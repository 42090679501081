import {
    Component,
    OnInit,
    ViewEncapsulation,
    Input,
    Injector,
    ChangeDetectorRef,
    AfterViewChecked,
    OnDestroy,
    EventEmitter,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UntypedFormBuilder, FormArray } from '@angular/forms';
import { DropdownsService, AuthenticationService } from 'app/services';
import {
    startWith,
    switchMap,
    tap,
    distinctUntilChanged,
    takeUntil,
    first,
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { merge, Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import * as _ from 'lodash';
import { AccountsCreateComponent } from '../../accounts-create.component';
import { FuseUtils } from '@fuse/utils';
import { map } from 'lodash';
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'tab-profile',
    templateUrl: './tab-profile.component.html',
    styleUrls: ['./tab-profile.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class TabProfileComponent
    implements OnInit, AfterViewChecked, OnDestroy {
    @Input() form: UntypedFormGroup;
    searchUrbanisation$ = new Subject<string>();
    searchLocationGroups$ = new Subject<string>();
    searchLocations$ = new Subject<string>();
    searchPropertyTypes$ = new Subject<string>();
    propertyTypes: any;
    searchPropertySubTypes$ = new Subject<string>();
    propertySubTypes: any;
    Groups = [];
    search = '';
    transactionOptions = [];
    purposeBuy = [];
    purposeRent = [];
    categories = [];
    occupancyStatusOptions = [];
    licenseOccuptionOptions = [];
    characteristics = [];
    orientations = [];
    views = [];
    conditions = [];
    parking = [];
    pool = [];
    garden = [];
    furniture = [];
    user: any;
    systemLang: any;
    purpose: boolean;
    shortRentals: boolean;
    longRentals: boolean;
    propTypes: any;
    ParPropTypes: any;
    urbanisations = [] as any;
    buildingStyle: any;
    mooring = [] as any;
    filter: any;
    searching: boolean;
    countries: any;
    countriesSearch: UntypedFormControl = new UntypedFormControl('');
    regions: any;
    regionsSearch: UntypedFormControl = new UntypedFormControl('');
    provinces: any;
    provincesSearch: UntypedFormControl = new UntypedFormControl('');
    cities: any;
    citiesSearch: UntypedFormControl = new UntypedFormControl('');
    locationGroups: any;
    locations: any;
    locationsSearch: UntypedFormControl = new UntypedFormControl('');
    pcategories = [] as any;
    _accountsCreateComponent: AccountsCreateComponent;
    currentLang: string;
    private _unsubscribeAll: Subject<any>;
    nudePurchase: boolean;
    fractional: boolean;
    rentToBuy: boolean;
    langReload = new EventEmitter<any>();

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _dropdownsService: DropdownsService,
        private _translateService: TranslateService,
        private _authenticationService: AuthenticationService,
        private cdRef: ChangeDetectorRef,
        private injector: Injector,
        private _toastr: ToastrService
    ) {
        this.currentLang =
            this._translateService.currentLang === 'es'
                ? 'es_AR'
                : this._translateService.currentLang;
        this.user = _authenticationService.currentUserValue;
        this.systemLang = this._translateService.currentLang;
        this.filter = {};
        this.filter.options = {
            limit: 1000,
            page: 1,
            sort: {
                'value.en': 1,
            },
        };
        this._unsubscribeAll = new Subject();
    }
    ngOnInit(): void {
        this._translateService.onLangChange
            .pipe(startWith(''), takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.systemLang = this._translateService.currentLang;
                this.currentLang =
                    this._translateService.currentLang === 'es'
                        ? 'es_AR'
                        : this._translateService.currentLang;
                this.langReload.next(true);
                this.loadDropdowns();
            });
        if (!this.form) {
            this._accountsCreateComponent = this.injector.get(
                AccountsCreateComponent
            ) as AccountsCreateComponent;
            this.form = this._accountsCreateComponent.form;
        }

        this._dropdownsService.getPropertyCategory().subscribe((data: any) => {
            const pcategories = map(data.body, (pcategory) => {
                pcategory.label = pcategory.value[this.systemLang];
                return pcategory;
            });
            this.pcategories = pcategories;
        });

        this._dropdownsService
            .getMooringTypes(this.filter)
            .subscribe((data: any) => {
                const mooring = map(data.docs, (mooringData) => {
                    mooringData.label = mooringData.value[this.systemLang];
                    return mooringData;
                });
                this.mooring = mooring;
            });

        this.langReload.subscribe(() => {
            const pcategories = map(this.pcategories, (pcategory) => {
                pcategory.label = pcategory.value[this.systemLang];
                return pcategory;
            });
            this.pcategories = pcategories;

            const mooring = map(this.mooring, (mooringData) => {
                mooringData.label = mooringData.value[this.systemLang];
                return mooringData;
            });
            this.mooring = mooring;

            const urbanisations = _.map(this.urbanisations, (urbanisation) => {
                urbanisation.label = this._translateService.instant(
                    urbanisation.value
                );
                return urbanisation;
            });
            this.urbanisations = urbanisations;

            const locationGroups = map(this.locationGroups, (locationGroup) => {
                locationGroup.label = locationGroup.value[this.systemLang];
                return locationGroup;
            });
            this.locationGroups = locationGroups;
        });

        this._dropdownsService.getAccountsBuildings().subscribe((data: any) => {
            const buildingStyles = map(data.body, (styles) => {
                styles.label = styles.value[this.systemLang];
                return styles;
            });
            this.buildingStyle = buildingStyles;
        });

        this.searchPropertyTypes$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getPropertyTypes(search);
                })
            )
            .subscribe((data: any) => {
                const propertyTypes = _.map(data, (type) => {
                    type.label = type.value[this.currentLang];
                    return type;
                });
                this.propertyTypes = propertyTypes;
            });

        merge(
            this.searchPropertySubTypes$,
            this.form.get('property_types').valueChanges.pipe(first())
        )
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    if (typeof search === 'object') {
                        search = '';
                    }
                    return this._dropdownsService.getPropertySubTypes(
                        this.form.get('property_types').value,
                        search
                    );
                })
            )
            .subscribe((data: any) => {
                const propertySubTypes = _.map(data, (type) => {
                    type.label = type.value[this.currentLang];
                    return type;
                });
                this.propertySubTypes = propertySubTypes;
            });

        this.searchUrbanisation$
            .pipe(
                startWith(''),
                distinctUntilChanged(),
                switchMap((search: string) => {
                    return this._dropdownsService.getUrbanisation(search);
                })
            )
            .subscribe((data: any) => {
                const urbanisations = _.map(data.docs, (urbanisation) => {
                    urbanisation.label = this._translateService.instant(
                        urbanisation.value
                    );
                    return urbanisation;
                });
                this.urbanisations = urbanisations;
            });

        this.searchLocationGroups$
            .pipe(
                startWith(''),
                distinctUntilChanged(),
                switchMap((search: string) => {
                    this.search = search;
                    return this._dropdownsService.getLocationsGroups();
                })
            )
            .subscribe((data: any) => {
                const locationGroups = map(data, (locationGroup) => {
                    locationGroup.label = locationGroup.value[this.systemLang];
                    if (!locationGroup.label) {
                        locationGroup.label = '';
                    }
                    return locationGroup;
                });
                this.locationGroups = locationGroups;

                if (this.search !== null) {
                    this.locationGroups = this.locationGroups.filter(
                        (element: any) => {
                            if (
                                element.label
                                    .toLowerCase()
                                    .match(this.search.toLowerCase())
                            ) {
                                return true;
                            }
                        }
                    );
                }
                this.searchLocations$.next('');
                this.search = '';
            });

        this.searchLocations$
            .pipe(
                // distinctUntilChanged(),
                switchMap((search: string) => {
                    this.search = search;
                    if (!_.isEmpty(this.form.get('locations_group').value)) {
                        const keys = [];
                        for (const item of this.locationGroups) {
                            if (item.hasOwnProperty('group') && item.group) {
                                for (const group of item.group) {
                                    keys.push(group);
                                }
                            }
                        }
                        return this._dropdownsService.getLocationsFromGroup(
                            keys,
                            2000
                        );
                    } else {
                        return this._dropdownsService.getLocations(
                            search,
                            false,
                            false,
                            false,
                            this.form.get('cities').value
                        );
                    }
                })
            )
            .subscribe((data: any) => {
                const keys = [];
                const locations = map(data.docs, (location) => {
                    const city = location?.city_data
                        ? ', ' + location?.city_data?.value[this.systemLang]
                        : '';
                    location.label = location.value[this.systemLang] + city;
                    keys.push(location?.key);
                    return location;
                });
                this.locations = locations;
                // passing data if location is empty so pass all locations GitLab #1373
                if (this.search !== null) {
                    this.locations = this.locations?.filter((element: any) => {
                        let city = '';
                        if (element?.values?.en) {
                            city = element?.values?.en;
                        } else if (element?.city_data?.value?.en) {
                            city = element?.city_data?.value?.en;
                        }
                        if (city && city.toLowerCase().match(this.search.toLowerCase())) {
                            return true;
                        }
                    });
                }
                this.search = '';
            });

            merge(
                this.countriesSearch.valueChanges,
                this.form.get('countries').valueChanges
            )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    let search = this.form.get('countries').value;
                    if (this.countriesSearch.value) {
                        search = this.countriesSearch.value;
                    }
                    return this._dropdownsService.getCountries(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.countries = data.docs;
            });

        merge(
            this.regionsSearch.valueChanges,
            this.form.get('countries').valueChanges,
            this.form.get('regions').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    let search = this.form.get('regions').value;
                    if (this.regionsSearch.value) {
                        search = this.regionsSearch.value;
                    }
                    return this._dropdownsService.getRegions(
                        search,
                        this.form.get('countries').value
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.regions = data.docs;
            });

        merge(
            this.provincesSearch.valueChanges,
            this.form.get('regions').valueChanges,
            this.form.get('provinces').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    let search = this.form.get('provinces').value;
                    if (this.provincesSearch.value) {
                        search = this.provincesSearch.value;
                    }
                    return this._dropdownsService.getProvinces(
                        search,
                        false,
                        this.form.get('regions').value
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.provinces = data.docs;
            });

        merge(
            this.citiesSearch.valueChanges,
            this.form.get('provinces').valueChanges,
            this.form.get('cities').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    let search = this.form.get('cities').value;
                    if (this.citiesSearch.value) {
                        search = this.citiesSearch.value;
                    }
                    return this._dropdownsService.getCities(
                        search,
                        false,
                        false,
                        this.form.get('provinces').value
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.cities = data.docs;
            });

        this.form
            .get('transaction_types')
            .valueChanges.pipe(startWith(''))
            .subscribe(() => {
                this.purpose = _.includes(
                    this.form.get('transaction_types').value,
                    'Buy'
                );
                this.shortRentals = _.includes(
                    this.form.get('transaction_types').value,
                    'Short term rental'
                );
                this.longRentals = _.includes(
                    this.form.get('transaction_types').value,
                    'Long term rental'
                );
                this.nudePurchase = _.includes(
                    this.form.get('transaction_types').value,
                    'Nude purchase'
                );
                this.fractional = _.includes(
                    this.form.get('transaction_types').value,
                    'Fractional'
                );
                this.rentToBuy = _.includes(
                    this.form.get('transaction_types').value,
                    'Rent to buy'
                );
            });
            this.form.get('min_beds').setValue(this.form.get('min_beds').value?.toString() ?? '');
            this.form.get('min_baths').setValue(this.form.get('min_baths').value?.toString() ?? '');
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }
    selectLocationGroup(e): void {
        this.Groups = [];
        for (const item of e) {
            for (const group of item.group) {
                this.Groups.push(group);
            }
        }

        if (this.Groups.length > 0) {
            this._dropdownsService
                .getLocationsFromGroup(this.Groups)
                .subscribe((data: any) => {
                    const selected = data.docs.map((item) => item.key);
                    let items = this.form.get('locations').value;
                    if (items) {
                        items = items.concat(selected);
                        items = Array.from(new Set(items));
                    } else {
                        items = selected;
                    }
                    this.form.get('locations').patchValue(items);
                });
        }
    }
    loadDropdowns(): any {
        const buildingStyles = map(this.buildingStyle, (styles) => {
            styles.label = styles.value[this.systemLang];
            return styles;
        });
        this.buildingStyle = buildingStyles;
        this.purposeBuy = [
            {
                key: 'Primary residence',
                value: this._translateService.instant('Primary residence'),
            },
            {
                key: 'Secondary residence',
                value: this._translateService.instant('Secondary residence'),
            },
            {
                key: 'Invesrment',
                value: this._translateService.instant('Invesrment'),
            },
            {
                key: 'investment_sales',
                value: this._translateService.instant('investment_sales'),
            },
            {
                key: 'Construction',
                value: this._translateService.instant('Construction'),
            },
        ];
        this.purposeBuy = _.orderBy(this.purposeBuy, ['value'], ['asc']);
        this.purposeRent = [
            {
                key: 'Primary residence',
                value: this._translateService.instant('Primary residence'),
            },
            {
                key: 'Secondary residence',
                value: this._translateService.instant('Secondary residence'),
            },
        ];
        this.purposeRent = _.orderBy(this.purposeRent, ['value'], ['asc']);
        this.transactionOptions = [
            {
                key: 'Short term rental',
                value: this._translateService.instant('Short term rental'),
            },
            {
                key: 'Long term rental',
                value: this._translateService.instant('Long term rental'),
            },
            {
                key: 'Buy',
                value: this._translateService.instant('Buy'),
            },
            {
                key: 'Nude purchase',
                value: this._translateService.instant('Nude purchase'),
            },
            {
                key: 'Fractional',
                value: this._translateService.instant('Fractional'),
            },
            {
                key: 'Rent to buy',
                value: this._translateService.instant('Rent to buy'),
            },
        ];
        this.transactionOptions = _.orderBy(
            this.transactionOptions,
            ['value'],
            ['asc']
        );
        this.categories = [
            {
                key: 'bargain',
                value: this._translateService.instant('bargain'),
            },
            {
                key: 'beachfront',
                value: this._translateService.instant('beachfront'),
            },
            {
                key: 'cheap',
                value: this._translateService.instant('cheap'),
            },
            {
                key: 'distressed',
                value: this._translateService.instant('distressed'),
            },
            {
                key: 'ecological',
                value: this._translateService.instant('ecological'),
            },
            {
                key: 'equestrian',
                value: this._translateService.instant('equestrian'),
            },
            {
                key: 'golf',
                value: this._translateService.instant('golf'),
            },
            {
                key: 'holiday_homes',
                value: this._translateService.instant('holiday_homes'),
            },
            {
                key: 'investment',
                value: this._translateService.instant('investment'),
            },
            {
                key: 'luxury',
                value: this._translateService.instant('luxury'),
            },
            {
                key: 'off_plan',
                value: this._translateService.instant('off_plan'),
            },
            {
                key: 'reduced',
                value: this._translateService.instant('reduced'),
            },
            {
                key: 'remote',
                value: this._translateService.instant('remote'),
            },
            {
                key: 'repossession',
                value: this._translateService.instant('repossession'),
            },
            {
                key: 'resale',
                value: this._translateService.instant('resale'),
            },
            {
                key: 'new_construction',
                value: this._translateService.instant('new_construction'),
            },
        ];
        this.categories = _.orderBy(this.categories, ['value'], ['asc']);
        this.occupancyStatusOptions = [
            {
                key: 'Occupied',
                value: this._translateService.instant('Occupied'),
            },
            {
                key: 'Not occupied',
                value: this._translateService.instant('Not occupied'),
            },
            {
                key: 'Periodically occupied',
                value: this._translateService.instant('Periodically occupied'),
            },
        ];
        this.occupancyStatusOptions = _.orderBy(
            this.occupancyStatusOptions,
            ['value'],
            ['asc']
        );
        this.licenseOccuptionOptions = [
            {
                key: 'Under Investigation',
                value: this._translateService.instant('Under Investigation'),
            },
            {
                key: 'Owner applying',
                value: this._translateService.instant('Owner applying'),
            },
            {
                key: 'Application pending',
                value: this._translateService.instant('Application pending'),
            },
            {
                key: 'Not possible to obtain AFO',
                value: this._translateService.instant(
                    'Not possible to obtain AFO'
                ),
            },
            {
                key: 'Assimilated',
                value: this._translateService.instant('Assimilated'),
            },
            {
                key: 'First Occupation License',
                value: this._translateService.instant(
                    'First Occupation License'
                ),
            },
        ];
        this.licenseOccuptionOptions = _.orderBy(
            this.licenseOccuptionOptions,
            ['value'],
            ['asc']
        );
        this.characteristics = [
            {
                key: 'beachfront',
                value: this._translateService.instant('beachfront'),
            },
            {
                key: 'beachside',
                value: this._translateService.instant('beachside'),
            },
            {
                key: 'close_to_airport',
                value: this._translateService.instant('close_to_airport'),
            },
            {
                key: 'close_to_busstop',
                value: this._translateService.instant('close_to_busstop'),
            },
            {
                key: 'close_to_church',
                value: this._translateService.instant('close_to_church'),
            },
            {
                key: 'close_to_forest',
                value: this._translateService.instant('close_to_forest'),
            },
            {
                key: 'close_to_golf',
                value: this._translateService.instant('close_to_golf'),
            },
            {
                key: 'close_to_hotel',
                value: this._translateService.instant('close_to_hotel'),
            },
            {
                key: 'close_to_marina',
                value: this._translateService.instant('close_to_marina'),
            },
            {
                key: 'close_to_mosque',
                value: this._translateService.instant('close_to_mosque'),
            },
            {
                key: 'close_to_port',
                value: this._translateService.instant('close_to_port'),
            },
            {
                key: 'close_to_restaurant',
                value: this._translateService.instant('close_to_restaurant'),
            },
            {
                key: 'close_to_schools',
                value: this._translateService.instant('close_to_schools'),
            },
            {
                key: 'close_to_sea',
                value: this._translateService.instant('close_to_sea'),
            },
            {
                key: 'close_to_shops',
                value: this._translateService.instant('close_to_shops'),
            },
            {
                key: 'close_to_skiing',
                value: this._translateService.instant('close_to_skiing'),
            },
            {
                key: 'close_to_supermarkets',
                value: this._translateService.instant('close_to_supermarkets'),
            },
            {
                key: 'close_to_taxistand',
                value: this._translateService.instant('close_to_taxistand'),
            },
            {
                key: 'close_to_town',
                value: this._translateService.instant('close_to_town'),
            },
            {
                key: 'close_to_train',
                value: this._translateService.instant('close_to_train'),
            },
            {
                key: 'commercial_area',
                value: this._translateService.instant('commercial_area'),
            },
            {
                key: 'countryside',
                value: this._translateService.instant('countryside'),
            },
            {
                key: 'easy_access',
                value: this._translateService.instant('easy_access'),
            },
            {
                key: 'frontline_golf',
                value: this._translateService.instant('frontline_golf'),
            },
            {
                key: 'marina',
                value: this._translateService.instant('marina'),
            },
            {
                key: 'mountain_pueblo',
                value: this._translateService.instant('mountain_pueblo'),
            },
            {
                key: 'no_nearby_neighbours',
                value: this._translateService.instant('no_nearby_neighbours'),
            },
            {
                key: 'not_isolated',
                value: this._translateService.instant('not_isolated'),
            },
            {
                key: 'port',
                value: this._translateService.instant('port'),
            },
            {
                key: 'private',
                value: this._translateService.instant('private'),
            },
            {
                key: 'suburban',
                value: this._translateService.instant('suburban'),
            },
            {
                key: 'town',
                value: this._translateService.instant('town'),
            },
            {
                key: 'tranquil',
                value: this._translateService.instant('tranquil'),
            },
            {
                key: 'urbanisation',
                value: this._translateService.instant('urbanisation'),
            },
            {
                key: 'village',
                value: this._translateService.instant('village'),
            },
        ];
        this.characteristics = _.orderBy(
            this.characteristics,
            ['value'],
            ['asc']
        );
        this.orientations = [
            {
                key: 'east',
                value: this._translateService.instant('east'),
            },
            {
                key: 'north',
                value: this._translateService.instant('north'),
            },
            {
                key: 'north_east',
                value: this._translateService.instant('north_east'),
            },
            {
                key: 'south',
                value: this._translateService.instant('south'),
            },
            {
                key: 'south_east',
                value: this._translateService.instant('south_east'),
            },
            {
                key: 'south_west',
                value: this._translateService.instant('south_west'),
            },
            {
                key: 'west',
                value: this._translateService.instant('west'),
            },
        ];
        this.orientations = _.orderBy(this.orientations, ['value'], ['asc']);
        this.views = [
            {
                key: 'beach',
                value: this._translateService.instant('beach'),
            },
            {
                key: 'countryside',
                value: this._translateService.instant('countryside'),
            },
            {
                key: 'courtyard',
                value: this._translateService.instant('courtyard'),
            },
            {
                key: 'forest',
                value: this._translateService.instant('forest'),
            },
            {
                key: 'garden',
                value: this._translateService.instant('garden'),
            },
            {
                key: 'golf',
                value: this._translateService.instant('golf'),
            },
            {
                key: 'lake',
                value: this._translateService.instant('lake'),
            },
            {
                key: 'mountain',
                value: this._translateService.instant('mountain'),
            },
            {
                key: 'panoramic',
                value: this._translateService.instant('panoramic'),
            },
            {
                key: 'partial_seaviews',
                value: this._translateService.instant('partial_seaviews'),
            },
            {
                key: 'pool',
                value: this._translateService.instant('pool'),
            },
            {
                key: 'port',
                value: this._translateService.instant('port'),
            },
            {
                key: 'sea',
                value: this._translateService.instant('sea'),
            },
            {
                key: 'ski',
                value: this._translateService.instant('ski'),
            },
            {
                key: 'street',
                value: this._translateService.instant('street'),
            },
            {
                key: 'urban',
                value: this._translateService.instant('urban'),
            },
        ];
        this.views = _.orderBy(this.views, ['value'], ['asc']);
        this.conditions = [
            {
                key: 'excellent',
                value: this._translateService.instant('excellent'),
            },
            {
                key: 'fair',
                value: this._translateService.instant('fair'),
            },
            {
                key: 'minor_updates_required',
                value: this._translateService.instant('minor_updates_required'),
            },
            {
                key: 'good',
                value: this._translateService.instant('good'),
            },
            {
                key: 'never_lived',
                value: this._translateService.instant('never_lived'),
            },
            {
                key: 'renovation_required',
                value: this._translateService.instant('renovation_required'),
            },
            {
                key: 'recently_renovated',
                value: this._translateService.instant('recently_renovated'),
            },
            {
                key: 'recently_refurbished',
                value: this._translateService.instant('recently_refurbished'),
            },
            {
                key: 'finishing_habitable_required',
                value: this._translateService.instant(
                    'finishing_habitable_required'
                ),
            },
            {
                key: 'basically_habitable',
                value: this._translateService.instant('basically_habitable'),
            },
        ];
        this.conditions = _.orderBy(this.conditions, ['value'], ['asc']);
        this.parking = [
            {
                key: 'communal_garage',
                value: this._translateService.instant('communal_garage'),
            },
            {
                key: 'parking_communal',
                value: this._translateService.instant('parking_communal'),
            },
            {
                key: 'covered',
                value: this._translateService.instant('covered'),
            },
            {
                key: 'private',
                value: this._translateService.instant('private'),
            },
            {
                key: 'more_than_one',
                value: this._translateService.instant('more_than_one'),
            },
        ];
        this.parking = _.orderBy(this.parking, ['value'], ['asc']);
        this.pool = [
            {
                key: 'pool_communal',
                value: this._translateService.instant('pool_communal'),
            },
            {
                key: 'pool_indoor',
                value: this._translateService.instant('pool_indoor'),
            },
            {
                key: 'pool_private',
                value: this._translateService.instant('pool_private'),
            },
        ];
        this.pool = _.orderBy(this.pool, ['value'], ['asc']);
        this.garden = [
            {
                key: 'almond_grove',
                value: this._translateService.instant('almond_grove'),
            },
            {
                key: 'garden_communal',
                value: this._translateService.instant('garden_communal'),
            },
            {
                key: 'easy_maintenance',
                value: this._translateService.instant('easy_maintenance'),
            },
            {
                key: 'fenced',
                value: this._translateService.instant('fenced'),
            },
            {
                key: 'fruit_trees_citrus',
                value: this._translateService.instant('fruit_trees_citrus'),
            },
            {
                key: 'fruit_trees_tropical',
                value: this._translateService.instant('fruit_trees_tropical'),
            },
            {
                key: 'irrigation_rights',
                value: this._translateService.instant('irrigation_rights'),
            },
            {
                key: 'landscaped',
                value: this._translateService.instant('landscaped'),
            },
            {
                key: 'Lawn',
                value: this._translateService.instant('Lawn'),
            },
            {
                key: 'olive_grove',
                value: this._translateService.instant('olive_grove'),
            },
            {
                key: 'outdoor_dining',
                value: this._translateService.instant('outdoor_dining'),
            },
            {
                key: 'playground',
                value: this._translateService.instant('playground'),
            },
            {
                key: 'plenty_of_water',
                value: this._translateService.instant('plenty_of_water'),
            },
            {
                key: 'pool_house',
                value: this._translateService.instant('pool_house'),
            },
            {
                key: 'garden_private',
                value: this._translateService.instant('garden_private'),
            },
            {
                key: 'shade_control',
                value: this._translateService.instant('shade_control'),
            },
            {
                key: 'tropical_garden',
                value: this._translateService.instant('tropical_garden'),
            },
            {
                key: 'vegetable',
                value: this._translateService.instant('vegetable'),
            },
            {
                key: 'veranda',
                value: this._translateService.instant('veranda'),
            },
            {
                key: 'vineyard',
                value: this._translateService.instant('vineyard'),
            },
        ];
        this.garden = _.orderBy(this.garden, ['value'], ['asc']);
        this.furniture = [
            {
                key: 'fully_furnished',
                value: this._translateService.instant('fully_furnished'),
            },
            {
                key: 'part_furnished',
                value: this._translateService.instant('part_furnished'),
            },
            {
                key: 'not_furnished',
                value: this._translateService.instant('not_furnished'),
            },
        ];
        this.furniture = _.orderBy(this.furniture, ['value'], ['asc']);
    }

    emailAlerts(event: any): void {
        console.log(event.checked);
        if (event.checked) {
            // tslint:disable-next-line: max-line-length
            this._toastr.warning(
                this._translateService.instant(
                    'alerts will work if property type, purchase budget is defined and gdpr is accepted'
                ),
                this._translateService.instant('warning')
            );
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    citySelect(): void {
        this.searchLocations$.next('');
    }
}

import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, DropdownsService } from 'app/services';
import { AccountsService } from 'app/services/accounts.service';
import { CrmMenuService } from 'app/services/crm-menu.service';
import { GlobalFuntions } from 'app/_helpers';
import { forEach, uniq, isEmpty, orderBy } from 'lodash';
import { merge, Subject } from 'rxjs';
import { startWith, switchMap, tap } from 'rxjs/operators';
@Component({
    selector: 'quick-add',
    templateUrl: './quick-add.component.html',
    styleUrls: ['./quick-add.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class QuickAddComponent implements OnInit {
    customSettings: UntypedFormArray;
    form: UntypedFormGroup;
    public sources: any = [];
    public sourceSearch: UntypedFormControl = new UntypedFormControl();
    public offices: any = [];
    officeGroups = [] as any;
    searchOffices$ = new Subject<string>();
    searchAssignee$ = new Subject<string>();
    public assigned: any = [];
    systemLang: any;
    nationalities: any;
    searching: boolean;
    currentLang: string;
    viewRecord: boolean;
    filter: any = {};
    public companies: any = [];
    public companiesSearch: UntypedFormControl = new UntypedFormControl();
    public officeData: any;
    currentUser: any;
    isClick = false as boolean;
    partners: UntypedFormArray;
    cbSubAgency = false;

    constructor(
        public dialogRef: MatDialogRef<QuickAddComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: UntypedFormBuilder,
        private accountsService: AccountsService,
        private _dropdownsService: DropdownsService,
        private router: Router,
        private _translateService: TranslateService,
        private _authenticationService: AuthenticationService,
        public _crmMenuService: CrmMenuService,
        private _globalFunction: GlobalFuntions
    ) {
        this.systemLang = this._translateService.currentLang;
        this.currentUser = this._authenticationService.currentUserValue;
        this.officeData = this._authenticationService.currentOfficeValue;
        // this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
        let currentAgency = this._authenticationService.currentAgencyValue;

        let officeRecord = false;
        if (this._globalFunction.getUserRole(this.currentUser.user_role)) {
            officeRecord = true;
        }
        if (!this.officeData?.master_office && this.officeData?.is_cb && currentAgency.brochures_only) {
            this.cbSubAgency = true;
        }

        this.form = this._formBuilder.group({
            id: [{ value: '', disabled: true }],
            type: [{ value: '', disabled: this.viewRecord }],
            forename: [
                { value: '', disabled: this.viewRecord },
                Validators.required,
            ],
            surname: [
                { value: '', disabled: this.viewRecord },
                Validators.required,
            ],
            mobile_phone: [
                { value: '', disabled: this.viewRecord },
                Validators.required,
            ],
            secondary_mobile_phone: { value: '', disabled: this.viewRecord },
            secondary_work_phone: { value: '', disabled: this.viewRecord },
            secondary_home_phone: { value: '', disabled: this.viewRecord },
            partners: this._formBuilder.array([this.createCustoms()]),
            date_of_birth: [{ value: '', disabled: this.viewRecord }],
            document_type: [{ value: '', disabled: this.viewRecord }],
            document_number: [
                { value: '', disabled: this.viewRecord },
                Validators.required,
            ],
            search: [{ value: '', disabled: this.viewRecord }],
            home_phone: [{ value: '', disabled: this.viewRecord }],
            work_phone: [{ value: '', disabled: this.viewRecord }],
            mobile: [
                { value: '', disabled: this.viewRecord },
                Validators.required,
            ],
            email: [
                { value: '', disabled: this.viewRecord },
                Validators.required,
            ],
            skype_id: [{ value: '', disabled: this.viewRecord }],
            title: [{ value: '', disabled: this.viewRecord }],
            gender: [{ value: '', disabled: this.viewRecord }],
            street: [{ value: '', disabled: this.viewRecord }],
            streetnumber: [{ value: '', disabled: this.viewRecord }],
            comments: [{ value: '', disabled: this.viewRecord }],
            source: [{ value: '', disabled: this.viewRecord }],
            gdpr_comment: [{ value: '', disabled: this.viewRecord }],
            options: [{ value: '', disabled: this.viewRecord }],
            complete_address: [{ value: '', disabled: this.viewRecord }],
            complete_address_country: [
                { value: '', disabled: this.viewRecord },
            ],
            complete_address_region: [{ value: '', disabled: this.viewRecord }],
            complete_address_city: [{ value: '', disabled: this.viewRecord }],
            postcode: [{ value: '', disabled: this.viewRecord }],
            complete_address_province: [
                { value: '', disabled: this.viewRecord },
            ],
            status: [{ value: '', disabled: this.viewRecord }],
            regionSearch: [{ value: '', disabled: this.viewRecord }],
            listing: this._formBuilder.array([]),
            custom1Settings: this._formBuilder.array([]),
            account_holder: [{ value: '', disabled: this.viewRecord }],
            bic_swift: [{ value: '', disabled: this.viewRecord }],
            iban: [{ value: '', disabled: this.viewRecord }],
            avg_balance: [{ value: '', disabled: this.viewRecord }],
            key: [{ value: '', disabled: this.viewRecord }],
            value: [{ value: '', disabled: this.viewRecord }],
            company_name: [
                { value: '', disabled: this.viewRecord },
                Validators.required,
            ],
            legal_form: [
                { value: '', disabled: this.viewRecord },
                Validators.required,
            ],
            tax_id: [
                { value: '', disabled: this.viewRecord },
                Validators.required,
            ],
            nationality: [{ value: '', disabled: this.viewRecord }],
            language: [{ value: [], disabled: this.viewRecord }],
            address: [{ value: '', disabled: this.viewRecord }],
            region: [{ value: '', disabled: this.viewRecord }],
            country: [{ value: '', disabled: this.viewRecord }],
            province: [{ value: '', disabled: this.viewRecord }],
            city: [{ value: '', disabled: this.viewRecord }],
            properties: [{ value: '', disabled: this.viewRecord }],
            communication_language: [{ value: '', disabled: this.viewRecord }],
            offices: [{ value: [this.officeData._id], disabled: officeRecord }],
            probability: [{ value: '', disabled: this.viewRecord }],
            gdpr_status: [{ value: '1', disabled: this.viewRecord }],
            company: [{ value: '', disabled: this.viewRecord }],
            company_contact: [{ value: '', disabled: this.viewRecord }],
            client_id: [{ value: '', disabled: this.viewRecord }],
            id_type: [{ value: '', disabled: this.viewRecord }],
            linked_users: { value: '', disabled: this.viewRecord },
            id_number: [{ value: '', disabled: this.viewRecord }],
            id_expiration: new UntypedFormControl(),
            chamber_number: [{ value: '', disabled: this.viewRecord }],
            assigned_to: [{ value: [], disabled: this.viewRecord }],
            linked_company: { value: '', disabled: this.viewRecord },

            transaction_types: { value: '', disabled: this.viewRecord },
            purpose: { value: '', disabled: this.viewRecord },
            property_types: { value: '', disabled: this.viewRecord },
            sub_types: { value: '', disabled: this.viewRecord },
            min_beds: { value: '', disabled: this.viewRecord },
            min_baths: { value: '', disabled: this.viewRecord },
            countries: { value: '', disabled: this.viewRecord },
            regions: { value: '', disabled: this.viewRecord },
            provinces: { value: '', disabled: this.viewRecord },
            cities: { value: '', disabled: this.viewRecord },
            locations_group: { value: '', disabled: this.viewRecord },
            locations: { value: '', disabled: this.viewRecord },
            urbanization: { value: '', disabled: this.viewRecord },
            urbanization_type: { value: '', disabled: this.viewRecord },
            feet_categories: { value: '', disabled: this.viewRecord },
            custom_categories: { value: '', disabled: this.viewRecord },
            year_built_from: { value: '', disabled: this.viewRecord },
            year_built_to: { value: '', disabled: this.viewRecord },
            min_plot_size: { value: '', disabled: this.viewRecord },
            max_plot_size: { value: '', disabled: this.viewRecord },
            occupancy_status: { value: '', disabled: this.viewRecord },
            license_first_occuption: { value: '', disabled: this.viewRecord },
            min_sleeps: { value: '', disabled: this.viewRecord },
            max_total_floors: { value: '', disabled: this.viewRecord },
            min_built: { value: '', disabled: this.viewRecord },
            max_built: { value: '', disabled: this.viewRecord },
            usefull_area_from: { value: '', disabled: this.viewRecord },
            usefull_area_to: { value: '', disabled: this.viewRecord },
            bed_from: { value: '', disabled: this.viewRecord },                
            bed_to: { value: '', disabled: this.viewRecord },                
            building_style: { value: '', disabled: this.viewRecord },
            gated: { value: '', disabled: this.viewRecord },
            lift: { value: '', disabled: this.viewRecord },
            feet_setting: { value: '', disabled: this.viewRecord },
            feet_orientation: { value: '', disabled: this.viewRecord },
            feet_views: { value: '', disabled: this.viewRecord },
            feet_condition: { value: '', disabled: this.viewRecord },
            parking: { value: '', disabled: this.viewRecord },
            pool: { value: '', disabled: this.viewRecord },
            feet_garden: { value: '', disabled: this.viewRecord },
            furniture: { value: '', disabled: this.viewRecord },
            mooring_type: { value: '', disabled: this.viewRecord },
            min_depth: { value: '', disabled: this.viewRecord },
            min_width: { value: '', disabled: this.viewRecord },
            min_length: { value: '', disabled: this.viewRecord },
            filter_projects: { value: '', disabled: this.viewRecord },
            filter_golf: { value: '', disabled: this.viewRecord },
            filter_holiday_homes: { value: '', disabled: this.viewRecord },
            filter_repossession: { value: '', disabled: this.viewRecord },
            filter_investment: { value: '', disabled: this.viewRecord },
            filter_off_plan: { value: '', disabled: this.viewRecord },
            filter_distressed: { value: '', disabled: this.viewRecord },
            only_own: { value: '', disabled: this.viewRecord },
            buy_from_date: { value: '', disabled: this.viewRecord },
            buy_price_low: { value: '', disabled: this.viewRecord },
            buy_price_high: { value: '', disabled: this.viewRecord },
            period: { value: '', disabled: this.viewRecord },
            rent_from_date: { value: '', disabled: this.viewRecord },
            rent_to_date: { value: '', disabled: this.viewRecord },
            short_term_Rent_price_low: { value: '', disabled: this.viewRecord },
            short_term_Rent_price_high: {
                value: '',
                disabled: this.viewRecord,
            },
            long_term_rent_from_date: { value: '', disabled: this.viewRecord },
            long_term_Rent_price_low: { value: '', disabled: this.viewRecord },
            long_term_Rent_price_high: { value: '', disabled: this.viewRecord },
            newsletter: { value: '', disabled: this.viewRecord },
            polygon: { value: '', disabled: this.viewRecord },
            min_single_purchase_price: { value: '', disabled: this.viewRecord },
            max_single_purchase_price: { value: '', disabled: this.viewRecord },
            min_initial_amount: { value: '', disabled: this.viewRecord },
            max_initial_amount: { value: '', disabled: this.viewRecord },
            min_lifetime_monthly_rent: { value: '', disabled: this.viewRecord },
            max_lifetime_monthly_rent: { value: '', disabled: this.viewRecord },
            min_temporary_monthly_rent: {
                value: '',
                disabled: this.viewRecord,
            },
            max_temporary_monthly_rent: {
                value: '',
                disabled: this.viewRecord,
            },
            next_contact_date: { value: '', disabled: this.viewRecord },
            account_alert: { value: '', disabled: this.viewRecord },
            account_alert_st_rental: { value: '', disabled: this.viewRecord },
            account_alert_lt_rental: { value: '', disabled: this.viewRecord },
            commercial_profile: this._formBuilder.group({
                transaction_type: { value: '', disabled: this.viewRecord },
                type_one: { value: '', disabled: this.viewRecord },
                type_two: { value: '', disabled: this.viewRecord },
                type_three: { value: '', disabled: this.viewRecord },
                beds_from: { value: '', disabled: this.viewRecord },
                beds_to: { value: '', disabled: this.viewRecord },
                baths_from: { value: '', disabled: this.viewRecord },
                baths_to: { value: '', disabled: this.viewRecord },
                toilets: { value: '', disabled: this.viewRecord },
                bed_from: { value: '', disabled: this.viewRecord },
                bed_to: { value: '', disabled: this.viewRecord },
                countries: { value: '', disabled: this.viewRecord },
                regions: { value: '', disabled: this.viewRecord },
                provinces: { value: '', disabled: this.viewRecord },
                cities: { value: '', disabled: this.viewRecord },
                locations_group: { value: '', disabled: this.viewRecord },
                locations: { value: '', disabled: this.viewRecord },
                urbanization: { value: '', disabled: this.viewRecord },
                urbanization_type: { value: '', disabled: this.viewRecord },
                accomodation: { value: '', disabled: this.viewRecord },
                custom_categories: { value: '', disabled: this.viewRecord },
                feet_categories: { value: '', disabled: this.viewRecord },
                min_built: { value: '', disabled: this.viewRecord },
                parking: { value: '', disabled: this.viewRecord },
                freehold: { value: '', disabled: this.viewRecord },
                leasehold: { value: '', disabled: this.viewRecord },
                email_alerts: { value: '', disabled: this.viewRecord },
                sales_price_from: { value: '', disabled: this.viewRecord },
                sales_price_to: { value: '', disabled: this.viewRecord },
                only_projects: { value: '', disabled: this.viewRecord },
                only_bank_repossessions: { value: '', disabled: this.viewRecord },
                only_investments: { value: '', disabled: this.viewRecord },
                only_offplan: { value: '', disabled: this.viewRecord },
                only_urgent_sales: { value: '', disabled: this.viewRecord },
                owner: { value: '', disabled: this.viewRecord },
                created_from: { value: '', disabled: this.viewRecord },
                created_to: { value: '', disabled: this.viewRecord },
                buy_from_date: { value: '', disabled: this.viewRecord },
                buy_price_from: { value: '', disabled: this.viewRecord },
                buy_price_to: { value: '', disabled: this.viewRecord },
                // new fields for rent 
                rent_from_date: { value: '', disabled: this.viewRecord },
                rent_to_date: { value: '', disabled: this.viewRecord },
                rent_price_from: { value: '', disabled: this.viewRecord },
                rent_price_to: { value: '', disabled: this.viewRecord },
                sleeps: { value: '', disabled: this.viewRecord },
                auction_from_date: { value: '', disabled: this.viewRecord },                
                auction_to_date: { value: '', disabled: this.viewRecord },                
                min_start_price: { value: '', disabled: this.viewRecord },                
                max_start_price: { value: '', disabled: this.viewRecord },                
                max_option_budget: { value: '', disabled: this.viewRecord },                
                min_validity_period: { value: '', disabled: this.viewRecord },                
                rent_buy_months: { value: '', disabled: this.viewRecord },                
                rent_buy_years: { value: '', disabled: this.viewRecord },                
                max_initial_amount: { value: '', disabled: this.viewRecord },                
                year_built_from: { value: '', disabled: this.viewRecord },                
                year_built_to: { value: '', disabled: this.viewRecord },                
                min_plot_size: { value: '', disabled: this.viewRecord },                
                max_plot_size: { value: '', disabled: this.viewRecord },                
                max_total_floors: { value: '', disabled: this.viewRecord },                                
                max_built: { value: '', disabled: this.viewRecord },                
                usefull_area_from: { value: '', disabled: this.viewRecord },                
                usefull_area_to: { value: '', disabled: this.viewRecord },                
                building_style: { value: '', disabled: this.viewRecord },                
                gated: { value: '', disabled: this.viewRecord },                
                lift: { value: '', disabled: this.viewRecord },                
                feet_setting: { value: '', disabled: this.viewRecord },                
                feet_orientation: { value: '', disabled: this.viewRecord },                
                feet_views: { value: '', disabled: this.viewRecord },                
                feet_condition: { value: '', disabled: this.viewRecord },                
                pool: { value: '', disabled: this.viewRecord },                
                feet_garden: { value: '', disabled: this.viewRecord },                
                furniture: { value: '', disabled: this.viewRecord },                
                climate_control: { value: '', disabled: this.viewRecord },                
                min_single_purchase_price: { value: '', disabled: this.viewRecord },                
                min_yearly_rent: { value: '', disabled: this.viewRecord },                
                lease_from_date: { value: '', disabled: this.viewRecord },
                lease_price_from: { value: '', disabled: this.viewRecord },
                lease_price_to: { value: '', disabled: this.viewRecord },
                newsletter: { value: '', disabled: this.viewRecord },
                comments: { value: '', disabled: this.viewRecord },
                next_contact_date: { value: '', disabled: this.viewRecord },
                polygon: { value: '', disabled: this.viewRecord },
                account_alert_lt_rental: { value: '', disabled: this.viewRecord },
                account_alert: { value: '', disabled: this.viewRecord },
                purpose: { value: '', disabled: this.viewRecord },
                occupancy_status: { value: '', disabled: this.viewRecord },
                license_first_occuption: { value: '', disabled: this.viewRecord },
                period: { value: '', disabled: this.viewRecord },
                // Smart Invest fields
                min_price_built_m2: { value: '', disabled: this.viewRecord },
                max_price_built_m2: { value: '', disabled: this.viewRecord },
                min_price_plot_m2: { value: '', disabled: this.viewRecord },
                max_price_plot_m2: { value: '', disabled: this.viewRecord },
                max_return_yearly_rent: { value: '', disabled: this.viewRecord },
                min_price_with_capex: { value: '', disabled: this.viewRecord },
                max_price_with_capex: { value: '', disabled: this.viewRecord },
                min_price_capex_m2: { value: '', disabled: this.viewRecord },
                max_price_capex_m2: { value: '', disabled: this.viewRecord },
                min_room_price: { value: '', disabled: this.viewRecord },
                max_room_price: { value: '', disabled: this.viewRecord },
                min_actual_yearly_rent: { value: '', disabled: this.viewRecord },
                max_actual_yearly_rent: { value: '', disabled: this.viewRecord },
                min_appraised: { value: '', disabled: this.viewRecord },
                max_appraised: { value: '', disabled: this.viewRecord },
                min_debt: { value: '', disabled: this.viewRecord },
                max_debt: { value: '', disabled: this.viewRecord },
                min_capital_gain: { value: '', disabled: this.viewRecord },
                max_capital_gain: { value: '', disabled: this.viewRecord },
                min_gross_profitability: { value: '', disabled: this.viewRecord },
                max_gross_profitability: { value: '', disabled: this.viewRecord },
                min_net_profitability: { value: '', disabled: this.viewRecord },
                max_net_profitability: { value: '', disabled: this.viewRecord },
                max_parking_spot_price: { value: '', disabled: this.viewRecord },
                min_power: { value: '', disabled: this.viewRecord },
                min_office_rooms: { value: '', disabled: this.viewRecord },
                max_office_rooms: { value: '', disabled: this.viewRecord },
                min_size_above_ground: { value: '', disabled: this.viewRecord },
                max_size_above_ground: { value: '', disabled: this.viewRecord },
                min_size_underground: { value: '', disabled: this.viewRecord },
                max_size_underground: { value: '', disabled: this.viewRecord },
                min_floors_above_ground: { value: '', disabled: this.viewRecord },
                max_floors_above_ground: { value: '', disabled: this.viewRecord },
                min_floors_underground: { value: '', disabled: this.viewRecord },
                max_floors_underground: { value: '', disabled: this.viewRecord },
                min_offices: { value: '', disabled: this.viewRecord },
                max_offices: { value: '', disabled: this.viewRecord },
                min_properties: { value: '', disabled: this.viewRecord },
                max_properties: { value: '', disabled: this.viewRecord },
                min_parking_spaces: { value: '', disabled: this.viewRecord },
                max_parking_spaces: { value: '', disabled: this.viewRecord },
                commercial_premises: { value: '', disabled: this.viewRecord },
                terrace_present: { value: '', disabled: this.viewRecord },
                smoke_exit: { value: '', disabled: this.viewRecord },
                status: { value: '', disabled: this.viewRecord },
                operational_strategy: { value: '', disabled: this.viewRecord },
                license_type: { value: '', disabled: this.viewRecord },
                min_percentage_property: { value: '', disabled: this.viewRecord },
                max_percentage_property: { value: '', disabled: this.viewRecord },
                min_total_floors: { value: '', disabled: this.viewRecord },
                land_uses: { value: '', disabled: this.viewRecord },
                change_of_use: { value: '', disabled: this.viewRecord },
                profile_comments: { value: '', disabled: this.viewRecord }
            }),
            mooring_profile: this._formBuilder.group({
                transaction_types: { value: '', disabled: this.viewRecord },
                type_one: { value: '', disabled: this.viewRecord },
                buy_from_date: { value: '', disabled: this.viewRecord },
                buy_price_low: { value: '', disabled: this.viewRecord },
                buy_price_high: { value: '', disabled: this.viewRecord },
                period: { value: '', disabled: this.viewRecord },
                rent_from_date: { value: '', disabled: this.viewRecord },
                rent_to_date: { value: '', disabled: this.viewRecord },
                rent_price_low: { value: '', disabled: this.viewRecord },
                rent_price_high: { value: '', disabled: this.viewRecord },
                min_beam: { value: '', disabled: this.viewRecord },
                min_length: { value: '', disabled: this.viewRecord },
                car_parking: { value: '', disabled: this.viewRecord },
                car_accessible: { value: '', disabled: this.viewRecord },
                countries: { value: '', disabled: this.viewRecord },
                regions: { value: '', disabled: this.viewRecord },
                provinces: { value: '', disabled: this.viewRecord },
                cities: { value: '', disabled: this.viewRecord },
                custom_categories: { value: '', disabled: this.viewRecord },
                comments: { value: '', disabled: this.viewRecord },
                next_contact_date: { value: '', disabled: this.viewRecord },
                polygon: { value: '', disabled: this.viewRecord }
            }),
            boat_profile: this._formBuilder.group({
                transaction_types: { value: '', disabled: this.viewRecord },
                type_one: { value: '', disabled: this.viewRecord },
                type_two: { value: '', disabled: this.viewRecord },
                buy_from_date: { value: '', disabled: this.viewRecord },
                buy_price_low: { value: '', disabled: this.viewRecord },
                buy_price_high: { value: '', disabled: this.viewRecord },
                period: { value: '', disabled: this.viewRecord },
                rent_from_date: { value: '', disabled: this.viewRecord },
                rent_to_date: { value: '', disabled: this.viewRecord },
                rent_price_low: { value: '', disabled: this.viewRecord },
                rent_price_high: { value: '', disabled: this.viewRecord },
                min_cabins: { value: '', disabled: this.viewRecord },
                min_heads: { value: '', disabled: this.viewRecord },
                countries: { value: '', disabled: this.viewRecord },
                regions: { value: '', disabled: this.viewRecord },
                provinces: { value: '', disabled: this.viewRecord },
                cities: { value: '', disabled: this.viewRecord },
                hull_material: { value: '', disabled: this.viewRecord },
                drive_type: { value: '', disabled: this.viewRecord },
                fuel_type: { value: '', disabled: this.viewRecord },
                max_passengers:{ value: '', disabled: this.viewRecord },
                engines: { value: '', disabled: this.viewRecord },
                engine_hours: { value: '', disabled: this.viewRecord },
                maximum_speed: { value: '', disabled: this.viewRecord },
                min_length_overall: { value: '', disabled: this.viewRecord },
                max_length_overall: { value: '', disabled: this.viewRecord },
                min_beam_overall: { value: '', disabled: this.viewRecord },
                max_beam_overall: { value: '', disabled: this.viewRecord },
                custom_categories: { value: '', disabled: this.viewRecord },
                comments: { value: '', disabled: this.viewRecord },
                next_contact_date: { value: '', disabled: this.viewRecord },
                polygon: { value: '', disabled: this.viewRecord }
            }),
        });
    }

    ngOnInit(): void {
        this.form.get('type').setValue('individual');
        if (!isEmpty(this._data)) {
            if(this._data.call_from == 'mail_list_item') {
                this.setDataFromDailogPass(this._data)
            }
        }
        (this.partners = this.form.get('partners') as UntypedFormArray),
            merge(
                this.sourceSearch.valueChanges,
                this.form.get('source').valueChanges
            )
                .pipe(
                    startWith(''),
                    tap(() => (this.searching = true)),
                    switchMap(() => {
                        let search = this.sourceSearch.value;
                        if (this.sourceSearch.value === null) {
                            search = '';
                        }
                        return this._dropdownsService.getSources(
                            'Accounts',
                            search
                        );
                    })
                )
                .subscribe((data: any) => {
                    this.searching = false;
                    this.sources = data.body;
                });

        this.searchOffices$
            .pipe(
                startWith(''),
                switchMap((search: string) => {
                    return this._dropdownsService.getOffices(search);
                })
            )
            .subscribe((data: any) => {
                this.offices = data.body;
                forEach(this.offices, (value) => {
                    if (value.office_groups) {
                        if (typeof value.office_groups == 'object') {
                            value.office_groups.forEach((group) => {
                                this.officeGroups.push(group);
                            });
                        } else {
                            this.officeGroups.push(value.office_groups);
                        }
                    }
                });
                this.officeGroups = uniq(this.officeGroups);
                this.officeGroups.sort();
            });

        merge(this.searchAssignee$, this.form.get('offices').valueChanges)
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap((search: any) => {
                    return this._dropdownsService.usersAssignee(
                        this.form.get('offices').value,
                        search
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                forEach(data, (value) => {
                    value.disabled =
                        !this.officeData.master_office &&
                        this.officeData._id !== value.office
                            ? true
                            : false;
                });
                this.assigned = data;
            });

        this._dropdownsService.getNationalities().subscribe((data: any) => { 
            data.body = orderBy(data.body, [`value.${this.systemLang}`], ['asc']);
            this.nationalities = data.body;
        });

        merge(
            this.companiesSearch.valueChanges,
            this.form.get('linked_company').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    const search = this.companiesSearch.value
                        ? this.companiesSearch.value
                        : '';
                    return this._dropdownsService.getCompanies(
                        search,
                        this.form.get('linked_company').value
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.companies = data.body;
            });
        if (this.cbSubAgency) {
            this.form.get('source').setValue('CB');
        }
    }

    onChangetitle(): void {
        const title = this.form.get('title').value;
        if (title === 'Mr.') {
            this.form.get('gender').setValue('Male');
        }
        if (title === 'Ms.' || title === 'Mrs.' || title === 'Miss.') {
            this.form.get('gender').setValue('Female');
        }
    }

    submitForm(): void {
        this.isClick = true;
        const formData = this.form.value;
        formData.from_crm = 1;
        this.accountsService.create(formData).subscribe(
            (data: any) => {
                this.dialogRef.close(data);
            },
            (err: any) => {
                this.isClick = false;
            }
        );
    }
    onClickClose(): void {
        this.dialogRef.close();
    }

    createCustoms(): UntypedFormGroup {
        return this._formBuilder.group({
            title: [{ value: '', disabled: false }],
            forename: [{ value: '', disabled: false }],
            surname: [{ value: '', disabled: false }],
            nationality: [{ value: '', disabled: false }],
        });
    }

    setDataFromDailogPass(data) {
        Object.keys(this.form.controls).forEach(key => {
            if (data[key] !== undefined) {
                this.form.get(key).setValue(data[key]);
            }
        });
    }
}

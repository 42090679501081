import {
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { isBuffer, toLower, toUpper } from 'lodash';
import { OfficesService, AuthenticationService } from 'app/services';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
import { CurrencyPipe } from '@angular/common';
import { includes, merge, forEach } from 'lodash';
import * as _ from 'lodash';
import { GetBoatMooringUrlPipe } from 'app/pipes/get-boat-mooring-url.pipe';
import { BaseService } from 'app/_helpers/base/base.service';

@Component({
    selector: 'windowcard-one',
    templateUrl: './windowcard-one.component.html',
    styleUrls: ['./windowcard-one.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class WindowcardOneComponent implements OnInit {
    card: any;
    @Input()
    set cardSettings(value: any) {
        this.card = value;
    }
    discKey: any;
    langKey: any;
    @Input()
    set cardLanguage(value: any) {
        this.langKey = toLower(value);
        if (this.langKey === 'es') {
            this.langKey = 'es_AR';
        }if( this.langKey === 'ned'){
            this.langKey = 'nl'
        }
        if (value === 'ES_AR') {
            value = 'ES';
        }
        this.discKey = value;
    }
    @Input() requestFrom: any;
    whiteLabeled: boolean;
    showTd = false;
    builtPro = false;
    plotPro = false;
    bedroomsPro = false;
    bathroomsPro = false;
    exclusiveProperty = false;
    reducedPrice = false;
    reducedPriceTag = false;
    reducedAndExProperty = false;
    oldPrice: any;
    @Input() cardProperty: any;
    @Input() templateID: any;
    rootUrl: string;
    cdnUrl: string;
    appUrl: string;
    firstImg = 0;
    secondImg = 1;
    thirdImg = 2;
    fourthImg = 3;
    fifthImg = 4;
    sixthImg = 5;
    seventhImg = 6;
    eigthImg = 7;
    ninthImg = 8;
    yearBuilt: any;
    currentLang: any;
    @ViewChild('pdfTemplate', { static: false }) pdfTemplate: ElementRef;
    currentOffice: any;
    masterOffice = false;
    subOfficePLAYA = false;
    subOfficeRoses = false;
    normalParking = false;
    coveredParking = false;
    bothParking = false;
    special_features: any = [];
    features: any = [];
    propertyAttachments: any = [];
    lastItem: any;
    hasParking = false;
    fullyFurnished = false;
    fullyFitted = false;
    hasPool = false;
    energy_certificate: any;
    pools = [];
    lt_rental = false;
    propertyForRent = false;
    propertyForSale = false;
    propertyForSaleAndRent = false;
    cbSubAgency = false;
    propertyType: any;
    propertyAddress = '';
    setAgencylogo: any;
    limitedDescription: string;
    constructor(
        public sanitizer: DomSanitizer,
        private _translateService: TranslateService,
        private _authenticationService: AuthenticationService,
        private _currencyPipe: CurrencyPipe,
        private _gService: BaseService
    ) {
        this.rootUrl = environment.rootUrl;
        this.cdnUrl = environment.cdnUrl;
        this.appUrl = environment.appUrl;
        this.currentLang =
            this._translateService.currentLang === 'es'
                ? 'es_AR'
                : this._translateService.currentLang;
        this.currentOffice = _authenticationService.currentOfficeValue;
        if (!this.currentOffice.master_office && this.currentOffice.is_cb) {
            this.cbSubAgency = true;
        }
        // Set the private default

        if (
            this.currentOffice.master_office &&
            this.currentOffice.master_office === true
        ) {
            this.masterOffice = true;
        } else if (
            this.currentOffice &&
            this.currentOffice._id === '5cef9598b15da704a56b3be8'
        ) {
            this.subOfficeRoses = true;
        } else if (
            this.currentOffice &&
            this.currentOffice._id === '5cef9676b15da765344e306a'
        ) {
            this.subOfficePLAYA = true;
        } else if (
            this.currentOffice &&
            this.currentOffice._id === '5f1c83c767d07c1ef11b01d8'
        ) {
        } else {
            this.subOfficePLAYA = true;
        }
    }

    ngOnInit(): void {
        const agency = JSON.parse(localStorage.getItem('currentAgency')); 
        this.setAgencylogo = agency.agency_logo;

        setTimeout(() => {
            if (this.cardProperty?.type && (this.cardProperty.type == 'boat' || this.cardProperty.type == 'mooring')) {
                let filter = {param: { id: this.cardProperty.agency_data._id }};
                this._gService.post(`agencies/address`, filter, 'nodejs').subscribe((address: any) => {
                    this.cardProperty.agency_logo = this.cardProperty.agency_data;
                    if (address && address.complete_address_city_value) {
                        this.cardProperty.agency_logo.complete_address_city = address.complete_address_city_value.value;
                    }
                    if (address && address.complete_address_country_value) {
                        this.cardProperty.agency_logo.complete_address_country = address.complete_address_country_value.value;
                    }
                    this.updateBoatMooringData();
                });
            }

            if(this.requestFrom !== undefined && this.requestFrom == "commercials") {
                this.setCommercialPropertyData();
                this.getCommercialSpecialFeatures();
                this.getCommercialFeatures();
            }else {
                this.getSpecialFeatures();
                this.getFeatures();
                this.getDescription(this.cardProperty)
                if (this.cardProperty && this.cardProperty.feet_parking) {
                    if (
                        this.cardProperty.feet_parking.communal_garage ||
                        this.cardProperty.feet_parking.covered ||
                        this.cardProperty.feet_parking.garage ||
                        this.cardProperty.feet_parking.underground
                    ) {
                        this.coveredParking = true;
                    }
                    if (
                        this.cardProperty.feet_parking.more_than_one ||
                        this.cardProperty.feet_parking.open ||
                        this.cardProperty.feet_parking.parking ||
                        this.cardProperty.feet_parking.parking_communal ||
                        this.cardProperty.feet_parking.parking_optional ||
                        this.cardProperty.feet_parking.parking_street ||
                        this.cardProperty.feet_parking.private ||
                        this.cardProperty.feet_parking
                            .public_parking_nearby_against_a_fee
                    ) {
                        this.normalParking = true;
                    }
                    if (this.coveredParking && this.normalParking) {
                        this.bothParking = true;
                    }
                    if (this.coveredParking || this.normalParking) {
                        this.hasParking = true;
                    }
                }
                if (
                    this.cardProperty &&
                    this.cardProperty.exclusive &&
                    this.cardProperty &&
                    this.cardProperty.currentprice &&
                    this.cardProperty.oldprice &&
                    this.cardProperty.oldprice.price &&
                    this.cardProperty.oldprice.price >
                        this.cardProperty.currentprice
                ) {
                    this.reducedAndExProperty = true;
                } else if (
                    this.cardProperty &&
                    this.cardProperty.currentprice &&
                    this.cardProperty.oldprice &&
                    this.cardProperty.oldprice.price &&
                    this.cardProperty.oldprice.price >
                        this.cardProperty.currentprice
                ) {
                    this.reducedPriceTag = true;
                } else if (this.cardProperty && this.cardProperty.exclusive) {
                    this.exclusiveProperty = true;
                }
                if (
                    this.cardProperty &&
                    this.cardProperty.oldprice &&
                    this.cardProperty.oldprice.price
                ) {
                    this.oldPrice = this._currencyPipe.transform(
                        this.cardProperty.oldprice.price,
                        'EUR',
                        'symbol-narrow',
                        '1.0-2',
                        'es_AR'
                    );
                }
                if (
                    this.cardProperty &&
                    this.cardProperty.currentprice &&
                    this.cardProperty.oldprice &&
                    this.cardProperty.oldprice.price &&
                    this.cardProperty.oldprice.price >
                        this.cardProperty.currentprice
                ) {
                    this.reducedPrice = true;
                }
                if (this.cardProperty && this.cardProperty.built) {
                    this.builtPro = true;
                }
                if (this.cardProperty && this.cardProperty.plot) {
                    this.plotPro = true;
                }
                if (this.cardProperty && this.cardProperty.bedrooms) {
                    this.bedroomsPro = true;
                }
                if (this.cardProperty && this.cardProperty.bathrooms) {
                    this.bathroomsPro = true;
                }
                if (this.cardProperty && this.cardProperty.year_built && this.cardProperty.year_built != '') {
                    this.yearBuilt = this.cardProperty.year_built.toString().substring(0, 4);
                }
                if (
                    this.cardProperty &&
                    this.cardProperty.feet_furniture &&
                    this.cardProperty.feet_furniture.fully_furnished
                ) {
                    this.fullyFurnished = true;
                }
                if (
                    this.cardProperty &&
                    this.cardProperty.feet_kitchen &&
                    this.cardProperty.feet_kitchen.fully_fitted
                ) {
                    this.fullyFitted = true;
                }
                if (
                    this.cardProperty &&
                    this.cardProperty.feet_pool &&
                    (this.cardProperty.feet_pool.pool_communal ||
                        this.cardProperty.feet_pool.pool_private ||
                        this.cardProperty.feet_pool.childrens_pool ||
                        this.cardProperty.feet_pool.fenced_pool ||
                        this.cardProperty.feet_pool.pool_heated ||
                        this.cardProperty.feet_pool.pool_indoor ||
                        this.cardProperty.feet_pool.infinity_pool ||
                        this.cardProperty.feet_pool.soler_heated_pool ||
                        this.cardProperty.feet_pool.plunge_pool ||
                        this.cardProperty.feet_pool.whirlpool)
                ) {
                    this.hasPool = true;
                }
                if(this.cardProperty && this.cardProperty.energy_certificate) {
                    this.energy_certificate = this.cardProperty?.energy_certificate;
                }
                if(this.cardProperty && this.cardProperty.lt_rental){
                    this.lt_rental = this.cardProperty?.lt_rental;
                }
                if (
                    this.cardProperty?.sale &&
                    this.cardProperty?.description &&
                    this.card?.type == 'Sale'
                ) {
                    this.propertyForSale = true;
                } else if (
                    this.cardProperty?.rent &&
                    this.cardProperty?.rental_description &&
                    this.card?.type != 'Sale'
                ) {
                    this.propertyForRent = true;
                }
                // if (this.cardProperty && this.cardProperty.property_type && (this.cardProperty?.property_type?.en || this.cardProperty?.property_type?.es_AR || this.cardProperty?.property_type?.fr)) {
                //     this.propertyType = this.cardProperty?.property_type?.en ? this.cardProperty?.property_type?.en + '/' : '' + this.cardProperty?.property_type?.es_AR ? this.cardProperty?.property_type?.es_AR + '/' : '' + this.cardProperty?.property_type?.fr ? this.cardProperty?.property_type?.fr : ''
                // }
                if (
                    this.cardProperty &&
                    this.cardProperty.property_city &&
                    this.cardProperty.property_city[this.currentLang] &&
                    this.cardProperty.property_province &&
                    this.cardProperty.property_province[this.currentLang]
                ) {
                    this.propertyAddress =
                        this.cardProperty.property_city[this.currentLang] +
                        ', ' +
                        this.cardProperty.property_province[this.currentLang];
                } else if (
                    this.cardProperty &&
                    this.cardProperty.property_city &&
                    this.cardProperty.property_city[this.currentLang]
                ) {
                    this.propertyAddress =
                        this.cardProperty.property_city[this.currentLang];
                } else if (
                    this.cardProperty &&
                    this.cardProperty.property_province &&
                    this.cardProperty.property_province[this.currentLang]
                ) {
                    this.propertyAddress =
                        this.cardProperty.property_province[this.currentLang];
                }
            }
            this.getPropertyImages();
        }, 2000);
    }

    // getSpecialFeatures(old function)
    // getSpecialFeatures(): any {

    //     if (this.cardProperty?.feet_pool?.pool_communal) {
    //         const feat = {
    //             icon: 'https://images.optima-crm.com/resize/tickets_documents/607679387071e049054b7022/20/20210414071151000000_communal-privatepool.png',
    //             value: 'Communal pool'
    //         }
    //         this.special_features.push(feat);
    //     }

    //     if (this.cardProperty?.feet_pool?.pool_private) {
    //         const feat = {
    //             icon: 'https://images.optima-crm.com/resize/tickets_documents/607679387071e049054b7022/20/20210414071151000000_communal-privatepool.png',
    //             value: 'Private pool'
    //         }
    //         this.special_features.push(feat);
    //     }

    //     if (this.cardProperty?.feet_views?.sea) {
    //         const feat = {
    //             icon: 'https://images.optima-crm.com/resize/tickets_documents/607679387071e049054b7022/20/20210414071151000000_SeaView.png',
    //             value: 'Sea view'
    //         }
    //         this.special_features.push(feat);
    //     }

    //     if (this.cardProperty?.custom_categories.includes(129)) {
    //         const feat = {
    //             icon: 'https://images.optima-crm.com/resize/tickets_documents/607679387071e049054b7022/20/20210414071151000000_rentallicence.png',
    //             value: 'Rental license'
    //         }
    //         this.special_features.push(feat);
    //     }

    //     if (this.cardProperty?.feet_furniture?.fully_furnished) {
    //         const feat = {
    //             icon: 'https://images.optima-crm.com/resize/tickets_documents/607679387071e049054b7022/20/20210414071151000000_fullyfurnished.png',
    //             value: 'Fully furnished'
    //         }
    //         this.special_features.push(feat);
    //     }

    //     if (this.cardProperty?.terrace?.terrace && this.cardProperty.terrace.terrace != '') {
    //         const feat = {
    //             icon: 'https://images.optima-crm.com/resize/tickets_documents/607679387071e049054b7022/20/20210414071151000000_terrace.png',
    //             value: 'Terrace 50 m2'
    //         }
    //         this.special_features.push(feat);
    //     }
    // }

    getSpecialFeatures(): any {
        if (this.cardProperty?.feet_pool?.pool_communal) {
            const feat = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/6131f67696596272c67d9624/300/20210909141707000000_Pool.png',
                value: 'Communal pool',
            };
            const feat_temp_34 = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/607679387071e049054b7022/20/20210414071151000000_communal-privatepool.png',
                value: 'Communal pool',
            };
            this.special_features.push(feat_temp_34);
        }

        if (this.cardProperty?.feet_pool?.pool_private) {
            const feat = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/6131f67696596272c67d9624/300/20210909141707000000_Pool.png',
                value: 'Private pool',
            };
            const feat_temp_34 = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/607679387071e049054b7022/20/20210414071151000000_communal-privatepool.png',
                value: 'Private pool',
            };
            this.special_features.push(feat_temp_34);
        }

        if (this.cardProperty?.feet_views?.sea) {
            const feat = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/6131f67696596272c67d9624/300/20210909141707000000_SeaView.png',
                value: 'Sea view',
            };
            const feat_temp_34 = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/607679387071e049054b7022/20/20210414071151000000_SeaView.png',
                value: 'Sea view'
            }
            this.special_features.push(feat_temp_34);
        }

        // https://images.optima-crm.com/resize/tickets_documents/6131f67696596272c67d9624/300/20210909141707000000_RentalLicense.png
        if (this.cardProperty?.custom_categories?.includes(129)) {
            const feat = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/6123b2a56e1a0c42da34a89c/300/20210921090111000000_RentalLicense.png',
                value: 'Rental license',
            };
            const feat_temp_34 = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/607679387071e049054b7022/20/20210414071151000000_rentallicence.png',
                value: 'Rental license'
            }
            this.special_features.push(feat_temp_34);
        }

        if (this.cardProperty?.feet_furniture?.fully_furnished) {
            const feat = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/6131f67696596272c67d9624/300/20210909141707000000_FullyFurnished.png',
                value: 'Fully furnished',
            };
            const feat_temp_34 = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/607679387071e049054b7022/20/20210414071151000000_fullyfurnished.png',
                value: 'Fully furnished'
            }
            this.special_features.push(feat_temp_34);
        }

        // old pic
        // https://images.optima-crm.com/resize/tickets_documents/6131f67696596272c67d9624/300/20210909141707000000_Terrace.png
        if (this.cardProperty?.terrace?.terrace && this.cardProperty.terrace.terrace != '') {
            const feat = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/6123b2a56e1a0c42da34a89c/300/20210921090111000000_Terrace.png',
                value: 'Terrace 50 m2',
            };
            const feat_temp_34 = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/607679387071e049054b7022/20/20210414071151000000_terrace.png',
                value: 'Terrace 50 m2'
            }
            this.special_features.push(feat_temp_34);
        }
    }

    getFeatures(): any {
        let features = [];
        _.forEach(this.cardProperty?.feet_categories, function (value, key) {
            if (value) {
                features.push(key);
            }
        });
        _.forEach(
            this.cardProperty?.feet_climate_control,
            function (value, key) {
                if (value) {
                    features.push(key);
                }
            }
        );
        _.forEach(this.cardProperty?.feet_condition, function (value, key) {
            if (value) {
                features.push(key);
            }
        });
        _.forEach(this.cardProperty?.feet_features, function (value, key) {
            if (value) {
                features.push(key);
            }
        });
        _.forEach(this.cardProperty?.feet_furniture, function (value, key) {
            if (value) {
                features.push(key);
            }
        });
        _.forEach(this.cardProperty?.feet_garden, function (value, key) {
            if (value) {
                features.push(key);
            }
        });
        _.forEach(this.cardProperty?.feet_kitchen, function (value, key) {
            if (value) {
                features.push(key);
            }
        });
        _.forEach(this.cardProperty?.feet_leisure, function (value, key) {
            if (value) {
                features.push(key);
            }
        });
        _.forEach(this.cardProperty?.feet_living_room, function (value, key) {
            if (value) {
                features.push(key);
            }
        });
        _.forEach(this.cardProperty?.feet_orientation, function (value, key) {
            if (value) {
                features.push(key);
            }
        });
        _.forEach(this.cardProperty?.feet_parking, function (value, key) {
            if (value) {
                features.push(key);
            }
        });
        _.forEach(this.cardProperty?.feet_pool, function (value, key) {
            if (value) {
                features.push(key);
            }
        });
        _.forEach(this.cardProperty?.feet_security, function (value, key) {
            if (value) {
                features.push(key);
            }
        });
        _.forEach(this.cardProperty?.feet_setting, function (value, key) {
            if (value) {
                features.push(key);
            }
        });
        _.forEach(this.cardProperty?.feet_utilities, function (value, key) {
            if (value) {
                features.push(key);
            }
        });
        _.forEach(this.cardProperty?.feet_views, function (value, key) {
            if (value) {
                features.push(key);
            }
        });
        this.features = features;
    }
    getCommercialSpecialFeatures() {  
        if (this.cardProperty?.pool?.pool_communal) {
            const feat = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/6131f67696596272c67d9624/300/20210909141707000000_Pool.png',
                value: 'Communal pool',
            };
            const feat_temp_34 = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/607679387071e049054b7022/20/20210414071151000000_communal-privatepool.png',
                value: 'Communal pool',
            };
            this.special_features.push(feat_temp_34);
        }

        if (this.cardProperty?.pool?.pool_private) {
            const feat = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/6131f67696596272c67d9624/300/20210909141707000000_Pool.png',
                value: 'Private pool',
            };
            const feat_temp_34 = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/607679387071e049054b7022/20/20210414071151000000_communal-privatepool.png',
                value: 'Private pool',
            };
            this.special_features.push(feat_temp_34);
        }

        if (this.cardProperty?.views?.sea) {
            const feat = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/6131f67696596272c67d9624/300/20210909141707000000_SeaView.png',
                value: 'Sea view',
            };
            const feat_temp_34 = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/607679387071e049054b7022/20/20210414071151000000_SeaView.png',
                value: 'Sea view'
            }
            this.special_features.push(feat_temp_34);
        }

        if (this.cardProperty?.furniture?.fully_furnished) {
            const feat = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/6131f67696596272c67d9624/300/20210909141707000000_FullyFurnished.png',
                value: 'Fully furnished',
            };
            const feat_temp_34 = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/607679387071e049054b7022/20/20210414071151000000_fullyfurnished.png',
                value: 'Fully furnished'
            }
            this.special_features.push(feat_temp_34);
        }

        if (this.cardProperty?.terraces?.terrace && this.cardProperty.terraces.terrace != '') {
            const feat = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/6123b2a56e1a0c42da34a89c/300/20210921090111000000_Terrace.png',
                value: 'Terrace 50 m2',
            };
            const feat_temp_34 = {
                icon: 'https://images.optima-crm.com/resize/tickets_documents/607679387071e049054b7022/20/20210414071151000000_terrace.png',
                value: 'Terrace 50 m2'
            }
            this.special_features.push(feat_temp_34);
        }
    }

    getCommercialFeatures() {
        const features = [];
        let propertyCategories = ['categories', 'climate_control','conditions','features','furniture','garden','kitchen','feet_leisure','living_room','orientations','parking','pool','security','settings','utility','views']
        let replaceObj = [
            { comm_key : 'categories', resd_key : 'feet_categories'},
            { comm_key : 'features' ,resd_key : 'feet_features'},
            { comm_key : 'climate_control' ,resd_key : 'feet_climate_control'},
            { comm_key : 'kitchen' ,resd_key : 'feet_kitchen'},
            { comm_key : 'settings' ,resd_key : 'feet_setting'},
            { comm_key : 'orientations' ,resd_key : 'feet_orientation'},
            { comm_key : 'views' ,resd_key : 'feet_views'},
            { comm_key : 'utility' ,resd_key : 'feet_utilities'},
            { comm_key : 'security' ,resd_key : 'feet_security'},
            { comm_key : 'furniture' ,resd_key : 'feet_furniture'},
            { comm_key : 'garden' ,resd_key : 'feet_garden'},
            { comm_key : 'pool' ,resd_key : 'feet_pool'},
            { comm_key : 'conditions' ,resd_key : 'feet_condition'}
        ];
        propertyCategories.forEach(category => {
            const property = this.cardProperty?.[category];
            if (property) {
                features.push(...Object.keys(property).filter(key => property[key]));
                const replaceKey = replaceObj.find(obj => obj.comm_key === category);
                if (replaceKey) {
                    this.cardProperty[replaceKey.resd_key] = this.cardProperty[category];
                    delete this.cardProperty[category];
                }
            }
        });
        this.features = features;
    }

    setCommercialPropertyData() {
        const property = this.cardProperty;
        if (!property) {
            return;
        }
        // Parking
        const parking = property.parking || {};
        const parkingOptions = [ parking.communal_garage, parking.covered, parking.garage, parking.underground, parking.more_than_one, parking.open, parking.parking, parking.parking_communal, parking.parking_optional, parking.parking_street, parking.private, parking.public_parking_nearby_against_a_fee ];
        this.coveredParking = parkingOptions.some(option => option);
        this.normalParking = parkingOptions.slice(4).some(option => option);
        this.bothParking = this.coveredParking && this.normalParking;
        this.hasParking = this.coveredParking || this.normalParking;
        // Price
        const currentPrice = property?.current_price ? property.current_price : (this.cardProperty?.currentprice ? this.cardProperty.currentprice : 0);        
        property.currentprice = currentPrice;
        this.cardProperty.currentprice = currentPrice;
        property.terrace = property?.terraces;
        const oldPrice = property?.old_price;
        this.reducedAndExProperty = property.exclusive && oldPrice > currentPrice;
        this.reducedPrice = currentPrice && oldPrice && oldPrice > currentPrice;
        this.reducedPriceTag = oldPrice > currentPrice;
        this.exclusiveProperty = property.exclusive;
        this.oldPrice = this._currencyPipe.transform(oldPrice, 'EUR', 'symbol-narrow', '1.0-2', 'es_AR');
    
        // Other property details
        this.builtPro = !!property.built;
        this.plotPro = !!property.plot;
        this.bedroomsPro = !!property.bedrooms;
        this.bathroomsPro = !!property.bathrooms;
        this.yearBuilt = property.year_built ? property.year_built.toString().substring(0, 4) : '';
    
        // Furniture and kitchen
        this.fullyFurnished = property.furniture?.fully_furnished;
        this.fullyFitted = property.kitchen?.fully_fitted;
    
        // Pool
        const pool = property.pool || {};
        const poolOptions = [
            pool.pool_communal, pool.pool_private, pool.childrens_pool, pool.fenced_pool,
            pool.pool_heated, pool.pool_indoor, pool.infinity_pool, pool.soler_heated_pool,
            pool.plunge_pool, pool.whirlpool
        ];
        this.hasPool = poolOptions.some(option => option);
    
        // Energy certificate and rental
        this.energy_certificate = property.energy_certificate_one;
        this.lt_rental = property.lt_rental;
    
        // Property type
        this.propertyForSale = property.sale && property.description && this.card?.type === 'Sale';
        this.propertyForRent = property.rent && property.rental_description && this.card?.type !== 'Sale';
        // Property address
        const city = property.property_city?.[this.currentLang];
        const province = property.property_province?.[this.currentLang];
        this.cardProperty.location_name = property?.property_location?.[this.currentLang];
        this.propertyAddress = [city, province].filter(Boolean).join(', ');
        // URL
        let url = '';
        if (property.website_url) {
            const saleUrl = property.website_url?.sale?.en;
            const rentUrl = property.website_url?.rent?.en;
            url = (property.sale && saleUrl) || (property.rent && rentUrl) || '';
        }
        property.url = url;
        this.getDescription(property)
    }

    getPropertyImages(): any {
        let model_type = "properties_images";
        if(this.requestFrom == "commercials" && !this.cardProperty?.res_id){
            model_type = "commercial_images"
        }
        let propertyAttachments = [];
        _.forEach(this.cardProperty?.images, function (value, key) {
            if (value) {
                let url = '';
                if (value.model_id && value.file_md5_name && !value.document) {
                    url ='https://images.optima-crm.com/resize/'+model_type+'/'+value.model_id+'/800/'+value.file_md5_name;
                   //url = 'http://localhost/ng-crm/uploads/'+model_type+'/'+ value.model_id + '/' + value.file_md5_name;
                }
                const image = {
                    key: key,
                    url: url,
                };
                propertyAttachments.push(image);
            }
        });
        propertyAttachments.shift();
        this.lastItem = propertyAttachments.pop();
        this.propertyAttachments = propertyAttachments;
    }

    formatePhone(n: string): any {
        if (n && typeof n === 'string') {
            return n
                .replace(/[^\dA-Z]/g, '')
                .replace(/(.{3})/g, '$1 ')
                .trim();
        }
        else return n;
    }

    spaceSeprator(n: string): any {
        if (n && typeof n === 'string') {
            return n.replace(/,/g, ' ');
        }
        else return n;
    }

    sanitizeUrl(url: string): any {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    sanitizeStyle(style: string): any {
        return this.sanitizer.bypassSecurityTrustStyle(style);
    }

    attachmentNext(imgTab: string): any {
        if (imgTab === 'first') {
            if (this.firstImg < this.cardProperty.images.length - 1) {
                this.firstImg++;
            } else {
                this.firstImg = 0;
            }
        }
        if (imgTab === 'second') {
            if (this.secondImg < this.cardProperty.images.length - 1) {
                this.secondImg++;
            } else {
                this.secondImg = 0;
            }
        }
        if (imgTab === 'third') {
            if (this.thirdImg < this.cardProperty.images.length - 1) {
                this.thirdImg++;
            } else {
                this.thirdImg = 0;
            }
        }
        if (imgTab === 'fourth') {
            if (this.fourthImg < this.cardProperty.images.length - 1) {
                this.fourthImg++;
            } else {
                this.fourthImg = 0;
            }
        }
        if (imgTab === 'fifth') {
            if (this.fifthImg < this.cardProperty.images.length - 1) {
                this.fifthImg++;
            } else {
                this.fifthImg = 0;
            }
        }
        if (imgTab === 'sixth') {
            if (this.sixthImg < this.cardProperty.images.length - 1) {
                this.sixthImg++;
            } else {
                this.sixthImg = 0;
            }
        }
        if (imgTab === 'seventh') {
            if (this.seventhImg < this.cardProperty.images.length - 1) {
                this.seventhImg++;
            } else {
                this.seventhImg = 0;
            }
        }
        if (imgTab === 'eigth') {
            if (this.eigthImg < this.cardProperty.images.length - 1) {
                this.eigthImg++;
            } else {
                this.eigthImg = 0;
            }
        }
        if (imgTab === 'ninth') {
            if (this.ninthImg < this.cardProperty.images.length - 1) {
                this.ninthImg++;
            } else {
                this.ninthImg = 0;
            }
        }
    }

    attachmentBack(imgTab: string): any {
        if (imgTab === 'first') {
            if (this.firstImg === 0) {
                this.firstImg = this.cardProperty.images.length - 1;
            } else {
                this.firstImg--;
            }
        }
        if (imgTab === 'second') {
            if (this.secondImg === 0) {
                this.secondImg = this.cardProperty.images.length - 1;
            } else {
                this.secondImg--;
            }
        }
        if (imgTab === 'third') {
            if (this.thirdImg === 0) {
                this.thirdImg = this.cardProperty.images.length - 1;
            } else {
                this.thirdImg--;
            }
        }
        if (imgTab === 'fourth') {
            if (this.fourthImg === 0) {
                this.fourthImg = this.cardProperty.images.length - 1;
            } else {
                this.fourthImg--;
            }
        }
        if (imgTab === 'fifth') {
            if (this.fifthImg === 0) {
                this.fifthImg = this.cardProperty.images.length - 1;
            } else {
                this.fifthImg--;
            }
        }
        if (imgTab === 'sixth') {
            if (this.sixthImg === 0) {
                this.sixthImg = this.cardProperty.images.length - 1;
            } else {
                this.sixthImg--;
            }
        }
        if (imgTab === 'seventh') {
            if (this.seventhImg === 0) {
                this.seventhImg = this.cardProperty.images.length - 1;
            } else {
                this.seventhImg--;
            }
        }
        if (imgTab === 'eigth') {
            if (this.eigthImg === 0) {
                this.eigthImg = this.cardProperty.images.length - 1;
            } else {
                this.eigthImg--;
            }
        }
        if (imgTab === 'ninth') {
            if (this.ninthImg === 0) {
                this.ninthImg = this.cardProperty.images.length - 1;
            } else {
                this.ninthImg--;
            }
        }
    }

    updateBoatMooringData() {
        let boatMooringpipe = new GetBoatMooringUrlPipe(this._translateService);
        this.cardProperty.url = boatMooringpipe.transform(
            this.cardProperty,
            this.currentLang
        );
        this.cardProperty.currentprice = this.cardProperty.current_price;
        this.cardProperty.feet_categories = this.cardProperty.categories;
        this.cardProperty.feet_condition = this.cardProperty.condition;
        this.cardProperty.feet_features = this.cardProperty.features;
        this.cardProperty.property_type =
            this.cardProperty == 'boat'
                ? this.cardProperty.boat_type?.this.cardProperty.boat_type.value
                : this.cardProperty.type_one;
        this.cardProperty.year_built = this.cardProperty.date_built
            ? new Date(this.cardProperty.date_built).getFullYear()
            : '';
        this.cardProperty.feet_kitchen = this.cardProperty.kitchen;
        this.cardProperty.feet_security = this.cardProperty.security;
        if (
            this.cardProperty.property_attachments &&
            this.cardProperty.property_attachments.length
        ) {
            this.cardProperty.images = this.cardProperty.property_attachments;
        }
        delete this.cardProperty.agency_data;
        delete this.cardProperty.current_price;
        delete this.cardProperty.categories;
        delete this.cardProperty.condition;
        delete this.cardProperty.features;
        delete this.cardProperty.kitchen;
        delete this.cardProperty.security;
    }

    translateFinnish(text: string) {
        const propertyTypeFinnishTranslations = {
            Apartment: 'Kerrostalohuoneisto',
            'Estate/Finca': 'Maa-/ metsätila, huvila maalla',
            Parking: 'Parkkipaikka',
            Plot: 'Tontti',
            Building: 'Kokonainen rakennus',
            House: 'Rivitalo',
            Commercial: 'Liiketila',
        };

        return propertyTypeFinnishTranslations[text]
            ? propertyTypeFinnishTranslations[text]
            : text;
    }

    getDescription(property) {
        if(property && this.discKey) {
            let description: any;
            if(this.card && this.card?.type) {
                const type = this.card?.type;
                if(type == 'Sale') {
                    description = property?.description?.[this.discKey];
                }else if(type == 'STR' || type == 'LTR') {
                    description = property?.rental_description?.[this.discKey];
                }
            }
            if (description) {
              this.limitedDescription = this.truncateDescription(description, 3200);
            } else {
              this.limitedDescription = 'no description';
            }
        }
    }

    // fit the character within limit while ensuring that it stops at the end of a complete sentence.
    truncateDescription(description: string, maxLength: number) {
        const trimmedDescription = description.replace(/<[^>]*>/g, '').replace(/\s+/g, ' ').trim();
        if (trimmedDescription.length <= maxLength) {
          return trimmedDescription;
        }
        const shortenedDescription = trimmedDescription.substring(0, maxLength);
        const lastSentenceEnd = shortenedDescription.lastIndexOf('.');
        return lastSentenceEnd !== -1 ? shortenedDescription.substring(0, lastSentenceEnd + 1) : shortenedDescription;
    }

}

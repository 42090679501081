import { Component, OnInit, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AuthenticationService, UserService, AgenciesService } from 'app/services';
import { Subject } from 'rxjs';
import { find, map, orderBy, indexOf, toUpper, filter } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { SubscribtionComponent } from 'app/main/authentication/register/subscribtion/subscribtion.component';
import { getCurrencySymbol } from '@angular/common';
import { BaseService } from 'app/_helpers/base/base.service';

@Component({
    selector: 'plans-dialog',
    templateUrl: './plans-dialog.component.html',
    styleUrls: ['./plans-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PlansDialogComponent implements OnInit, OnDestroy {

    currentUser: any;
    currentAgency: any;
    euCountries = [15, 22, 34, 54, 56, 57, 58, 67, 72, 73, 81, 84, 249, 103, 105, 117, 123, 124, 132, 150, 170, 171, 175, 191, 192, 1, 202];
    displayedColumns: string[] = ['info'];
    dataSource = [
        { info: '' },
        { info: 'mls_national' },
        { info: 'mls_international' },
        { info: 'minimum_share_limit' },
        { info: 'properties_up_to' },
        { info: 'define_sharing_level' },
        { info: 'exact_location_required' },
        { info: 'alternative_coordinates' },
        { info: 'virtual_tours' },
        { info: 'generate_wc' },
        { info: 'create_own_wc' },
        { info: 'edit_properties' },
        { info: 'share_nd' },
        { info: 'xml_import_export' },
        { info: 'publish_to_portals' },
        { info: 'create_viewing_request' },
        { info: 'calendar_activities_management' },
        { info: 'crm_functions' },
        { info: 'market_place' },
        { info: 'realtity_app' },
        { info: 'six_languages_included' },
        { info: 'users' },
        { info: '' }
    ];
    plans: any;
    planChoosed: boolean;
    products: any = [];
    isUe: boolean;
    billingType = 'monthly';
    loader: boolean;
    private _unsubscribeAll: Subject<any>;
    user: any;

    constructor(
        public matDialogRef: MatDialogRef<PlansDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _authenticationService: AuthenticationService,
        private _userService: UserService,
        private _agenciesService: AgenciesService,
        public matDialog: MatDialog,
        private _baseService: BaseService,
    ) {
        this.currentUser = this._authenticationService.currentUserValue;
        this.currentAgency = this._authenticationService.currentAgencyValue;
        this.isUe = indexOf(this.euCountries, this.currentUser.default_country) > -1 ? true : false;
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.loader = true;
        this._userService.getById(this.currentUser._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: any) => this.user = user);

        this._userService.getProducts()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((products: any) => {
                this.products = products;
                this.setProducts();
                this.loader = false;
            }, () => this.loader = false);
    }

    setProducts(): void {
        let unitLabel = '';
        if (this.isUe) {
            unitLabel = 'ue-monthly';
            if (this.billingType === 'annually') {
                unitLabel = 'ue-yearly';
            }
        }
        if (!this.isUe) {
            unitLabel = 'noue-monthly';
            if (this.billingType === 'annually') {
                unitLabel = 'noue-yearly';
            }
        }
        console.log(this.isUe);
        console.log(this.currentUser.default_country);
        console.log(unitLabel);
        const product = find(this.products, { statement_descriptor: 'mlsproduct', unit_label: unitLabel });
        if (product) {
            this.displayedColumns = ['info'];
            let plans = product.plans;
            plans = orderBy(plans, ['metadata.order'], ['asc']);
            // if (this.currentUser.user_role === 'mls_pro') {
            plans = filter(plans, (p) => p.amount !== 0);
            // }
            const planNames = map(plans, (plan) => {
                this.dataSource = map(this.dataSource, (data) => {
                    data[plan.nickname] = plan.metadata[data.info] ? plan.metadata[data.info] : (!data.info ? '' : '-');
                    return data;
                });
                return plan.nickname;
            });
            plans = map(plans, (plan) => {
                plan.currency = getCurrencySymbol(toUpper(plan.currency), 'narrow');
                return plan;
            });
            this.displayedColumns = [...this.displayedColumns, ...planNames];
            this.plans = plans;
        }
    }

    subscribtion(plan: any): void {
        this.loader = true;
        if (plan.amount === 0) {
            this.updateUser(plan);
        } else {
            const formData = {
                email: this.currentUser.email,
                full_name: this.currentUser.full_name,
            };
            const matDialog = this.matDialog.open(SubscribtionComponent, {
                width: '450px',
                data: {
                    formData: formData,
                    plan: plan
                }
            });
            matDialog.afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((subscribtion: any) => {
                    if (subscribtion) {
                        this.updateUser(plan, subscribtion);
                    } else {
                        this.loader = false;
                    }
                });
        }
    }

    updateUser(plan?: any, subscribtion?: any): void {

        this.planChoosed = true;

        this.currentUser.user_role = subscribtion && subscribtion.id ? 'mls_pro' : 'mls_user';

        this.currentAgency.mls_agency = true;
        this.currentAgency.mls_national = (plan && plan.metadata && plan.metadata.mls_national) === 'true' ? true : false;
        this.currentAgency.mls_international = (plan && plan.metadata && plan.metadata.mls_international) === 'true' ? true : false;
        this.currentAgency.properties_up_to = (plan && plan.metadata && plan.metadata.properties_up_to) ? plan.metadata.properties_up_to : '25';
        this.currentAgency.define_sharing_level = (plan && plan.metadata && plan.metadata.define_sharing_level) === 'true' ? true : false;
        this.currentAgency.exact_location_required = (plan && plan.metadata && plan.metadata.exact_location_required) === 'true' ? true : false;
        this.currentAgency.alternative_coordinates = (plan && plan.metadata && plan.metadata.alternative_coordinates) === 'true' ? true : false;
        this.currentAgency.generate_wc = (plan && plan.metadata && plan.metadata.generate_wc) === 'true' ? true : false;
        this.currentAgency.create_own_wc = (plan && plan.metadata && plan.metadata.create_own_wc) === 'true' ? true : false;
        this.currentAgency.edit_properties = (plan && plan.metadata && plan.metadata.edit_properties) === 'true' ? true : false;
        this.currentAgency.share_nd = (plan && plan.metadata && plan.metadata.share_nd) === 'true' ? true : false;
        this.currentAgency.xml_import_export = (plan && plan.metadata && plan.metadata.xml_import_export) === 'true' ? true : false;
        this.currentAgency.create_viewing_request = (plan && plan.metadata && plan.metadata.create_viewing_request) === 'true' ? true : false;
        this.currentAgency.calendar_activities_management = (plan && plan.metadata && plan.metadata.calendar_activities_management) === 'true' ? true : false;
        this.currentAgency.crm_functions = (plan && plan.metadata && plan.metadata.crm_functions) === 'true' ? true : false;
        this.currentAgency.market_place = (plan && plan.metadata && plan.metadata.market_place) === 'true' ? true : false;
        this.currentAgency.realtity_app = (plan && plan.metadata && plan.metadata.realtity_app) ? plan.metadata.realtity_app : '';
        this.currentAgency.virtual_tours = (plan && plan.metadata && plan.metadata.virtual_tours) === 'true' ? true : false;
        this.currentAgency.publish_to_portals = (plan && plan.metadata && plan.metadata.publish_to_portals) === 'true' ? true : false;
        this.currentAgency.six_languages_included = (plan && plan.metadata && plan.metadata.six_languages_included) === 'true' ? true : false;
        this.currentAgency.user_limit = (plan && plan.metadata && plan.metadata.users) ? plan.metadata.users : '1';
        this.currentAgency.subscribtion = subscribtion ? subscribtion.id : 'free';
        if (this.currentUser.user_role == 'mls_pro') {
            this.currentAgency.contacts_up_to = 'unlimited';
            this.currentAgency.export_basket_limit = 'unlimited';
            this.currentAgency.minimum_share_limit = 0;
        } else {
            this.currentAgency.contacts_up_to = (plan?.metadata?.contacts_up_to) ? plan.metadata.contacts_up_to : '20';
            this.currentAgency.export_basket_limit = (plan?.metadata?.export_basket_limit) ? plan.metadata.export_basket_limit : '3';
            this.currentAgency.minimum_share_limit = (plan && plan.metadata && plan.metadata.minimum_share_limit) ? plan.metadata.minimum_share_limit : '10';
        }

        this._authenticationService.setCurrentUser(this.currentUser);

        this.user.role = this.currentUser.user_role;
        this._userService.update(this.user)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._baseService.succussMessage('subscription updated sucessfuly', 'updated');
                this.loader = false;
                this.matDialogRef.close();
            });

        this._agenciesService.update(this.currentAgency)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((agency: any) => {
                this._authenticationService.setCurrentAgency(agency);
                this.loader = false;
            });

    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}

import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { BaseService } from 'app/_helpers/base/base.service';
import { UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { startWith, tap, map as Map, takeUntil, finalize } from 'rxjs/operators';
import { Subject, forkJoin } from 'rxjs';
import { GLOBALS } from 'app/config/globals';
import { AttachmentsService } from '../../../../app/services/attachments.service';
import { concat, forEach, last, map, remove, split } from 'lodash';
import { environment } from 'environments/environment';

@Component({
    selector: 'whatsapp-message',
    templateUrl: './whatsapp-message.component.html',
    styleUrls: ['./whatsapp-message.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WhatsappMessageComponent implements OnInit {

    composeForm: UntypedFormGroup;
    ids: any = [];
    displayedColumns: string[] = ['name', 'actions'];
    loadingResults: boolean;
    owner: boolean;
    activeEditor: any;
    filteredTemplates: any[];
    public tinyMc = GLOBALS.tinyMce; // To use centralized tinyMce
    public availableTemplates: any[] = [];
    templateSearch: UntypedFormControl = new UntypedFormControl('');
    searching: boolean;
    templateLanguage: string = '';
    private _unsubscribeAll: Subject<any>;
    attachments: string[] = [];
    loadigResult: boolean = false;
    env: { cdnUrl: string };


    constructor(
        public matDialogRef: MatDialogRef<WhatsappMessageComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar,
        private _toaster: ToastrService,
        private _baseService: BaseService,
        private _attachmentService: AttachmentsService
    ) {
        this.tinyMc.setup = (editor) => {
            editor.on('init', (e) => {
                this.activeEditor = editor;
            });
        };
        this.ids = this._data?.ids;
        this.owner = this._data?.owner;
        this._unsubscribeAll = new Subject();
        this.env = environment;
    }

    ngOnInit(): void {
        this.getWaTemplates();
        this.composeForm = this.createComposeForm();
    }

    getWaTemplates() {
        this._baseService.get('external-service/wa-templates', 'nodejs').subscribe((data: any) => {
            this.availableTemplates = data?.data;
            this.filteredTemplates = data?.data;
            this.filterTemplate();
        });
    }

    createComposeForm(): UntypedFormGroup {
        return new UntypedFormGroup({
            template: new UntypedFormControl({value:''}),
            template_language: new UntypedFormControl({value:''}),
            content: new UntypedFormControl({ value: ''}),
            components: new UntypedFormControl({ value: ''}),
        });
    }

    filterTemplate(): void{
        this.templateSearch.valueChanges
        .pipe(
            startWith(''),
            tap(() => this.searching = true),
            Map((search: string) => {
                const data = this.availableTemplates
                return data.filter(template => template.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
            })
        )
        .subscribe((data: any[]) => {
            this.filteredTemplates = data;
            this.searching = false;
        });
    }

    templateSelect() {
        if (!this.composeForm.get('template').value) {
            this.composeForm.get('content').setValue('');
        } else {
            const selectedTemplate = this.availableTemplates.filter(template => template.name === this.composeForm.get('template').value);
            if (selectedTemplate.length) {
                this.composeForm.get('content').setValue(
                    this.prepareMessage(selectedTemplate)
                )
                this.composeForm.get('template_language').setValue(selectedTemplate[0]?.language);
                this.composeForm.get('components').setValue(selectedTemplate[0].components);
            }
        }
    }

    sendMessage(){
        let dataToPost = this.composeForm.getRawValue();
        if(this.attachments.length){
            dataToPost['attachments'] = this.attachments;
        }
        this.matDialogRef.close(dataToPost);
    }

    convertNewlinesAndEscapeHtml(input) {
        let escapedHtml = this.escapeHtml(input);
        return escapedHtml.replace(/\n/g, "<br>");
    }

    escapeHtml(str) {
        return str.replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }

    prepareMessage(selectedTemplate) {
        let message = '';
        for (const component of selectedTemplate[0].components) {
            const type = component?.type.trim().toLowerCase();
            if (type === 'header') {
                const format = component?.format.trim().toLowerCase(); // `component.format` only available in header type
                if(format === 'text'){
                    message += component?.text;
                }else if(format === 'document'){
                    message += '{{Attachment}}';
                }
            }
            else if (type === 'body') {
                if (message.length) {
                    message += '\n\n';
                }
                message += component?.text;
            }
            else if (type === 'footer') {
                message += `\n\n${component?.text}`;
            }
        }
        return this.convertNewlinesAndEscapeHtml(message);
    }

    onSelectFile(files: FileList): void {
        let size = 0;
        let renameFiles = []

        forEach(files, (file: File) => {
            size = file.size + size;
            renameFiles.push(this.renameFileWithTimestamp(file))
        });

        if (size <= 104857600) { // whatsapp limit to upload max size document is 100MB
            const uploader = [];
            this.loadigResult = true;
            forEach(renameFiles, (file: File) => {
                uploader.push(this._attachmentService.mailAttachment(file, 'whatsapp', ''));
            });
            forkJoin(uploader)
                .pipe(
                    takeUntil(this._unsubscribeAll),
                    finalize(() => this.loadigResult = false) 
                )
                .subscribe((data: any) => {
                    const attachments = map(data, (url) => {
                        return this.env.cdnUrl+'whatsapp/'+this.currentDate()+'/'+last(split(url, '/'));
                    });
                    this.attachments = concat(this.attachments, attachments);
                }, () => {});
        } else {
            this._snackBar.open(this._translateService.instant('File size must be less than 5MB'), this._translateService.instant('Close'), {
                duration: 3000,
            });
            forEach(files, (file: File) => {
                this.removeAttachment(file);
            });
        }
    }

    renameFileWithTimestamp(file) {
        const timestamp = Math.round(Date.now() / 1000); // Get current timestamp in milliseconds
        const extension = file.name.split('.').pop(); // Extract file extension
        const baseName = file.name.replace(/\.[^/.]+$/, ""); // Remove existing extension
        const newName = `${baseName}_${timestamp}.${extension}`; // Create new name with timestamp
    
        return new File([file], newName, { type: file.type });
    }

    currentDate(){
        var now = new Date();
        var day = ("0" + now.getDate()).slice(-2);
        var month = ("0" + (now.getMonth() + 1)).slice(-2);
        return month + "-" + day + "-" + now.getFullYear();
    }

    removeAttachment(file: any): void {
        remove(this.attachments, (el: any) => {
            return el === file;
        });
    }

}

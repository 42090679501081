import { Component, OnInit, ViewEncapsulation, ViewChild, Inject, AfterViewInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewingToursFormComponent } from '../viewing-tours-create/viewing-tours-form/viewing-tours-form.component';
import { ViewingToursService } from 'app/services/viewing-tours.service';
import * as _ from 'lodash';
import { forEach } from 'lodash';
import { DatePipe } from '@angular/common';
import { UntypedFormArray } from '@angular/forms';
import { TimeLineDataService } from 'app/components/time-line/time-line-data.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalFuntions } from 'app/_helpers';
import { AuthenticationService } from 'app/services/authentication.service';

@Component({
  selector: 'app-viewing-tours-modal',
  templateUrl: './viewing-tours-modal.component.html',
  styleUrls: ['./viewing-tours-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})
export class ViewingToursModalComponent implements OnInit, AfterViewInit, AfterViewChecked {

  data: any;
  list: UntypedFormArray;
  borderColor: string;
  loadingResults = false;
  toggle = false as boolean;
  isClick = false as boolean;
  sharedProperty = false;
  status: any;
  userAgencyData: any;

  @ViewChild(ViewingToursFormComponent, { static: true }) viewingCreateForm: ViewingToursFormComponent;
  calendarViewingId: any;

  constructor(
    public matDialogRef: MatDialogRef<ViewingToursModalComponent>,
    private datePipe: DatePipe,
    private _viewingsService: ViewingToursService,
    private _timeLineData: TimeLineDataService,
    private _fuseSidebarService: FuseSidebarService,
    private globalFunction: GlobalFuntions,
    private _translateService: TranslateService,
    private cdRef: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    private _authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) {
    if (!_.isEmpty(this._data)) {
      this.data = this._data;
      // use for dashboard-calendar we set _id to show calendar data 
      this.calendarViewingId = this.data?.data?._id;
      if (this.data && this.data.data && this.data.data.color) {
        this.borderColor = this.data.data.color;
      }
    }
    this.userAgencyData = this._authenticationService.currentAgencyValue;
  }

  ngAfterViewInit(): void {
    if (this.data?.prop_id && this.data?.ref) {
      this.viewingCreateForm.propertiesIds.push(this.data.ref);
      this.viewingCreateForm.viewing.get('viewing_for').setValue('Property');
      this.viewingCreateForm.viewing.get('list.' + 0 + '.property').setValue(this.data.prop_id);
      this.viewingCreateForm.propertySearch$.next('');
      this.sharedProperty = this.data.shared;
      this.status = this.data.status ? this.data.status : '';
      setTimeout(() => {
        this.viewingCreateForm.onSelectProperty(0);
      }, 4000);
    } else if (this.data?.com_id && this.data?.ref) {
      this.viewingCreateForm.commPropIds.push(this.data.ref);
      this.viewingCreateForm.viewing.get('viewing_for').setValue('Commercial Properties');
      this.viewingCreateForm.viewing.get('list.' + 0 + '.commercial_property').setValue(this.data.com_id);
      this.viewingCreateForm.comPropertySearch$.next('');
      setTimeout(() => {
        this.viewingCreateForm.onSelectCommercialProperties(0);
      }, 4000);
    } else if (this.data?.const_id) {
      this.viewingCreateForm.viewing.get('viewing_for').setValue('Project');
      this.viewingCreateForm.viewing.get('list.' + 0 + '.project').setValue(this.data.const_id);
      setTimeout(() => {
        this.viewingCreateForm.onSelectProject(0);
      }, 4000);
    }
  }

  ngOnInit(): void {
    if (!_.isEmpty(this.data)) {
      if (this.data.shared) {
        this.sharedProperty = this.data.shared;
      }
      if (this.data.status) {
        this.status = this.data.status;
      }
      if (this.data.relatedTo === 'update') {
        this.loadingResults = true;
        setTimeout(() => {
          if (this.data._id || this.calendarViewingId) {
            let v_id = this.data._id;
            if(this.calendarViewingId && this.calendarViewingId != '') {
              v_id = this.calendarViewingId;
            }
            this._viewingsService.getById(v_id).subscribe((data: any) => {
              this.loadingResults = false
              this.viewingCreateForm.viewing.patchValue(data);
              this.viewingCreateForm.viewing.get('viewing_for').setValue('Commercial Properties');
              if (this.userAgencyData.commercial_properties &&  this.userAgencyData.residential_properties) {
                this.viewingCreateForm.viewing.get('viewing_for').setValue('Commercial Properties');
              } else if(this.userAgencyData.residential_properties){
                this.viewingCreateForm.viewing.get('viewing_for').setValue('Property');
              }
              if (data.color) {
                this.borderColor = data.color;
              }
              this.list = this.viewingCreateForm.viewing.get('list') as UntypedFormArray;
              if (this.viewingCreateForm.viewing.get('companies').value) {
                this.viewingCreateForm.companyContact();
                this.viewingCreateForm.loadCompanies();
              }
              forEach(data.list, (value) => {
                if(value?.property_reference){
                  this.viewingCreateForm?.propertiesIds.push(value.property_reference);
                  this.viewingCreateForm?.commPropIds.push(value.property_reference);
                }
              });
              this.viewingCreateForm.clearFormArray(this.list);
              if (data.list && data.list.length) {
                forEach(data.list, (extra, key) => {
                  this.list.push(this.viewingCreateForm.createList());
                  if(extra?.property_reference) {
                    extra.commercial_reference = extra?.property_reference;
                  }
                  this.viewingCreateForm.viewing.get('list.' + key).patchValue(extra);
                });
              } else {
                this.list.push(this.viewingCreateForm.createList());
              }
              this.viewingCreateForm.propertySearch$.next('');
              this.viewingCreateForm.comPropertySearch$.next('');
            });
          } else {
            this.viewingCreateForm.viewing.patchValue(this.data.data);
            this.list = this.viewingCreateForm.viewing.get('list') as UntypedFormArray;
            if (this.viewingCreateForm.viewing.get('companies').value) {
              this.viewingCreateForm.companyContact();
              this.viewingCreateForm.loadCompanies();
            }
            forEach(this.data.data.list, (value) => {
              this.viewingCreateForm.propertiesIds.push(value.property_reference);
            });
            this.viewingCreateForm.clearFormArray(this.list);
            if (this.data.data.list && this.data.data.list.length) {
              forEach(this.data.data.list, (extra, key) => {
                this.list.push(this.viewingCreateForm.createList());
                this.viewingCreateForm.viewing.get('list.' + key).patchValue(extra);
              });
            } else {
              this.list.push(this.viewingCreateForm.createList());
            }
            this.viewingCreateForm.propertySearch$.next('');
            this.viewingCreateForm.comPropertySearch$.next('');
          }
        }, 200);
      } else
        if (this.data.relatedTo === 'calendar') {
          setTimeout(() => {
            this.viewingCreateForm.viewing.get('start_date').setValue(this.data.date);
            this.viewingCreateForm.viewing.get('end_date').setValue(this.data.date);
            this.viewingCreateForm.viewing.get('date').setValue(this.data.date);
          }, 200);
        } else
          if (this.data.relatedTo === 'accounts') {
            setTimeout(() => {
              this.viewingCreateForm.viewing.get('account').setValue(this.data.accountId);
              this.viewingCreateForm.viewing.get('account_name').setValue(this.data.accountName);
              // setTimeout(() => { this.viewingCreateForm.loadAccount(this.data.accountId); }, 100);
            }, 800);
          } else if (this.data.relatedTo === 'commercial_properties') {
            setTimeout(() => {
              this.viewingCreateForm.viewing.get('list.' + 0 + '.commercial_property').setValue(this.data.commercialId);
            }, 10);
          }
          else if (this.data.id) {
            this._viewingsService.getById(this.data.id).subscribe((data: any) => {
              this.viewingCreateForm.viewing.patchValue(data);
              if (data.color) {
                this.borderColor = data.color;
              }
              this.list = this.viewingCreateForm.viewing.get('list') as UntypedFormArray;
              if (this.viewingCreateForm.viewing.get('companies').value) {
                this.viewingCreateForm.companyContact();
                this.viewingCreateForm.loadCompanies();
              }
              forEach(data.list, (value) => {
                this.viewingCreateForm.propertiesIds.push(value.property_reference);
              });
              this.viewingCreateForm.clearFormArray(this.list);
              if (data.list && data.list.length) {
                forEach(data.list, (extra, key) => {
                  this.list.push(this.viewingCreateForm.createList());
                  this.viewingCreateForm.viewing.get('list.' + key).patchValue(extra);
                });
              } else {
                this.list.push(this.viewingCreateForm.createList());
              }
              this.viewingCreateForm.propertySearch$.next('');
              this.viewingCreateForm.viewRecord = true;
            });
          }
    }
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  submitForm(): void {
    if (this.viewingCreateForm.viewing.valid) {
      this.loadingResults = true;
      this.isClick = true;
      // this.viewingCreateForm.viewing.get('start_time').setValue(this.viewingCreateForm.viewing.get('date').value);
      // this.viewingCreateForm.viewing.get('end_time').setValue(this.viewingCreateForm.viewing.get('date').value);
      this.viewingCreateForm.viewing.get('date').setValue(this.datePipe.transform(this.viewingCreateForm.viewing.get('date').value, 'yyyy-MM-dd'));
      this.viewingCreateForm.viewing.get('start_date').setValue(this.viewingCreateForm.viewing.get('date').value);
      this.viewingCreateForm.viewing.get('end_date').setValue(this.viewingCreateForm.viewing.get('date').value);
      this.viewingCreateForm.viewing.value.color = '#1b72e2';
      forEach(this.viewingCreateForm.accounts, (value) => {
        if (this.viewingCreateForm.viewing.get('account').value === value._id) {
          if (value.type === 'individual' || value.type === 'family' || value.type === 'pre_register') {
            this.viewingCreateForm.viewing.get('subject').setValue('Viewing ' + value.forename + ' ' + value.surname);
          }
          if (value.type === 'company') {
            this.viewingCreateForm.viewing.get('subject').setValue('Viewing ' + value.company_name);
          }
        }
      });
      if (!_.isEmpty(this.data)) {
        if (this.data.relatedTo === 'update') {
          if (this.borderColor) {
            this.viewingCreateForm.viewing.get('color').setValue(this.borderColor);
          }
          const formData = this.globalFunction.clearObject(this.viewingCreateForm.viewing.value) as any;
          let listVal: any;
          if (this.viewingCreateForm.viewing.get('list').value) {
            listVal = this.viewingCreateForm.viewing.get('list').value;
          }
          if (listVal) {
            formData.list = listVal;
          }
          this._viewingsService.update(this.viewingCreateForm.viewing.value._id, formData)
            .subscribe((data: any) => {
              this.matDialogRef.close('refresh');
            }, (err) => {
              this.loadingResults = false;
              this.isClick = false;
            });
        } else {
          if (this.borderColor) {
            this.viewingCreateForm.viewing.get('color').setValue(this.borderColor);
          }
          const formData = this.globalFunction.clearObject(this.viewingCreateForm.viewing.value) as any;
          let listVal: any;
          if (this.viewingCreateForm.viewing.get('list').value) {
            listVal = this.viewingCreateForm.viewing.get('list').value;
          }
          if (listVal) {
            formData.list = listVal;
          }

          this._viewingsService.create(formData)
            .subscribe((data: any) => {
              this.matDialogRef.close('refresh');
              this._snackBar.open(this._translateService.instant('Created successfully'), this._translateService.instant('Close'), {
                duration: 2000,
              });
            }, (err) => {
              this.loadingResults = false;
              this.isClick = false;
            });
        }
      } else {
        if (this.borderColor) {
          this.viewingCreateForm.viewing.get('color').setValue(this.borderColor);
        }
        const formData = this.globalFunction.clearObject(this.viewingCreateForm.viewing.value) as any;
        let listVal: any;
        if (this.viewingCreateForm.viewing.get('list').value) {
          listVal = this.viewingCreateForm.viewing.get('list').value;
        }
        if (listVal) {
          formData.list = listVal;
        }
        this._viewingsService.create(formData)
          .subscribe((data: any) => {
            this.matDialogRef.close('refresh');
          }, (err) => {
            this.loadingResults = false;
            this.isClick = false;
          });
      }
    } else {
      this.viewingCreateForm.viewing.markAllAsTouched();
    }
  }
  onOpenTimeline(event: any): void {
    this._timeLineData.setModelDataValue(event);
    this._timeLineData.setRelatedToValue('activities');
    this._fuseSidebarService.getSidebar('timeline').toggleOpen(1000000);
  }
}

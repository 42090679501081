import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ElementRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { MapGeocoder, MapGeocoderResponse } from '@angular/google-maps';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from './../../../services';
import { Subject } from 'rxjs';
declare var google: any;

@Component({
  selector: 'app-address-map',
  templateUrl: './address-map.component.html',
  styleUrls: ['./address-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})

export class AddressMapComponent implements OnInit, OnDestroy {

  longitude: number;
  latitude: number;
  center : google.maps.LatLngLiteral = {lat: 40.416394, lng: -3.703930};
  zoom = 8;
  address: any;
  compAddress: string;
  coordinates = { lat: null, lng: null };
  mapHeight: string;
  mapWidth: string;
  mqAlias: string;
  loadingResults = false;
  markerPosition: google.maps.LatLngLiteral = { lat: 40.416394, lng: -3.703930 };

  @ViewChild('search', { static: true })
  public searchElementRef: ElementRef;
  private _unsubscribeAll: Subject<void>;

  constructor(
    public matDialogRef: MatDialogRef<AddressMapComponent>,
    private mediaObserver: MediaObserver,
    private _authenticationService: AuthenticationService,
    private geocoder: MapGeocoder, // Inject MapGeocoder
    @Inject(MAT_DIALOG_DATA) public _data: any,
  ) {
    this._unsubscribeAll = new Subject();
    
  }
  
  ngOnInit(): void {
    const agency = JSON.parse(localStorage.getItem('currentAgency'));
    if (!agency) {
      const roMap = JSON.parse(localStorage.getItem('roMap'));
      if (roMap) {
        this.checkData("roMap", roMap);
      }
    } else {
      this.checkData("", agency);
    }
    console.log(this._data);
   
    if (this._data.address && !this._data.longitude && !this._data.latitude) {
      this.loadingResults = true;
      this.loadAddress(this._data);
    } else if (this._data.longitude && this._data.latitude) {
      this.markerPosition.lat = parseFloat(this._data.latitude.value) || this.center.lat;
      this.markerPosition.lng = parseFloat(this._data.longitude.value) || this.center.lng;
      this.center.lat = this.markerPosition.lat;
      this.center.lng = this.markerPosition.lng;
      this.getAddress(this.markerPosition.lat, this.markerPosition.lng);
    }  else if (!this._data.longitude && !this._data.latitude && !this._data.address) {
        this.markerPosition.lat = this.center.lat;
        this.markerPosition.lng = this.center.lng;
        this.getAddress(this.markerPosition.lat, this.markerPosition.lng);
      }
    
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  checkData(model: string, data: any): void {
    if (data.map) {
      this.center.lat = parseFloat(data.map.lat) || this.center.lat;
      this.center.lng = parseFloat(data.map.lng) || this.center.lng;
      this.zoom = +data.map.zoom || this.zoom;
    }

    if (model !== "roMap") {
      this._authenticationService.currentOffice
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((office: any) => {
          if (office.map) {
            this.center.lat = parseFloat(office.map.lat) || this.center.lat;
            this.center.lng = parseFloat(office.map.lng) || this.center.lng;
            this.zoom = +office.map.zoom || this.zoom;
          }
        });
    }

    this.mediaObserver.asObservable()
      .pipe(
        filter((changes: MediaChange[]) => changes.length > 0),
        map((changes: MediaChange[]) => changes[0])
      )
      .subscribe((change: MediaChange) => {
        this.mqAlias = change.mqAlias;
        let adjustScreen = 265;
        if (this.mqAlias === 'xl' || this.mqAlias === 'gt-lg') {
          adjustScreen = 500;
        }
        this.mapHeight = (window.innerHeight - adjustScreen) + 'px';
        this.mapWidth = window.innerWidth + 'px';
      });
  }

  mapClicked(event: google.maps.MapMouseEvent): void {
    if (event.latLng) {
      this.markerPosition = event.latLng.toJSON();
      this.getAddress(this.markerPosition.lat, this.markerPosition.lng);
    }
  }

  getAddress(latitude: number, longitude: number): void {
    this.geocoder.geocode({ location: { lat: latitude, lng: longitude } })
      .subscribe(({ results, status }: MapGeocoderResponse) => {
        if (status === 'OK' && results[0]) {
          this.address = results[0].address_components;
          this.compAddress = results[0].formatted_address;
          this.coordinates.lat = latitude;
          this.coordinates.lng = longitude;
        } else {
          console.error('Geocoder failed due to: ' + status);
        }
      });
  }

  loadAddress(_data: any): void {
    this.geocoder.geocode({ address: _data.address })
      .subscribe(({ results, status }: MapGeocoderResponse) => {
        let lat = _data?.latitude?.value;
        let lng = _data?.longitude?.value;
        if (status === 'OK' && results[0]) {
          this.zoom = 13;
          this.address = results[0].address_components;
          this.compAddress = results[0].formatted_address;

          if (!lat) {
            lat = results[0].geometry.location.lat();
          }
          if (!lng) {
            lng = results[0].geometry.location.lng();
          }

          this.coordinates.lat = lat;
          this.coordinates.lng = lng;
          this.center.lat = parseFloat(lat);
          this.center.lng = parseFloat(lng);
          this.markerPosition.lat = parseFloat(lat);
          this.markerPosition.lng = parseFloat(lng);
        } else {
          console.error('Geocoder failed due to: ' + status);
        }
        this.loadingResults = false;
      });
  }
}


import { Component, OnInit, ViewEncapsulation, Input, Injector, AfterViewChecked, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DropdownsService } from 'app/services';
import { TranslateService } from '@ngx-translate/core';
import { fuseAnimations } from '@fuse/animations';
import { merge, Subject } from 'rxjs';
import * as _ from 'lodash';
import { startWith, tap, switchMap, distinctUntilChanged, delay, takeUntil } from 'rxjs/operators';
import { AccountsCreateComponent } from '../../accounts-create.component';
import { FuseUtils } from '@fuse/utils';
import { map } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { CommercialPropertiesService } from 'app/services/commercial-properties.service';
@Component({
    selector: 'tab-profileNonresidential',
    templateUrl: './tab-profile-nonresidential.component.html',
    styleUrls: ['./tab-profile-nonresidential.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class TabProfileNonresidentialComponent implements OnInit, AfterViewChecked {

    @Input() form: UntypedFormGroup;
    tt = [
        {
            key: 'sale',
            value: this._translateService.instant('sale')
        },
        {
            key: 'lt_rent',
            value: this._translateService.instant('For LT rent')
        },
        {
            key: 'st_rent',
            value: this._translateService.instant('For ST rent')
        },
        {
            key: 'auction',
            value: this._translateService.instant('auction')
        }
    ];
    systemLang: any;
    cto: any;
    ctt: any;
    typeThree: boolean;
    searching: boolean;
    countries: any;
    search = '';
    countriesSearch: UntypedFormControl = new UntypedFormControl('');
    regions: any;
    regionsSearch: UntypedFormControl = new UntypedFormControl('');
    provinces: any;
    provincesSearch: UntypedFormControl = new UntypedFormControl('');
    cities: any;
    citiesSearch: UntypedFormControl = new UntypedFormControl('');
    locGroups: any;
    locGroupsSearch: UntypedFormControl = new UntypedFormControl('');
    locations: any;
    locationsSearch: UntypedFormControl = new UntypedFormControl('');
    operationStrategies: any;
    operationalStrategySearch: UntypedFormControl = new UntypedFormControl('');
    LicenseSearch: UntypedFormControl = new UntypedFormControl('');
    license_type: any;
    searchLandUses$ = new Subject<string>();
    landUses: any;
    urbanisations: any;
    pcategories: any;
    fieldUnderType: boolean;
    hideRent: boolean;
    hideSale: boolean;
    hideLtRent: boolean;
    hideStRent: boolean;
    hideAuction: boolean;
    hideFractional: boolean;
    hideRentBuy: boolean;
    hideEquitableSale: boolean;
    searchUrbanisation$ = new Subject<string>();
    private _unsubscribeAll: Subject<any>;
    searchLocationGroups$ = new Subject<string>();
    searchLocations$ = new Subject<string>();
    locationGroups: any;
    Groups = [];
    _accountsCreateComponent: AccountsCreateComponent;
    langReload = new EventEmitter<any>();
    furniture: any[];
    climate_control: any[];
    garden: any[];
    pool: any[];
    parking: any[];
    conditions: any[];
    views: any[];
    orientations: any[];
    characteristics: any[];
    categories: any[];
    hideSleep: boolean;
    buildingStyle: any[];
    purposeBuy: { key: string; value: any; }[];
    purposeRent: { key: string; value: any; }[];
    occupancyStatusOptions: { key: string; value: any; }[];
    licenseOccuptionOptions: { key: string; value: any; }[];
    features = ['categories', 'settings', 'orientations', 'views', 'conditions', 'parking', 'garden', 'pool', 'furniture', 'climate_control'];

    constructor(
        private _dropdownsService: DropdownsService,
        private _commercialPropertiesService: CommercialPropertiesService,
        private _translateService: TranslateService,
        private injector: Injector,
        private cdRef: ChangeDetectorRef,
        private _toastr: ToastrService
    ) {
        this.systemLang = this._translateService.currentLang;
        this._unsubscribeAll = new Subject();
    }


    ngOnInit(): void {
        if (!this.form) {
            this._accountsCreateComponent = this.injector.get(AccountsCreateComponent) as AccountsCreateComponent;
            this.form = this._accountsCreateComponent.form;
        }
        if (
            [136, 141, 142, 143, 144, 150, 153].some(ele => {
                return this.form.get('commercial_profile.type_one').value.includes(ele)
            })
        ) {
            this.tt.push(...[
                {
                    key: 'fractional',
                    value: this._translateService.instant('fractional')
                },
                {
                    key: 'rent_to_buy',
                    value: this._translateService.instant('Rent to buy')
                },
                {
                    key: 'equitable_title_sale',
                    value: this._translateService.instant('Equitable title sale')
                }
            ])
        }
        this.getTypeTwo();
        this._translateService.onLangChange
            .subscribe(() => {
                this.systemLang =
                    this._translateService.currentLang === 'es'
                        ? 'es_AR'
                        : this._translateService.currentLang;
                const locationGroups = map(this.locationGroups, (locationGroup) => {
                    locationGroup.label = locationGroup.value[this.systemLang];
                    return locationGroup;
                });
                this.locationGroups = locationGroups;
                this.langReload.next(true);
            });

        merge(this.form.get('commercial_profile.type_one').valueChanges, this.langReload)
            .subscribe(() => {
                this.getTypeTwo();
                this.loadFeatures();
            });

        this.langReload
            .pipe(startWith(''))
            .subscribe(() => {
                this._dropdownsService.getCommercialTypes()
                    .subscribe((data: any) => {
                        const cto = map(data, (ctoData) => {
                            ctoData.label = ctoData.value[this.systemLang];
                            return ctoData;
                        });
                        this.cto = cto;
                    });

                this._dropdownsService.getPropertyCategory()
                    .subscribe((data: any) => {
                        const pcategories = map(data.body, (item) => {
                            item.label = item.value[this.systemLang];
                            return item;
                        });
                        this.pcategories = pcategories;
                    });

                this.loadDropdowns();
            });

        if (this.form.get('commercial_profile.type_one').value?.length > 0) {
            this.typeOneChange();
            this.loadFeatures();
        }
        
        this.form.get('commercial_profile.type_one').valueChanges.subscribe(() => {
            this.typeOneChange();
        });

        if (this.form.get('commercial_profile.transaction_type').value?.length > 0) {
            this.transactionTypeChange();
        }

        this.form.get('commercial_profile.transaction_type').valueChanges
        .subscribe(() => {
                this.transactionTypeChange();
            });

        this.searchLocationGroups$
            .pipe(
                startWith(''),
                distinctUntilChanged(),
                switchMap((search: string) => {
                    this.search = search;
                    return this._dropdownsService.getLocationsGroups();
                }),
            )
            .subscribe((data: any) => {
                const locationGroups = map(data, (locationGroup) => {
                    locationGroup.label = locationGroup.value[this.systemLang];
                    if (!locationGroup.label) {
                        locationGroup.label = '';
                    }
                    return locationGroup;
                });
                this.locationGroups = locationGroups;
                if (this.search !== null) {
                    this.locationGroups = this.locationGroups.filter((element: any) => {
                        if (element?.label.toLowerCase().match(this.search.toLowerCase())) {
                            return true;
                        }
                    });
                }
                this.searchLocations$.next('');
                this.search = '';
            });

            this.searchLocations$
            .pipe(
                switchMap((search: string) => {
                    this.search = search;
                    if (!_.isEmpty(this.form.get('commercial_profile.locations_group').value)) {
                        const keys = [];
                        for (const item of this.locationGroups) {
                            if (item.hasOwnProperty('group') && item.group) {
                                for (const group of item.group) {
                                    keys.push(group);
                                }
                            }
                        }
                        return this._dropdownsService.getLocationsFromGroup(
                            keys,
                            2000
                        );
                    } else {
                        return this._dropdownsService.getLocations(
                            search,
                            false,
                            false,
                            false,
                            this.form.get('commercial_profile.cities').value
                        );
                    }
                })
            )
            .subscribe((data: any) => {
                const keys = [];
                const locations = map(data.docs, (location) => {
                    const city = location?.city_data
                        ? ', ' + location?.city_data?.value[this.systemLang]
                        : '';
                    location.label = location.value[this.systemLang] + city;
                    keys.push(location?.key);
                    return location;
                });
                this.locations = locations;
                if (this.search !== null) {
                    this.locations = this.locations?.filter((element: any) => {
                        let city = '';
                        if (element?.values?.en) {
                            city = element?.values?.en;
                        } else if (element?.city_data?.value?.en) {
                            city = element?.city_data?.value?.en;
                        }
                        if (city && city.toLowerCase().match(this.search.toLowerCase())) {
                            return true;
                        }
                    });
                }
                this.search = '';
            });

        this.searchUrbanisation$
            .pipe(
                startWith(''),
                distinctUntilChanged(),
                switchMap((search: string) => {
                    return this._dropdownsService.getUrbanisation(search);
                }),
            )
            .subscribe((data: any) => {
                this.urbanisations = data.docs;
            });

        this.searchLandUses$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((searchValue: string) => {
                    let search = '';
                    if (searchValue) {
                        search = searchValue;
                    }
                    return this._dropdownsService.getLandUses(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                const landUses = map(data, (element) => {
                  if (element?.value) {
                      element.label = element.value[this.systemLang];
                  }
                  return element;
              });
              this.landUses = landUses;
            });

        this.operationalStrategySearch.valueChanges
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((searchValue: string) => {
                    let search = '';
                    if (searchValue) {
                        search = searchValue;
                    }
                    return this._dropdownsService.getCommercialOperationalStrategies(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                const operationStrategies = map(data, (element) => {
                  if (element?.value) {
                    element.label = element.value[this.systemLang] ? element.value[this.systemLang] : element.value['en'];
                  }
                  return element;
              });
              this.operationStrategies = operationStrategies;
            });
        
        this.LicenseSearch.valueChanges
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    return this._dropdownsService.getLicenseTypes(this.LicenseSearch.value);
                })
            )
            .subscribe((data: any) => {
                this.license_type = data.docs;
                this.searching = false;
            });

        this._dropdownsService.getAccountsBuildings().subscribe((data: any) => {
            const buildingStyles = map(data.body, (styles) => {
                styles.label = styles.value[this.systemLang];
                return styles;
            });
            this.buildingStyle = buildingStyles;
        });

        merge(this.countriesSearch.valueChanges, this.form.get('commercial_profile.countries').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('commercial_profile.countries').value;
                    if (this.countriesSearch.value) {
                        search = this.countriesSearch.value;
                    }
                    return this._dropdownsService.getCountries(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.countries = data.docs;
            });

        merge(this.regionsSearch.valueChanges, this.form.get('commercial_profile.countries').valueChanges, this.form.get('commercial_profile.regions').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('commercial_profile.regions').value;
                    if (this.regionsSearch.value) {
                        search = this.regionsSearch.value;
                    }
                    return this._dropdownsService.getRegions(search, this.form.get('commercial_profile.countries').value);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.regions = data.docs;
            });

        merge(this.provincesSearch.valueChanges, this.form.get('commercial_profile.regions').valueChanges,this.form.get('commercial_profile.provinces').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('commercial_profile.provinces').value;
                    if (this.provincesSearch.value) {
                        search = this.provincesSearch.value;
                    }
                    return this._dropdownsService.getProvinces(search, false, this.form.get('commercial_profile.regions').value);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.provinces = data.docs;
            });

        merge(this.citiesSearch.valueChanges, this.form.get('commercial_profile.provinces').valueChanges,this.form.get('commercial_profile.cities').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('commercial_profile.cities').value;
                    if (this.citiesSearch.value) {
                        search = this.citiesSearch.value;
                    }
                    return this._dropdownsService.getCities(search, false, false, this.form.get('commercial_profile.provinces').value);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.cities = data.docs;
            });
    }

    typeOneChange(): void {
        if (
            this.form.get('commercial_profile.type_one').value.some((e) => [136,141,142,143,144,150].includes(e))
        ) {
                        this.tt = [
                {
                    key: 'sale',
                    value: this._translateService.instant('sale')
                },
                {
                    key: 'lt_rent',
                    value: this._translateService.instant('For LT rent')
                },
                {
                    key: 'st_rent',
                    value: this._translateService.instant('For ST rent')
                },
                {
                    key: 'auction',
                    value: this._translateService.instant('auction')
                },
                {
                    key: 'fractional',
                    value: this._translateService.instant('fractional')
                },
                {
                    key: 'rent_to_buy',
                    value: this._translateService.instant('Rent to buy')
                },
                {
                    key: 'equitable_title_sale',
                    value: this._translateService.instant('Equitable title sale')
                }
            ];
        } else if (
            !this.form.get('commercial_profile.type_one').value.some((e) => [136,143,150,142,144,141,180,181,275,153].includes(e))
        ) {
            this.tt = [
                {
                    key: 'sale',
                    value: this._translateService.instant('sale')
                },
                {
                    key: 'auction',
                    value: this._translateService.instant('auction')
                }
            ];
        }
        if (this.form.get('commercial_profile.type_one').value.some((e) => [164].includes(e))) {
            this.tt.push({key: 'lt_rent',value: this._translateService.instant('For LT rent')});
        }
    }

    transactionTypeChange(): void {
        this.typeThree = _.includes(this.form.get('commercial_profile.type_one').value, 79);

        if (
            _.includes(this.form.get('commercial_profile.transaction_type').value, 'sale')
        ) {
            this.hideSale = true; 
        } else {
            this.hideSale = false;
        }

        if (
            _.includes(this.form.get('commercial_profile.transaction_type').value, 'lt_rent')
        ) {
            this.hideLtRent = true;
        } else {
            this.hideLtRent = false
        }

        if (
            _.includes(this.form.get('commercial_profile.transaction_type').value, 'st_rent')
        ) {
            this.hideStRent = true;
        } else {
            this.hideStRent = false
        }

        if (
            _.includes(this.form.get('commercial_profile.transaction_type').value, 'fractional')
        ) {
            this.hideFractional = true;
        } else {
            this.hideFractional = false;
        }

        if (
            _.includes(this.form.get('commercial_profile.transaction_type').value, 'rent_to_buy')
        ) {
            this.hideRentBuy = true;
        } else {
            this.hideRentBuy = false;
        }

        if (
            _.includes(this.form.get('commercial_profile.transaction_type').value, 'equitable_title_sale')
        ) {
            this.hideEquitableSale = true;
        } else {
            this.hideEquitableSale = false;
        }

        if (
            _.includes(this.form.get('commercial_profile.transaction_type').value, 'auction')
        ) {
            this.hideAuction = true; 
        } else {
            this.hideAuction = false;
        }
    }

    loadDropdowns(): any {
        const buildingStyles = map(this.buildingStyle, (styles) => {
            styles.label = styles.value[this.systemLang];
            return styles;
        });
        this.purposeBuy = [
            {
                key: 'Primary residence',
                value: this._translateService.instant('Primary residence'),
            },
            {
                key: 'Secondary residence',
                value: this._translateService.instant('Secondary residence'),
            },
            {
                key: 'Invesrment',
                value: this._translateService.instant('Invesrment'),
            },
            {
                key: 'investment_sales',
                value: this._translateService.instant('investment_sales'),
            },
            {
                key: 'Construction',
                value: this._translateService.instant('Construction'),
            },
        ];
        this.purposeBuy = _.orderBy(this.purposeBuy, ['value'], ['asc']);
        this.purposeRent = [
            {
                key: 'Primary residence',
                value: this._translateService.instant('Primary residence'),
            },
            {
                key: 'Secondary residence',
                value: this._translateService.instant('Secondary residence'),
            },
        ];
        this.purposeRent = _.orderBy(this.purposeRent, ['value'], ['asc']);
        this.buildingStyle = buildingStyles;
        this.occupancyStatusOptions = [
            {
                key: 'Occupied',
                value: this._translateService.instant('Occupied'),
            },
            {
                key: 'Not occupied',
                value: this._translateService.instant('Not occupied'),
            },
            {
                key: 'Periodically occupied',
                value: this._translateService.instant('Periodically occupied'),
            },
        ];
        this.occupancyStatusOptions = _.orderBy(this.occupancyStatusOptions,['value'],['asc']);
        this.licenseOccuptionOptions = [
            {
                key: 'Under Investigation',
                value: this._translateService.instant('Under Investigation'),
            },
            {
                key: 'Owner applying',
                value: this._translateService.instant('Owner applying'),
            },
            {
                key: 'Application pending',
                value: this._translateService.instant('Application pending'),
            },
            {
                key: 'Not possible to obtain AFO',
                value: this._translateService.instant(
                    'Not possible to obtain AFO'
                ),
            },
            {
                key: 'Assimilated',
                value: this._translateService.instant('Assimilated'),
            },
            {
                key: 'First Occupation License',
                value: this._translateService.instant(
                    'First Occupation License'
                ),
            },
        ];
        this.licenseOccuptionOptions = _.orderBy(this.licenseOccuptionOptions, ['value'],['asc']);
    }

    checkTypeOne(value) {
        let show = false;
        if (
            value === 'beds' &&
            this.form.get('commercial_profile.type_one').value.some((e) => [136,143,150,142,144,163,141,180,181,153].includes(e))
        ) {
            show = true;
        } else if (
            value === 'status' &&
            !_.includes(this.form.get('commercial_profile.type_one').value, 92)
        ) {
            show = true;
        } else if (
            ['year_built', 'occupancy_status', 'legal_status'].includes(value) &&
            !_.includes(this.form.get('commercial_profile.type_one').value, 147)
        ) {
            show = true;
        } else if (
            value === 'operational_strategy' &&
            this.form.get('commercial_profile.type_one').value.some((e) => [31, 87, 44, 92, 79, 47, 108, 275, 153].includes(e))
        ) {
            show = true;
        } else if (
            value === 'percentage_property' &&
            this.form.get('commercial_profile.type_one').value.some((e) => [87, 47].includes(e))
        ) {
            show = true;
        } else if (
            value === 'license' &&
            this.form.get('commercial_profile.type_one').value.some((e) => [1, 83, 18, 39, 47, 22, 72, 5, 107, 164].includes(e))
        ) {
            show = true;
        } else if (
            value === 'feet_condition' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [147, 92, 108].includes(e))
        ) {
            show = true;
        } else if (
            value === 'min_power' &&
            _.includes(this.form.get('commercial_profile.type_one').value, 87)
        ) {
            show = true;
        } else if (
            value === 'only_projects' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [1,163,72,141].includes(e))
        ) {
            show = true;
        } else if (
            value === 'only_urgent_sales' &&
            !_.includes(this.form.get('commercial_profile.type_one').value, 163)
        ) {
            show = true;
        } else if (
            value === 'toilets' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [1,31,44,79,83,87,18,39,22,72,5,147,107,92,47,275].includes(e))
        ) {
            show = true;
        } else if (
            value === 'office_rooms' &&
            this.form.get('commercial_profile.type_one').value.some((e) => [31,87].includes(e))
        ) {
            show = true;
        } else if (
            value === 'total_floors' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [163,1,147,164].includes(e))
        ) {
            show = true;
        } else if (
            value === 'built_size' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [147,92,275].includes(e))
        ) {
            show = true;
        } else if (
            value === 'bed_rooms' &&
            this.form.get('commercial_profile.type_one').value.some((e) => [31,136,143,150,142,144,141,180,181,153].includes(e))
        ) {
            show = true;
        } else if (
            value === 'baths' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [31,44,79,87,163,1,83,18,39,22,72,5,147,107,164,92,47,275,108,153].includes(e))
        ) {
            show = true;
        } else if (
            value === 'plot_size' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [136,163,18,39,72,5,107,164].includes(e))
        ) {
            show = true;
        } else if (
            ['number_of_properties','commercial_premises','accomodation'].includes(value) &&
            this.form.get('commercial_profile.type_one').value.some((e) => [87,153].includes(e))
        ) {
            show = true;
        } else if (
            value === 'office_quantities' &&
            _.includes(this.form.get('commercial_profile.type_one').value, 44)
        ) {
            show = true;
        } else if (
            value === 'building_style' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [163,1,83,18,39,22,72,147,107,164,92,79,108,153].includes(e))
        ) {
            show = true;
        } else if (
            value === 'elevator' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [1,147,92].includes(e))
        ) {
            show = true;
        } else if (
            value === 'furniture' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [1,83,18,39,22,72,147,164,92,79,108,153].includes(e))
        ) {
            show = true;
        } else if (
            value === 'feet_garden' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [1,83,18,39,72,147,164,87,44,92,79,47,108,275,153].includes(e))
        ) {
            show = true;
        } else if (
            value === 'pool' &&
            this.form.get('commercial_profile.type_one').value.some((e) => [136,87,141,142,163,144,143,92,31,147,181,180,150].includes(e))
        ) {
            show = true;
        } else if (
            value === 'parking' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [1,108,147,141].includes(e))
        ) {
            show = true;
        } else if (
            value === 'parking_spaces' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [1,83,18,39,22,72,147,141,92,275,153].includes(e))
        ) {
            show = true;
        } else if (
            value === 'gated' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [1,83,18,39,107,141,79,47,108,275].includes(e))
        ) {
            show = true;
        } else if (
            value === 'feet_setting' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [108,153].includes(e))
        ) {
            show = true;
        } else if (
            value === 'feet_views' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [1,83,18,39,22,72,107,79,108,153].includes(e))
        ) {
            show = true;
        } else if (
            value === 'feet_orientation' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [44,79,108,153].includes(e))
        ) {
            show = true;
        } else if (
            value === 'land_uses' &&
            this.form.get('commercial_profile.type_one').value.some((e) => [147,87,44,92,79,108,275].includes(e))
        ) {
            show = true;
        } else if (
            value === 'smoke_exit' &&
            this.form.get('commercial_profile.type_one').value.some((e) => [5,47].includes(e))
        ) {
           show = true;
        } else if (
            value === 'terrace_present' &&
            this.form.get('commercial_profile.type_one').value.some((e) => [136,143,150,142,144,5,180,181,47].includes(e))
        ) {
            show = true;
        } else if (
            ['size_aboveground','size_underground'].includes(value) &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [136,163,1,72,147,107,141,180,181,92].includes(e))
        ) {
            show = true;
        } else if (
            ['floors_aboveground','floors_underground'].includes(value) &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [136,163,1,147,92,275,153].includes(e))
        ) {
            show = true;
        } else if (
            value === 'hold' &&
            !_.includes(this.form.get('commercial_profile.type_one').value, 108)
        ) {
            show = true;
        } else if (
            value === 'parking_spot' &&
            this.form.get('commercial_profile.type_one').value.some((e) => [147,92,275].includes(e))
        ) {
            show = true;
        } else if (
            value === 'profitability' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [163,1,83,18,39,22,72,147,107,92,275].includes(e))
        ) {
            show = true;
        } else if (
            value === 'for_debt' &&
            _.includes(this.form.get('commercial_profile.type_one').value, 275)
        ) {
            show = true;
        } else if (
            value === 'actual_yearly_rent' &&
            !_.includes(this.form.get('commercial_profile.type_one').value, 275)
        ) {
            show = true;
        } else if (
            value === 'room_price' &&
            _.includes(this.form.get('commercial_profile.type_one').value, 31)
        ) {
            show = true;
        } else if (
            value === 'capex_price' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [163,147,275].includes(e))
        ) {
            show = true;
        } else if (
            value === 'plot_m2' &&
            this.form.get('commercial_profile.type_one').value.some((e) => [143,150,142,144,1,92].includes(e))
        ) {
            show = true;
        } else if (
            value === 'built_m2' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [1,147,92].includes(e))
        ) {
            show = true;
        } else if (
            value === 'alert_lt_rental' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [275,153].includes(e))
        ) {
            show = true;
        } else if (
            value === 'rent_info' &&
            ((this.hideLtRent && !this.form.get('commercial_profile.type_one').value.some((e) => [147,275,153].includes(e))) ||
            (this.hideStRent && this.form.get('commercial_profile.type_one').value.some((e) => [136,143,150,142,144,163].includes(e))))
        ) {
            show = true;
        } else if (
            value === 'rent_price_to' &&
            ((this.hideLtRent && !this.form.get('commercial_profile.type_one').value.some((e) => [147,275,153].includes(e))) ||
            (this.hideStRent && this.form.get('commercial_profile.type_one').value.some((e) => [136,143,150,142,144,163,31,87,44,92,79,47,108].includes(e))))
        ) {
            show = true;
        } else if (
            value === 'usefull_area' &&
            !this.form.get('commercial_profile.type_one').value.some((e) => [275,147].includes(e))
        ) {
            show = true;
        }
        return show;
    }

    selectLocationGroup(e): void {
        this.Groups = [];
        for (const item of e) { this.Groups.push(...item.group); }
        if (this.Groups.length === 0) { return; }

        this._dropdownsService.getLocationsFromGroup(this.Groups).pipe(
            switchMap((data: any) => {
                const selected = data.docs.map((item) => item.key);
                return this._dropdownsService.getLocations(selected, false, false, false, false, 50);
            })
        ).subscribe((result: any) => {
            if (result) {
                const items = new Set(this.form.get('commercial_profile.locations').value || []);
                const locations = result.docs.map((location) => {
                    const city = location?.city_data?.value[this.systemLang] || '';
                    const label = location.value[this.systemLang] + city;
                    items.add(location.key);
                    return { ...location, label };
                });
                this.locations = locations.filter((element: any) => {
                    const city = element.values?.en || element.city_data?.value?.en || '';
                    return city.toLowerCase().includes(this.search.toLowerCase());
                });
                this.form.get('commercial_profile.locations').patchValue(Array.from(items));
            }
        });
    }

    fieldsSelected(): boolean {
        const to = this.form.get('commercial_profile.type_one').value;
        const tt = this.form.get('commercial_profile.transaction_type').value;
        return !_.isEmpty(to) && !_.isEmpty(tt);
    }
    showPurpose(): boolean {
        if(this.form.get('commercial_profile.transaction_type')) {
            return true;
        }else {
            return false;
        }
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    getTypeTwo() {
        this._dropdownsService.getCommercialTypesTwo(this.form.get('commercial_profile.type_one').value)
            .pipe(startWith(''))
            .subscribe((data: any) => {
                const ctt = map(data, (cttData) => {
                    cttData.label = cttData.value[this.systemLang];
                    return cttData;
                });
                this.ctt = ctt;
            });
    }

    loadFeatures() {
        const filterFeatures:any = {};
        filterFeatures.tab_in_crm = { $in: this.features };
        if (Array.isArray(this.form.get('commercial_profile.type_one').value)) {
            filterFeatures.show_in_type_one = { $in: this.form.get('commercial_profile.type_one').value };
        }
        this._commercialPropertiesService.getFeaturesAll(filterFeatures)
            .subscribe((data: any) => {
                if (data) {
                    const featuresData: any = _.groupBy(data, 'tab_in_crm');
                    let lang = this.systemLang.toUpperCase() === 'ES_AR' ? 'ES' : this.systemLang.toUpperCase();
                    for (const feature of this.features) {
                        if (feature in featuresData) {
                            let featureArr = [];
                            for (const featureData of featuresData[feature]) {
                                featureArr.push({
                                    key: featureData.key,
                                    value: featureData.translations[lang] ? featureData.translations[lang] : featureData.translations['EN']
                                });
                            }
                            if (feature === 'settings') {
                                this.characteristics = _.orderBy(featureArr, ['value'], ['asc']);
                            } else {
                                this[feature] = _.orderBy(featureArr, ['value'], ['asc']);
                            }
                        }
                    }
                }
            });
    }

    citySelect(): void {
        this.searchLocations$.next('');
    }

    emailAlerts(event: any): void {
        if (event.checked) {
            this._toastr.warning(
                this._translateService.instant(
                    'alerts will work if property type, purchase budget is defined and gdpr is accepted'
                ),
                this._translateService.instant('warning')
            );
        }
    }

}

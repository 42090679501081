import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { OwnersService } from 'app/services/owners.service';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'app/services';
import { GlobalFuntions } from 'app/_helpers';

@Component({
    selector: 'owner-quick-edit',
    templateUrl: './quick-edit.component.html',
    styleUrls: ['./quick-edit.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class QuickEditComponent implements OnInit {
    dialogTitle: string;
    form: UntypedFormGroup;
    customSettings: UntypedFormArray;
    financialAccounts: UntypedFormArray;
    partners: UntypedFormArray;
    officeData: any;
    currentUser: any;
    private _unsubscribeAll: Subject<any>;

    constructor(
        public matDialogRef: MatDialogRef<QuickEditComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _formBuilder: UntypedFormBuilder,
        private domSanitizer: DomSanitizer,
        private _ownersService: OwnersService,
        public _authenticationService: AuthenticationService,
        private _globalFunctions: GlobalFuntions
    ) {
        this.currentUser = this._authenticationService.currentUserValue;
        this.officeData = this._authenticationService.currentOfficeValue;
        this.dialogTitle = 'Edit ' + _data.tab;
        this._unsubscribeAll = new Subject();

        let officeRecord = false;
        if (this._globalFunctions.getUserRole(this.currentUser.user_role)) {
            officeRecord = true;
        }
        if (!this?.currentUser?.user_master_office) {
            officeRecord = true;
        }

        this.form = this._formBuilder.group({
            _id: [],
            owner_id: [],
            type: [],
            classification: [],
            forename: [],
            surname: [],
            date_of_birth: [],
            birthday_greetings: [],
            age: [],
            civil_status: [],
            document_type: [],
            document_number: [],
            document_type_two: [],
            document_number_two: [],
            search: [],
            home_phone: [],
            work_phone: [],
            mobile: [{ value: '' }, Validators.required],
            email: [{ value: '' }, Validators.required],
            secondary_mobile: [],
            secondary_home_phone: [],
            secondary_work_phone: [],
            skype_id: [],
            title: [],
            gender: [],
            street: [],
            streetnumber: [],
            comments: [],
            source: [],
            gdpr_comment: [],
            options: [],
            complete_address: [],
            complete_address_country: [],
            complete_address_region: [],
            complete_address_city: [],
            postcode: [],
            complete_address_province: [],
            status: [],
            regionSearch: [],
            listing: this._formBuilder.array([]),
            custom_settings: this._formBuilder.array([]),
            financial_accounts: this._formBuilder.array([]),
            account_holder: [],
            bic_swift: [],
            iban: [],
            avg_balance: [],
            key: [],
            value: [],
            company_name: [],
            legal_form: [],
            tax_id: [],
            nationality: [],
            language: [],
            language_value: [],
            address: [{ value: '' }, Validators.required],
            region: [],
            country: [],
            province: [],
            city: [],
            properties: [],
            communication_language: [],
            offices: [{ value: [this.officeData._id], disabled: officeRecord }],
            probability: [],
            gdpr_status: [],
            company: [],
            company_contact: [],
            partners: this._formBuilder.array([]),
            automated_email: [],
            lead_status: [],
            assign_agent: [],
            accounting_code: [],
            payment_method: [],
            children: [],
            invoice_series: [],
            newsletter: [],
            currency: [],
            linked_users: []
        });
        this.customSettings = this.form.get('custom_settings') as UntypedFormArray;
        this.financialAccounts = this.form.get(
            'financial_accounts'
        ) as UntypedFormArray;
        this.partners = this.form.get('partners') as UntypedFormArray;
    }

    ngOnInit(): void {
        const owner = this._data.owner;
        if (_.isEmpty(owner.listing)) {
            owner.listing = [];
        }
        if (_.isEmpty(owner.custom_settings)) {
            owner.custom_settings = [];
        }
        if (_.isEmpty(owner.financial_accounts)) {
            owner.financial_accounts = [];
        }
        if (_.isEmpty(owner.partners)) {
            owner.partners = [];
        }
        if (_.isArray(owner.mobile)) {
            owner.mobile = owner.mobile.toString();
        }

        this.form.patchValue(owner);
        if (!_.isEmpty(owner.custom_settings) && owner.custom_settings.length) {
            this.clearFormArray(this.customSettings);
            _.forEach(owner.custom_settings, (setting, key) => {
                this.customSettings.push(this.createCustomSettings());
                this.form.get('custom_settings.' + key).patchValue(setting);
            });
        }
        if (
            !_.isEmpty(owner.financial_accounts) &&
            owner.financial_accounts.length
        ) {
            this.clearFormArray(this.financialAccounts);
            _.forEach(owner.financial_accounts, (setting, key) => {
                this.financialAccounts.push(this.createFinancialAcoounts());
                this.form.get('financial_accounts.' + key).patchValue(setting);
            });
        }
        if (!_.isEmpty(owner.partners) && owner.partners.length) {
            this.clearFormArray(this.partners);
            _.forEach(owner.partners, (setting, key) => {
                this.partners.push(this.createPartners());
                this.form.get('partners.' + key).patchValue(setting);
            });
        }
    }

    clearFormArray = (formArray: UntypedFormArray) => {
        while (formArray.length !== 0) {
            formArray.removeAt(0);
        }
    };

    createFinancialAcoounts(): UntypedFormGroup {
        return this._formBuilder.group({
            account_holder: [{ value: '', disabled: false }],
            iban: [{ value: null, disabled: false }],
            bic_swift: [{ value: '', disabled: false }],
            avg_balance: [{ value: '', disabled: false }],
            account_currency: [{ value: '', disabled: false }],
        });
    }

    createCustomSettings(): UntypedFormGroup {
        return this._formBuilder.group({
            key: [{ value: '', disabled: false }],
            value: [{ value: '', disabled: false }],
        });
    }

    createPartners(): UntypedFormGroup {
        return this._formBuilder.group({
            title: { value: '', disabled: false },
            forename: { value: '', disabled: false },
            surname: { value: '', disabled: false },
            date_of_birth: { value: '', disabled: false },
            age: { value: '', disabled: true },
            civil_status: { value: '', disabled: false },
            nationality: { value: '', disabled: false },
            document_type: { value: '', disabled: false },
            document_number: { value: '', disabled: false },
            document_type_two: { value: '', disabled: false },
            document_number_two: { value: '', disabled: false },
            gender: { value: '', disabled: false },
            phone: { value: '', disabled: false },
            email: { value: '', disabled: false },
            children: { value: '', disabled: false },
        });
    }
    transform(html: string): SafeHtml {
        return this.domSanitizer.bypassSecurityTrustHtml(html);
    }
    submitContact(): any {
        this._ownersService
            .update(this.form.value)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.matDialogRef.close(true);
            });
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatSvgIconComponent } from './mat-svg-icon.component';

@NgModule({
  declarations: [MatSvgIconComponent],
  imports: [
    CommonModule,
    MatIconModule,
  ],
  exports: [MatSvgIconComponent]
})
export class MatSvgIconModule { }

import { Component, Inject, OnDestroy, OnInit, SecurityContext, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { GLOBALS } from 'app/config/globals';
import { LanguagesService, AuthenticationService } from 'app/services';
import { PdfTemplatesService } from 'app/services/pdf-templates.service';
import { BaseService } from 'app/_helpers/base/base.service';
import { environment } from 'environments/environment';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GetCommercialReferencePipe } from 'app/pipes/get-commercial-reference.pipe';
import { toUpper } from 'lodash';

@Component({
    selector: 'pdf-view-dialog',
    templateUrl: './pdf-view-dialog.component.html',
    styleUrls: ['./pdf-view-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [GetCommercialReferencePipe]
})
export class PdfViewDialogComponent implements OnInit, OnDestroy, AfterViewInit {
    type = 'portrait';
    text = 'Portrait';
    my = 'primary';
    contentType = 'content';
    private _unsubscribeAll: Subject<any>;
    systemLanguages: any;
    templataData: any;
    currentLang: string;
    activeTab: any;
    public tinyMce = GLOBALS.tinyMce; // To use centralized tinyMce
    langTab: number;
    currentAgency: any;
    editorLoader: Boolean;
    propRef: any;
    constructor(
        public matDialogRef: MatDialogRef<PdfViewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _languagesService: LanguagesService,
        private _pdfTemplatesService: PdfTemplatesService,
        private _translateService: TranslateService,
        private _matSnackBar: MatSnackBar,
        private _gService: BaseService,
        public sanitizer: DomSanitizer,
        private _authenticationService: AuthenticationService,
        private _getCommercialReference: GetCommercialReferencePipe,
    ) {
        this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
        this._unsubscribeAll = new Subject();
        this.activeTab = this.currentLang;
        this.currentAgency = this._authenticationService.currentAgencyValue;
    }

    ngOnInit(): void {
        combineLatest([this._languagesService.getLanguagesForSystem(), this._pdfTemplatesService.getDecodedData(this._data)])
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data: any) => {
                this.systemLanguages = data[0];
                this.templataData = data[1];
                this.editorLoader = true;
                if(this.templataData?.type == 'constructions'){
                    this.contentType = 'content_st_rental';
                }
                this.loadEditorData();
            });
        if(this?._data?.property_id) {
            let filter = {"id" : this?._data?.property_id , "model" : "CommercialProperties", "fields_name": {"reference" : 1 , "external_reference" : 1, "other_reference" : 1}}
            this._gService.post(`commercial_properties/get-field-value`, filter , 'nodejs').subscribe((data) => {
                if(data) {
                    this.propRef = this._getCommercialReference.transform(data)
                }
            });
        }    
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    selectContent(type: string): void {
        if (this.templataData.hasOwnProperty(type)) {
            this.contentType = type;
        }
        else {
            this._matSnackBar.open(this._translateService.instant('Content doesn\'t exist'), this._translateService.instant('Close'), {
                duration: 2000,
            });
        }
    }
    changeText(): void {
        if (this.text === 'Portrait') {
            this.type = 'landscape';
            this.text = 'Landscape';
            this.my = 'primary';
        } else if (this.text === 'Landscape') {
            this.type = 'portrait';
            this.text = 'Portrait';
            this.my = 'primary';
        }
    }
    domPdfDownload(data: any): void {
        let content = '' as any;
        let activeTab = this.transformLang(this.activeTab);
        let currentLang = this.transformLang(this.currentLang);
        if (data[this.contentType][activeTab]) {
            content = this.sanitizer.bypassSecurityTrustHtml(data[this.contentType][activeTab]);
        } else {
            content = (data[this.contentType] && data[this.contentType][currentLang] ? this.sanitizer.bypassSecurityTrustHtml(data[this.contentType][currentLang]) : '' );
        }
        let template_id = data?.preview_property ? data.preview_property : '';
        if(data.copy_from && data.copy_key) {
            template_id = data.copy_key;
        }
        if(template_id == "") {
            template_id = data?.key;
        }
        this._gService.post(`pdf/html-to-pdf?template_id=${template_id}&type=${this.type}`, this.sanitizer.sanitize(SecurityContext.HTML, content), 'Yii')
            .subscribe(() => {
                if (this?.propRef || this?.propRef === '') {
                    window.open(`${environment.yiiUrl}pdf/download-html-pdf?name=${this.propRef}`, '_blank');
                }else {
                    if(this._data?.viewing_tours_id) {
                        window.open(`${environment.yiiUrl}pdf/download-html-pdf?name=${this._data.viewing_tours_id}`, '_blank');
                    }else {
                        window.open(`${environment.yiiUrl}pdf/download-html-pdf?name=${this._data.property_id}`, '_blank');
                    }
                }
            });
    }
    onSelectedTabChange(event: MatTabChangeEvent): void {
        this.activeTab = event.tab.ariaLabel;
        this.langTab = event.index;
    }
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.langTab = 0;
        }, 2000);
    }
    transformLang(lang: string) {
        return toUpper(lang) === 'ES_AR' ? 'ES' : toUpper(lang);
    }
    loadEditorData() {
        setTimeout(() => {
            this.editorLoader = false;
        }, 3000);
    }

}

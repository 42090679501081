import { Component, OnInit, ViewEncapsulation, Input, OnDestroy, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { Subject } from 'rxjs';
import {take} from 'rxjs/operators';
import { TimeLineService } from 'app/services/time-line.service';
import { takeUntil, distinctUntilChanged, debounceTime, startWith, switchMap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { NgForm, UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AuthenticationService, DropdownsService } from 'app/services';
import { FuseUtils } from '@fuse/utils';
import { forEach, map } from 'lodash';
import { fuseAnimations } from '@fuse/animations';
import { GlobalFuntions } from 'app/_helpers';
import { MeetingCreateModalComponent } from 'app/main/activities/meetings/meeting-create-modal/meeting-create-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TasksCreateModalComponent } from 'app/main/activities/tasks/tasks-create-modal/tasks-create-modal.component';
import { ViewingToursModalComponent } from 'app/main/activities/viewingTours/viewing-tours-modal/viewing-tours-modal.component';
import { SalesCreateModalComponent } from 'app/main/activities/sales/sales-create-modal/sales-create-modal.component';
import { OffersModalComponent } from 'app/main/activities/offers/offers-modal/offers-modal.component';
import { TimeLineDataService } from './time-line-data.service';
import { TranslateService } from '@ngx-translate/core';
import { GetCommercialReferencePipe } from 'app/pipes/get-commercial-reference.pipe';
import { GetPropertyReferencePipe } from 'app/pipes/get-property-reference.pipe';
import { BaseService } from 'app/_helpers/base/base.service';
import { CrmMenuService } from 'app/services/crm-menu.service';
import { Router } from '@angular/router';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';


@Component({
    selector: 'time-line',
    templateUrl: './time-line.component.html',
    styleUrls: ['./time-line.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [GetCommercialReferencePipe, GetPropertyReferencePipe]
})
export class TimeLineComponent implements OnInit, OnDestroy {

    quickSearch = false as boolean;
    comments: UntypedFormGroup;
    properties: any;
    searchProperties$ = new Subject<string>();
    searchCommercial$ = new Subject<string>();
    commercials: any;
    property = false as boolean;
    object = false as boolean;
    searchProjects$ = new Subject<string>();
    projects: any;
    userAgency: any;
    timeLine: any;
    type: UntypedFormControl = new UntypedFormControl('');
    searchInput: UntypedFormControl = new UntypedFormControl('');
    filteredTimeLine: any;
    env: { production: boolean; hmr: boolean; yiiUrl: string; apiUrl: string; cdnUrl: string; };
    loader: boolean;
    emailLoader: boolean;
    messageLoader: boolean;
    closeButton = false;
    dialogRef: any;
    modelData: any;
    model: any;
    currentLang: any;
    relatedTo: any;
    // showingActivities: Boolean = true;
    accountsOrOwnersTimeline: Boolean = false;
    // #2683
    showingActivities: any;
    showingActivitiesColor: Boolean = true;
    showingEmailsColor: Boolean = false;
    showingMessagesColor: Boolean = false;
    accountsMessagesTimeline: Boolean = false;
    meeTimeline: Boolean = false;
    messageTimeLine: any;
    expandedMessageTimeline = [];
    expandedMessageTimelineLoader: Boolean;
    currencySign : any;
    emailTimeLine: any;
    filteredEmailTimeLine: any;
    basicTimeLine: any;
    filteredBasicTimeLine: any;
    commentUserData: any;
    userRole: any;
    BadgeHidden: boolean =  true;
    propMesg = [];

    @ViewChild('replyForm', { static: false })
    private _replyForm: NgForm;

    @ViewChild('replyInput', { static: false })
    private _replyInput: ElementRef;

    private _unsubscribeAll: Subject<any>;
    propertyModule: any;

    constructor(
        public _timeLineData: TimeLineDataService,
        private _translateService: TranslateService,
        public _fuseSidebarService: FuseSidebarService,
        public _timeLineService: TimeLineService,
        private formBuilder: UntypedFormBuilder,
        private _globalFunction: GlobalFuntions,
        private _dropdownsService: DropdownsService,
        public _authenticationService: AuthenticationService,
        private _matDialog: MatDialog,
        private _getCommercialReference: GetCommercialReferencePipe,
        private _getPropertyReference: GetPropertyReferencePipe,
        private _baseService: BaseService,
        private _crmMenuService: CrmMenuService,
        private router: Router,
        private _ngZone: NgZone,
        ) {
            this.modelData = this._timeLineData.modelDataValue;
            this.relatedTo = this._timeLineData.relatedToValue;
            this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
            this.userAgency = _authenticationService.currentAgencyValue;
            this.env = environment;
            this._unsubscribeAll = new Subject();
            this.propertyModule = this._crmMenuService.isPropertyModule;            
            this.userRole = this._authenticationService.currentUserValue;
            
        }
        
        @ViewChild('autosize') autosize: CdkTextareaAutosize;
        triggerResize() {
            this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
          }
    

    ngOnInit(): void {
        this.loadData();
        this.comments = this.formBuilder.group({
            related_to: { value: this.userAgency.commercial_properties ? 'Object' : 'Property', disabled: false },
            related_to_id: { value: '', disabled: false },
            activity_type: { value: '', disabled: false }
        });
        if (this.comments.get('related_to').value == 'Object') {
            this.object = true;
        } else if (this.comments.get('related_to').value == 'Property') {
            this.property = true;
        }
        if (this.router.url.split('/').includes("boats") || this.router.url.split('/').includes("moorings"))
            this.comments.get('related_to').setValue(this.router.url.split('/').includes("boats") ? 'Boat' : 'Mooring');
        
        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                this.filteredTimeLine = FuseUtils.filterArrayByString(this.timeLine, searchText);
            });
        if(this.propertyModule) {
            this.searchProperties$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getProperty(search);
                }),
            )
            .subscribe((data: any) => {
                forEach(data.body, (value) => {
                    value.label = value.reference + ' - ' + value.agency_reference + ' - ' + value.type_one + ' - ' + value.location;
                });
                this.properties = data.body;
            });
        }

        this.searchCommercial$
        .pipe(
            startWith(''),
            switchMap((search: string) => {
                const searchFilter = {} as any;
                    if (search) {
                        const queryOr = [];
                        queryOr.push({ reference: Number(search) });
                        queryOr.push({ external_reference: { $regex: '.*' + search + '.*', $options: 'i' } });
                        queryOr.push({ other_reference: { $regex: '.*' + search + '.*', $options: 'i' } });
                        searchFilter.$or = queryOr;
                    }
                    return this._dropdownsService.getCommercialProperties(searchFilter);
                })
            )
            .subscribe((data: any) => {
                const commercialObjects = map(data.body, (property) => {
                    property.label = property.reference;
                    if (property.hasOwnProperty('property_type_one') && property?.property_type_one?.value) {
                        property.label = property.label + ' - ' + property.property_type_one.value[this.currentLang];
                    }
                    if (property.hasOwnProperty('property_location') && property?.property_location?.value) {
                        property.label = property.label + ' - ' + property.property_location.value[this.currentLang];
                    }
                    return property;
                });
                this.commercials = commercialObjects;
            });

        this.searchProjects$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getProject(search);
                }),
            )
            .subscribe((data: any) => {
                forEach(data.body, (value) => {
                    // tslint:disable-next-line: max-line-length
                    value.label = (value._id ? (this.userAgency.agency_reference + '-') : '') + value.reference + ' / ' + value.user_reference + ' / ' + value.agency_reference;
                });
                this.projects = data.body;
            });
            
            this.comments.get('related_to').valueChanges.subscribe((data: any) => {
            if (data === 'Property') {
                this.property = true;
                this.object = false;
            } else if (data === 'Object') {
                this.property = false;
                this.object = true;
            } else {
                this.property = false;
                this.object = false;
            }
        });
    }

    loadData(): void {
        this.timeLine = [];
        if (this.relatedTo === 'agencies' && this.modelData) {
            this.model = 'Agencies';
            this.agenciesTimeline();
        }
        if (this.relatedTo === 'sales' && this.modelData) {
            this.model = 'Accounts';
            this.salesTimeline();
        }
        if (this.relatedTo === 'account' && this.modelData) {
            this.model = 'Accounts';
            this.accountsOrOwnersTimeline = true;
            this.accountsMessagesTimeline = true;
            this.accountTimeline();
        }
        if (this.relatedTo === 'company' && this.modelData) {
            this.model = 'Company';
            this.companyTimeline();
        }
        if (this.relatedTo === 'activities' && this.modelData) {
            if (this.modelData.type === 'viewing') {
                this.model = 'Viewings';
            } else {
                this.model = 'Activities';
            }
            this.activitiesTimeline();
        }
        if (this.relatedTo === 'owner' && this.modelData) {
            this.model = 'Owners';
            this.accountsOrOwnersTimeline = true;
            this.ownerTimeline();
        }
        if (this.relatedTo === 'accountNotes' && this.modelData) {
            this.model = 'Accounts';
            this.closeButton = true;
            this.accountsOrOwnersTimeline = true;
            this.accountTimeline();
        }
        if (this.relatedTo === 'ownerNotes' && this.modelData) {
            this.model = 'Owners';
            this.closeButton = true;
            this.accountsOrOwnersTimeline = true;
            this.ownerTimeline();
        }
        if (this.relatedTo === 'Commercials' && this.modelData) {
            this.model = 'Commercial-Properties';
            this.commercialTimeline();
        }
        if (this.relatedTo === 'mooringPrivateInfo' && this.modelData) {
            this.model = 'Mooring-Properties';
            this.closeButton = true;
            this.boatMooringTimeline();
        }
        if (this.relatedTo === 'mooring' && this.modelData) {
            this.model = 'Mooring-Properties';            
            this.boatMooringTimeline();
            this.type.setValue('Note');
        }
        if (this.relatedTo === 'property' && this.modelData) {
            this.model = 'Properties';
            this.propertyTimeline();
            this.type.setValue('Note');
        }
        if (this.relatedTo === 'propertyPrivateinfo' && this.modelData) {
            this.model = 'Properties';
            this.closeButton = true;
            this.propertyTimeline();
        }
        if (this.relatedTo === 'sequence' && this.modelData) {
            this.model = 'Sequences';
            this.sequenceTimeline();
        }
        if (this.relatedTo === 'RentalContracts' && this.modelData) {
            this.model = 'RentalContracts';
            this.activitiesTimeline();
        }
    }

    openDialog(type: string, id: string, dateTime?: any): void {
        if (type === 'Meeting') {
            this.dialogRef = this._matDialog.open(MeetingCreateModalComponent, {
                panelClass: 'event-form-dialog',
                data: {
                    relatedTo: 'update',
                    _id: id
                }
            });
        } else if (type === 'Task') {
            this.dialogRef = this._matDialog.open(TasksCreateModalComponent, {
                panelClass: 'event-form-dialog',
                data: {
                    relatedTo: 'update',
                    _id: id
                }
            });
        } else if (type === 'Viewing') {
            this.dialogRef = this._matDialog.open(ViewingToursModalComponent, {
                panelClass: 'event-form-dialog',
                data: {
                    relatedTo: 'update',
                    _id: id
                }
            });
        } else if (type === 'Sale') {
            this.dialogRef = this._matDialog.open(SalesCreateModalComponent, {
                panelClass: 'event-form-dialog',
                data: {
                    relatedTo: 'update',
                    _id: id
                }
            });
        } else if (type === 'Offer') {
            this.dialogRef = this._matDialog.open(OffersModalComponent, {
                panelClass: 'event-form-dialog',
                data: {
                    relatedTo: 'update',
                    _id: id
                }
            });
        }
        else { return; }

        this.dialogRef.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
                if (response) {
                }
            });
    }

    submit(): void {
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    agenciesTimeline(): void {
        this.loader = true;
        this._baseService.get(`comments/agencies-timeline?model=${this.model}&model_id=${this.modelData._id}&expand=attachments,user_data,created_user`)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = data;
                this.filteredTimeLine = data;
                this.loader = false;
            });

    }

    salesTimeline(): void {
        this.loader = true;
        this._timeLineService.getAccountTimeline(this.model, this.modelData.account)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = data;
                this.filteredTimeLine = data;
                this.loader = false;
            });
        this._timeLineService.getAccountTimelineEmails(this.model, this.modelData.account)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.emailTimeLine = data;
                this.filteredEmailTimeLine = data;
            });
    }

    showActivities(status): void {
        this.showingActivities = status;
        if (status === 'true') {
            this.showingActivitiesColor = true;
            this.showingEmailsColor = false;
            this.showingMessagesColor = false;

            this.timeLine = this.basicTimeLine;
            this.filteredTimeLine = this.filteredBasicTimeLine;
        } else if(status === 'false') {
            this.showingActivitiesColor = false;
            this.showingEmailsColor = true;
            this.showingMessagesColor = false;

            this.timeLine = this.emailTimeLine;
            this.filteredTimeLine = this.filteredEmailTimeLine;
        } else if(status === 'message') {
            this.showingActivitiesColor = false;
            this.showingEmailsColor = false;
            this.showingMessagesColor = true;
            
            this.timeLine = this.messageTimeLine;
            this.filteredTimeLine = null;
        }
    }

    accountTimeline(): void {
        this.loader = true;
        this.emailLoader = true;
        this.messageLoader = true;
        this._timeLineService.getAccountTimeline(this.model, this.modelData._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = this.basicTimeLine = data;
                const filterData = forEach(data, (value: any) => {
                    if (value && value.activity_type) {
                        value.activity_type = this._translateService.instant(value.activity_type);
                    }
                    if (value && value.comment) {
                        const dates = new Date(value.created_at);
                        const NewDate = dates.getDate() + '/' + (dates.getMonth() + 1) + '/' + dates.getFullYear();
                        value.customComment =
                            `<b> ${this._translateService.instant('Account')}</b> : 
                         ${value?.user_data?.full_name} 
                        <br>
                        <b> ${this._translateService.instant('Date')}</b> : ${NewDate ? NewDate : ''} <br>
                        <b> ${this._translateService.instant('Time')}</b> : ${value.created_at ? (dates.getHours() + ':' + dates.getMinutes()) : ''} 
                        <br> ${value.comment ? value.comment : ''}
                        <br> ${(value.email_data && value.email_data.content) ? value.email_data.content : ''}
                        ` ;
                    }
                });
                this.filteredTimeLine = this.filteredBasicTimeLine = filterData;
                this.loader = false;
                if (this.modelData.whatsapp) {
                    this.comments.get('activity_type').setValue('Whatsapp/SMS');
                }
            });
        this._timeLineService.getAccountTimelineEmails(this.model, this.modelData._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.emailTimeLine = data;
                this.filteredEmailTimeLine = forEach(data, (value: any) => {
                    if (value && value?.activity_type) {
                        value.activity_type = this._translateService.instant(value?.activity_type);
                    }
                    if (value && value?.comment) {
                        const dates = new Date(value?.created_at);
                        const NewDate = dates.getDate() + '/' + (dates.getMonth() + 1) + '/' + dates.getFullYear();
                        value.customComment =
                            `<b> ${this._translateService.instant('Account')}</b> : 
                         ${value?.user_data?.full_name} 
                        <br>
                        <b> ${this._translateService.instant('Date')}</b> : ${NewDate ? NewDate : ''} <br>
                        <b> ${this._translateService.instant('Time')}</b> : ${value.created_at ? (dates.getHours() + ':' + dates.getMinutes()) : ''} 
                        <br> ${value.comment ? value.comment : ''}
                        <br> ${(value.email_data && value.email_data.content) ? value.email_data.content : ''}
                        ` ;
                    }
                });
                this.emailLoader = false;
                if (this.showingEmailsColor) {
                    this.timeLine = this.emailTimeLine;
                    this.filteredTimeLine = this.filteredEmailTimeLine;
                }
            });
        this._timeLineService.getAccountTimelineMessages("Properties", this.modelData._id).pipe(takeUntil(this._unsubscribeAll))
        .subscribe((data : any) => {
            if(data) {
                this.messageTimeLine = data;
                this.messageLoader = false;
                this.messageTimeLine = forEach(data, (value: any) => { 
                    if (value && value.currency) {
                        value.currency = this.checkCurrencySign(value);
                    }
                });
            }
        });
        this._timeLineService.getAccountExpandedPropertiesTimelineStatus(this.modelData._id).pipe(takeUntil(this._unsubscribeAll))
        .subscribe((statusData: any)=> {
            if(statusData) {
                const unSeenStatues = [];
                if (statusData?.comments) {
                    statusData.comments.forEach((msg) => {
                        if(msg?.status === 'Unseen') {
                            unSeenStatues.push(msg?.status)
                        }
                    });
                }
                if(unSeenStatues.length > 0) {
                    this.BadgeHidden = false;
                }else {
                    this.BadgeHidden = true;
                }
            }
        });     
    }

    activitiesTimeline(): void {
        this.loader = true;
        this._timeLineService.getActivitiesTimeline(this.model, this.modelData._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = data;
                this.filteredTimeLine = data;
                this.loader = false;
            });
    }

    companyTimeline(): void {
        this.loader = true;
        this._timeLineService.getCompaniesTimeline(this.model, this.modelData._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = data;
                this.filteredTimeLine = data;
                this.loader = false;
            });
    }

    ownerTimeline(): void {
        this.loader = true;
        this.emailLoader = true;
        this._timeLineService.getOwnerTimeline(this.model, this.modelData._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = this.basicTimeLine = data;
                const filterData = forEach(data, (value: any) => {
                    if (value && value.activity_type) {
                        value.activity_type = this._translateService.instant(value.activity_type);
                    }
                    if (value && value.comment) {
                        const dates = new Date(value.created_at * 1000);
                        const NewDate = dates.getDate() + '/' + (dates.getMonth() + 1) + '/' + dates.getFullYear();
                        value.customComment =
                            `<b> ${this._translateService.instant('Owner')}</b> : 
                         ${this.modelData?.full_name} 
                        <br>
                        <b> ${this._translateService.instant('Date')}</b> : ${NewDate ? NewDate : ''} <br>
                        <b> ${this._translateService.instant('Time')}</b> : ${value.created_at ? (dates.getHours() + ':' + dates.getMinutes()) : ''} 
                        <br> ${value.comment ? value.comment : ''}
                        ` ;
                    }
                });
                this.filteredTimeLine = this.filteredBasicTimeLine = filterData;
                this.loader = false;
                if (this.modelData.whatsapp) {
                    this.comments.get('activity_type').setValue('Whatsapp/SMS');
                }
            });
        this._timeLineService.getOwnerTimelineEmails(this.model, this.modelData._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.emailTimeLine = data;
                this.filteredEmailTimeLine = forEach(data, (value: any) => {
                    if (value && value.activity_type) {
                        value.activity_type = this._translateService.instant(value.activity_type);
                    }
                    if (value && value.comment) {
                        const dates = new Date(value.created_at);
                        const NewDate = dates.getDate() + '/' + (dates.getMonth() + 1) + '/' + dates.getFullYear();
                        value.customComment =
                            `<b> ${this._translateService.instant('Account')}</b> : 
                         ${value?.user_data?.full_name} 
                        <br>
                        <b> ${this._translateService.instant('Date')}</b> : ${NewDate ? NewDate : ''} <br>
                        <b> ${this._translateService.instant('Time')}</b> : ${value.created_at ? (dates.getHours() + ':' + dates.getMinutes()) : ''} 
                        <br> ${value.comment ? value.comment : ''}
                        <br> ${(value.email_data && value.email_data.content) ? value.email_data.content : ''}
                        ` ;
                    }
                });
                this.emailLoader = false;
                if (this.showingEmailsColor) {
                    this.timeLine = this.emailTimeLine;
                    this.filteredTimeLine = this.filteredEmailTimeLine;
                }
            });
    }

    propertyTimeline(): void {
        this.loader = true;
        this._timeLineService.getPropertyTimeline(this.model, this.modelData._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = data;
                // this.filteredTimeLine = data;
                this.filteredTimeLine = forEach(data, (value: any) => {
                    if (value && value.comment) {
                        const dates = new Date(value.date_time_unix * 1000);
                        const NewDate = dates.getDate() + '/' + (dates.getMonth() + 1) + '/' + dates.getFullYear();
                        value.customComment =
                            `<b> ${this._translateService.instant('Property')}</b> : 
                        ${this._getPropertyReference.transform(this.modelData)} ${this.modelData?.type_one_name ? this.modelData?.type_one_name : ''} 
                        ${this._translateService.instant('in')} ${this.modelData?.location_name ? this.modelData?.location_name : ''}
                        <br>
                        <b> ${this._translateService.instant('Date')}</b> : ${NewDate ? NewDate : ''} <br>
                        <b> ${this._translateService.instant('Time')}</b> : ${value.date_time ? (dates.getHours() + ':' + dates.getMinutes()) : ''} 
                        <br> ${value?.comment}
                        ` ;
                    }
                });
                this.loader = false;
            });
    }

    commercialTimeline(): void {
        this.loader = true;
        this._timeLineService.getCommercialTimeline(this.model, this.modelData._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = data;
                // this.filteredTimeLine = data;
                this.filteredTimeLine = forEach(data, (value: any) => {
                    if (value && value.comment) {
                        const dates = new Date(value.date_time_unix * 1000);
                        const NewDate = dates.getDate() + '/' + (dates.getMonth() + 1) + '/' + dates.getFullYear();
                        value.customComment =
                            `<b> ${this._translateService.instant('Property')}</b> : 
                         ${this._getCommercialReference.transform(this.modelData)} ${this.modelData?.type_one_value ? this.modelData?.type_one_value[this.currentLang] : ''} 
                         ${this._translateService.instant('in')} ${this.modelData?.location_value ? this.modelData?.location_value[this.currentLang] : ''}
                        <br>
                        <b> ${this._translateService.instant('Date')}</b> : ${NewDate ? NewDate : ''} <br>
                        <b> ${this._translateService.instant('Time')}</b> : ${value.created_at ? (dates.getHours() + ':' + dates.getMinutes()) : ''} 
                        <br> ${value?.comment}
                        ` ;
                    }
                });
                this.loader = false;
            });
    }

    boatMooringTimeline(): void {
        this.loader = true;
        this._timeLineService.getBoatMooringTimeline(this.model, this.modelData._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = data;
                this.filteredTimeLine = forEach(data, (value: any) => {
                    if (value && value.comment) {
                        const dates = new Date(value.date_time_unix * 1000);
                        const NewDate = dates.getDate() + '/' + (dates.getMonth() + 1) + '/' + dates.getFullYear();
                        const type = this.router.url.split('/').includes("boats") ? 'Boat' : 'Mooring';
                        value.customComment =
                            `<b> ${this._translateService.instant(type)}</b> : 
                         ${this._getCommercialReference.transform(this.modelData)} ${this.modelData?.type_one_value ? this.modelData?.type_one_value[this.currentLang] : ''} 
                         ${this._translateService.instant('in')} ${this.modelData?.location_value ? this.modelData?.location_value[this.currentLang] : ''}
                        <br>
                        <b> ${this._translateService.instant('Date')}</b> : ${NewDate ? NewDate : ''} <br>
                        <b> ${this._translateService.instant('Time')}</b> : ${value.created_at ? (dates.getHours() + ':' + dates.getMinutes()) : ''} 
                        <br> ${value?.comment}
                        ` ;
                    }
                });
                this.loader = false;
            });
    }

    sequenceTimeline(): void {
        this.loader = true;
        this._timeLineService.getSequenceTimeline(this.model, this.modelData._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = data;
                this.filteredTimeLine = data;
                this.loader = false;
            });
    }

    getTimeline(): void {
        this.loader = true;
        this._baseService.get(`comments?model=${this.model}&model_id=${this.modelData._id}&expand=attachments,user_data,created_user`)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.timeLine = data;
                this.filteredTimeLine = data;
                this.loader = false;
            });
    }

    reply(event: any, modelName='', modelId='', msgIndex=''): void {
        const currentUser = this._authenticationService.currentUserValue;
        event.preventDefault();

        /**
         * https://gitlab.optimasit.com/arsl/optima-crm-v2/-/issues/2963
         * as we using different controls to send message 
         * that why we use here setSendMessage
         */
        let setSendMessage = null;
        setSendMessage = this._replyForm?.form?.value?.message;

        if((typeof(this._replyForm.form.value.message0) == 'undefined') && setSendMessage == null) {
            setSendMessage = this.propMesg[msgIndex];
        }
        if(typeof(setSendMessage) == 'undefined' || setSendMessage == null) {
            setSendMessage = this._replyForm.form.value.message0;
        }

        if (!setSendMessage) {
            return;
        }
        const m_name = modelName ? modelName : this.model;
        const m_id = modelId ? modelId : this.modelData._id; 
        const message = {
            message: setSendMessage,
            activity_type: this.comments.get('activity_type').value,
            related_to: this.comments.get('related_to').value,
            related_to_id: this.comments.get('related_to_id').value,
            comment: setSendMessage,
            created_by: currentUser.user_id,
            model: m_name,
            model_id: m_id,
            user: currentUser.full_name,
            user_id: currentUser.user_id,
            users: []
        };
        if (this.relatedTo === 'sales') {
            message.model_id = this.modelData.account;
        }

        // for message tab check
        if(this.showingMessagesColor) {
            Object.assign(message, {account_id: this.modelData._id});
            Object.assign(message, {assigned_to_id: currentUser.user_id});  // as assigned user login to send reply
            Object.assign(message, {status: "Seen"});
            if(message?.related_to_id !='') {
                Object.assign(message, {related_to_id: modelId});
            }
        }
        this.propMesg[msgIndex] = '';
        this._replyForm.reset();
        this.loader = true;
        this.messageLoader = true;
        this._timeLineService.addComment(this._globalFunction.cleanObject(message))
            .subscribe(() => {
                if (this.relatedTo === 'agencies') {
                    this.agenciesTimeline();
                }
                if (this.relatedTo === 'sales') {
                    this.salesTimeline();
                }
                if (this.relatedTo === 'account') {
                    this.accountTimeline();
                }
                if (this.relatedTo === 'company') {
                    this.companyTimeline();
                }
                if (this.relatedTo === 'owner') {
                    this.ownerTimeline();
                }
                if (this.relatedTo === 'ownerNotes') {
                    this.ownerTimeline();
                }
                if (this.relatedTo === 'accountNotes') {
                    this.accountTimeline();
                }
                if (this.relatedTo === 'property' || this.relatedTo === 'propertyPrivateinfo') {
                    this.propertyTimeline();
                }
                if (this.relatedTo === 'sequence') {
                    this.sequenceTimeline();
                }
                if (this.relatedTo === 'activities') {
                    this.activitiesTimeline();
                }
                if (this.relatedTo === 'Commercials') {
                    this.commercialTimeline();
                }
                if (this.relatedTo === 'mooringPrivateInfo' || this.relatedTo === 'mooring') {
                    this.boatMooringTimeline();
                }
            });

        if (this.comments.get('activity_type').value === 'Whatsapp/SMS' && this.modelData.mobile_phone) {
            window.open(`https://api.whatsapp.com/send?phone=${this.modelData.mobile_phone}&text=${message.comment}&source=&data=`, `_blank`);
        }
        if (this.comments.get('activity_type').value === 'Whatsapp/SMS' && this.modelData.mobile) {
            window.open(`https://api.whatsapp.com/send?phone=${this.modelData.mobile}&text=${message.comment}&source=&data=`, `_blank`);
        }
    }

    checkCurrencySign(property){
        this.currencySign = ' €';
        if (property?.currency) {
            if (property.currency === 'GBP') {
              this.currencySign = ' £';
            } else if (property.currency === 'USD') {
              this.currencySign = ' $';
            } else if(property.currency === 'EUR'){
              this.currencySign = ' €';
            }
        }
        return this.currencySign;
    }

    expandedRows(expandedProp, i) {
        this.expandedMessageTimelineLoader = true;
        this._timeLineService.getAccountExpandedPropertiesTimeline(expandedProp._id, this.modelData._id).pipe(takeUntil(this._unsubscribeAll))
        .subscribe((data : any) => {
            if(data) {
                this.expandedMessageTimeline[i] = data.comment;
                this.commentUserData = data.user_data;
                let comment_ids_array=[];
                data.comment.forEach(commentIds => {
                    if(commentIds.status === 'Unseen') {
                        comment_ids_array.push(commentIds._id.$oid)
                    }
                });
                this.expandedMessageTimelineLoader = false;
                if(comment_ids_array.length > 0) {
                    this._timeLineService.getAccountTimelineUpdateStatuses(comment_ids_array).pipe(takeUntil(this._unsubscribeAll)).subscribe((updateStatueResp : any) => {
                        if(updateStatueResp) {
                            this.BadgeHidden = updateStatueResp.body
                        }
                    });
                }
            }
        });
    }
}

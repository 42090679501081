import { Component, OnInit, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-mat-svg-icon',
  templateUrl: './mat-svg-icon.component.html',
  styleUrls: ['./mat-svg-icon.component.scss']
})
export class MatSvgIconComponent implements OnInit {
  @Input() iconName: string;

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    // Register the SVG icon with MatIconRegistry
    this.iconRegistry.addSvgIcon(
      this.iconName,
      this.sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/svgs/${this.iconName}.svg`)
    );
  }

}

import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { merge, Subject } from 'rxjs';
import { DropdownsService, AuthenticationService } from 'app/services';
import { takeUntil, switchMap, startWith } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { PropertiesService } from 'app/services/properties.service';
import { GlobalFuntions } from 'app/_helpers';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { map, includes, forEach, filter } from 'lodash';
import { PropertiesSearchService } from 'app/services/properties-search.service';
import { SaveBookmarkComponent } from '../save-bookmark/save-bookmark.component';
import { MatDialog } from '@angular/material/dialog';
import { CrmMenuService } from 'app/services/crm-menu.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
    selector: 'properties-srarch-form',
    templateUrl: './search-form.component.html',
    styleUrls: ['./search-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class SearchFormComponent implements OnInit, OnDestroy {

    @Input() account: string;
    @Input() calendar: boolean;
    form: UntypedFormGroup;
    searchOffices$ = new Subject<string>();
    offices = [] as any;
    searchListingAgency$ = new Subject<string>();
    searchUserAgency$ = new Subject<string>();
    listingAgencies = [] as any;
    userAgencies = [] as any;
    searchCompanyContacts$ = new Subject<string>();
    companyContacts = [] as any;
    searchListingAgent$ = new Subject<string>();
    listingAgent = [] as any;
    searchCountries$ = new Subject<any>();
    countries = [] as any;
    searchProvinces$ = new Subject<any>();
    provinces = [] as any;
    searchCities$ = new Subject<any>();
    cities = [] as any;
    searchLocations$ = new Subject<any>();
    locations = [] as any;
    Groups = [] as any;
    searchUrbanisations$ = new Subject<string>();
    urbanisations = [] as any;
    searchPropertyTypes$ = new Subject<string>();
    propertyTypes = [] as any;
    searchPropertySubTypes$ = new Subject<any>();
    propertySupTypes = [] as any;
    searchBuildingStyles$ = new Subject<string>();
    buildingStyles = [] as any;
    searchOwners$ = new Subject<string>();
    owners = [] as any;
    sharingLevels = [] as any;
    sharedAgency = [] as any;
    currentLang: string;
    currentUser: any;
    searchSharedAgency$ = new Subject<string>();
    @Output() submitSearchForm: EventEmitter<any> = new EventEmitter();
    extend = true;
    private _unsubscribeAll: Subject<any>;
    view = 'extended-view';
    pcategories: any;
    mooring: any;
    filter: any;
    bookMark = '';
    bookMarkData: any;
    bsearch = {} as any;
    showbMarks = false as boolean;
    applyCheck = false;
    userAgencyData: any;
    id: any;
    searchLG$ = new Subject<string>();
    lgData = [] as any;
    showLocationGroups = false;
    showLocation = true;
    addEditDialogRef: any;
    categories = [{
        key: 'bargain',
        value: this._translateService.instant('bargain')
    },
    {
        key: 'beachfront',
        value: this._translateService.instant('beachfront')
    },
    {
        key: 'cheap',
        value: this._translateService.instant('cheap')
    },
    {
        key: 'distressed',
        value: this._translateService.instant('distressed')
    },
    {
        key: 'ecological',
        value: this._translateService.instant('ecological')
    },
    {
        key: 'equestrian',
        value: this._translateService.instant('equestrian')
    },
    {
        key: 'golf',
        value: this._translateService.instant('golf')
    },
    {
        key: 'holiday_homes',
        value: this._translateService.instant('holiday_homes')
    },
    {
        key: 'investment',
        value: this._translateService.instant('investment')
    },
    {
        key: 'luxury',
        value: this._translateService.instant('luxury')
    },
    {
        key: 'off_plan',
        value: this._translateService.instant('off_plan')
    },
    {
        key: 'reduced',
        value: this._translateService.instant('reduced')
    },
    {
        key: 'remote',
        value: this._translateService.instant('remote')
    },
    {
        key: 'repossession',
        value: this._translateService.instant('repossession')
    },
    {
        key: 'resale',
        value: this._translateService.instant('resale')
    }];

    characteristics = [
        {
            key: 'beachfront',
            value: this._translateService.instant('beachfront')
        },
        {
            key: 'beachside',
            value: this._translateService.instant('beachside')
        },
        {
            key: 'close_to_airport',
            value: this._translateService.instant('close_to_airport')
        },
        {
            key: 'close_to_busstop',
            value: this._translateService.instant('close_to_busstop')
        },
        {
            key: 'close_to_church',
            value: this._translateService.instant('close_to_church')
        },
        {
            key: 'close_to_forest',
            value: this._translateService.instant('close_to_forest')
        },
        {
            key: 'close_to_golf',
            value: this._translateService.instant('close_to_golf')
        },
        {
            key: 'close_to_hotel',
            value: this._translateService.instant('close_to_hotel')
        },
        {
            key: 'close_to_marina',
            value: this._translateService.instant('close_to_marina')
        },
        {
            key: 'close_to_mosque',
            value: this._translateService.instant('close_to_mosque')
        },
        {
            key: 'close_to_port',
            value: this._translateService.instant('close_to_port')
        },
        {
            key: 'close_to_restaurant',
            value: this._translateService.instant('close_to_restaurant')
        },
        {
            key: 'close_to_schools',
            value: this._translateService.instant('close_to_schools')
        },
        {
            key: 'close_to_sea',
            value: this._translateService.instant('close_to_sea')
        },
        {
            key: 'close_to_shops',
            value: this._translateService.instant('close_to_shops')
        },
        {
            key: 'close_to_skiing',
            value: this._translateService.instant('close_to_skiing')
        },
        {
            key: 'close_to_supermarkets',
            value: this._translateService.instant('close_to_supermarkets')
        },
        {
            key: 'close_to_taxistand',
            value: this._translateService.instant('close_to_taxistand')
        },
        {
            key: 'close_to_town',
            value: this._translateService.instant('close_to_town')
        },
        {
            key: 'close_to_train',
            value: this._translateService.instant('close_to_train')
        },
        {
            key: 'commercial_area',
            value: this._translateService.instant('commercial_area')
        },
        {
            key: 'countryside',
            value: this._translateService.instant('countryside')
        },
        {
            key: 'easy_access',
            value: this._translateService.instant('easy_access')
        },
        {
            key: 'frontline_golf',
            value: this._translateService.instant('frontline_golf')
        },
        {
            key: 'marina',
            value: this._translateService.instant('marina')
        },
        {
            key: 'mountain_pueblo',
            value: this._translateService.instant('mountain_pueblo')
        },
        {
            key: 'no_nearby_neighbours',
            value: this._translateService.instant('no_nearby_neighbours')
        },
        {
            key: 'not_isolated',
            value: this._translateService.instant('not_isolated')
        },
        {
            key: 'port',
            value: this._translateService.instant('port')
        },
        {
            key: 'private',
            value: this._translateService.instant('private')
        },
        {
            key: 'suburban',
            value: this._translateService.instant('suburban')
        },
        {
            key: 'town',
            value: this._translateService.instant('town')
        },
        {
            key: 'tranquil',
            value: this._translateService.instant('tranquil')
        },
        {
            key: 'urbanisation',
            value: this._translateService.instant('urbanisation')
        },
        {
            key: 'village',
            value: this._translateService.instant('village')
        },
    ];

    views = [{
        key: 'beach',
        value: this._translateService.instant('beach')
    },
    {
        key: 'countryside',
        value: this._translateService.instant('countryside')
    },
    {
        key: 'courtyard',
        value: this._translateService.instant('courtyard')
    },
    {
        key: 'forest',
        value: this._translateService.instant('forest')
    },
    {
        key: 'garden',
        value: this._translateService.instant('garden')
    },
    {
        key: 'golf',
        value: this._translateService.instant('golf')
    },
    {
        key: 'lake',
        value: this._translateService.instant('lake')
    },
    {
        key: 'mountain',
        value: this._translateService.instant('mountain')
    },
    {
        key: 'panoramic',
        value: this._translateService.instant('panoramic')
    },
    {
        key: 'partial_seaviews',
        value: this._translateService.instant('partial_seaviews')
    },
    {
        key: 'pool',
        value: this._translateService.instant('pool')
    },
    {
        key: 'port',
        value: this._translateService.instant('port')
    },
    {
        key: 'sea',
        value: this._translateService.instant('sea')
    },
    {
        key: 'ski',
        value: this._translateService.instant('ski')
    },
    {
        key: 'street',
        value: this._translateService.instant('street')
    },
    {
        key: 'urban',
        value: this._translateService.instant('urban')
    }];

    conditions = [
        {
            key: 'excellent',
            value: this._translateService.instant('excellent')
        },
        {
            key: 'fair',
            value: this._translateService.instant('fair')
        },
        {
            key: 'minor_updates_required',
            value: this._translateService.instant('minor_updates_required')
        },
        {
            key: 'good',
            value: this._translateService.instant('good')
        },
        {
            key: 'never_lived',
            value: this._translateService.instant('never_lived')
        },
        {
            key: 'renovation_required',
            value: this._translateService.instant('renovation_required')
        },
        {
            key: 'recently_renovated',
            value: this._translateService.instant('recently_renovated')
        },
        {
            key: 'recently_refurbished',
            value: this._translateService.instant('recently_refurbished')
        },
        {
            key: 'finishing_habitable_required',
            value: this._translateService.instant('finishing_habitable_required')
        },
        {
            key: 'basically_habitable',
            value: this._translateService.instant('basically_habitable')
        }];

    parking = [{
        key: 'communal_garage',
        value: this._translateService.instant('communal_garage')
    },
    {
        key: 'parking_communal',
        value: this._translateService.instant('parking_communal')
    },
    {
        key: 'covered',
        value: this._translateService.instant('covered')
    },
    {
        key: 'private',
        value: this._translateService.instant('private')
    },
    {
        key: 'more_than_one',
        value: this._translateService.instant('more_than_one')
    }];

    garden = [
        {
            key: 'almond_grove',
            value: this._translateService.instant('almond_grove')
        },
        {
            key: 'garden_communal',
            value: this._translateService.instant('garden_communal')
        },
        {
            key: 'easy_maintenance',
            value: this._translateService.instant('easy_maintenance')
        },
        {
            key: 'fenced',
            value: this._translateService.instant('fenced')
        },
        {
            key: 'fruit_trees_citrus',
            value: this._translateService.instant('fruit_trees_citrus')
        },
        {
            key: 'fruit_trees_tropical',
            value: this._translateService.instant('fruit_trees_tropical')
        },
        {
            key: 'irrigation_rights',
            value: this._translateService.instant('irrigation_rights')
        },
        {
            key: 'landscaped',
            value: this._translateService.instant('landscaped')
        },
        {
            key: 'Lawn',
            value: this._translateService.instant('Lawn')
        },
        {
            key: 'olive_grove',
            value: this._translateService.instant('olive_grove')
        },
        {
            key: 'outdoor_dining',
            value: this._translateService.instant('outdoor_dining')
        },
        {
            key: 'playground',
            value: this._translateService.instant('playground')
        },
        {
            key: 'plenty_of_water',
            value: this._translateService.instant('plenty_of_water')
        },
        {
            key: 'pool_house',
            value: this._translateService.instant('pool_house')
        },
        {
            key: 'garden_private',
            value: this._translateService.instant('garden_private')
        },
        {
            key: 'shade_control',
            value: this._translateService.instant('shade_control')
        },
        {
            key: 'tropical_garden',
            value: this._translateService.instant('tropical_garden')
        },
        {
            key: 'vegetable',
            value: this._translateService.instant('vegetable')
        },
        {
            key: 'veranda',
            value: this._translateService.instant('veranda')
        },
        {
            key: 'vineyard',
            value: this._translateService.instant('vineyard')
        }];
    pool = [{
        key: 'pool_communal',
        value: this._translateService.instant('pool_communal')
    },
    {
        key: 'pool_indoor',
        value: this._translateService.instant('pool_indoor')
    },
    {
        key: 'pool_private',
        value: this._translateService.instant('pool_private')
    }];
    statuses = [{
        key: 'Occupied',
        value: this._translateService.instant('Occupied')
    },
    {
        key: 'Not occupied',
        value: this._translateService.instant('Not occupied')
    },
    {
        key: 'Periodically occupied',
        value: this._translateService.instant('Periodically occupied')
    }];
    Licences = [
        {
            key: 'Assimilated',
            value: this._translateService.instant('Assimilated')
        },
        {
            key: 'Application pending',
            value: this._translateService.instant('Application pending')
        },
        {
            key: 'Under Investigation',
            value: this._translateService.instant('Under Investigation')
        },
        {
            key: 'Owner applying',
            value: this._translateService.instant('Owner applying')
        },
        {
            key: 'Not possible to obtain AFO',
            value: this._translateService.instant('Not possible to obtain AFO')
        },
        {
            key: 'First Occupation License',
            value: this._translateService.instant('First Occupation License')
        }
    ];


    constructor(
        private _formBuilder: UntypedFormBuilder,
        public _dropdownsService: DropdownsService,
        public _translateService: TranslateService,
        public _fuseSidebarService: FuseSidebarService,
        private _matDialog: MatDialog,
        public _authenticationService: AuthenticationService,
        private _propertiesService: PropertiesService,
        private _globalFunction: GlobalFuntions,
        private route: ActivatedRoute,
        private router: Router,
        private _propertiesSearchService: PropertiesSearchService,
        private _crmMenuService: CrmMenuService,
        private _fuseNavigationService: FuseNavigationService,
    ) {
        this._unsubscribeAll = new Subject();
        this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
        this.currentUser = this._authenticationService.currentUserValue;
        this.userAgencyData = _authenticationService.currentAgencyValue;
        this.filter = {};
        this.filter.options = {
            limit: 1000,
            page: 1,
            sort: {
                'value.en': 1
            }
        };
        this.setForm();
        // old set
        // 'Valuation'
        this.form.get('status').setValue(['Available', 'Under Offer', 'Valuation']);
        router.events.pipe(takeUntil(this._unsubscribeAll)).subscribe((val: any) => {
            if (val instanceof NavigationEnd) {
                this.id = this.route.snapshot.paramMap.get('id');
                // if (this.id) {
                //     this._propertiesService.getBookmarkById(this.id).subscribe((data: any) => {
                //         // this.searchFilters = data.search_query;
                //         this.form.patchValue(data.search_query);
                //     });
                // } else {
                this._propertiesSearchService.propertiesSearchData
                    .subscribe((data: any) => {
                        if (data && data.type && includes(this.router.url, data.type)) {
                            this.form.patchValue(data.data);
                        }
                    });
                // }
            }
        });

    }

    ngOnInit(): void {
        if (this.calendar) {
            this.form.get('lt_rental').setValue(true);
            this.form.get('st_rental').setValue(true);
            this.form.get('status').setValue(['Available']);
        }
        this._propertiesService.getBookmarks('residential').subscribe((data: any) => {
            this.bookMarkData = data;
        });

        this._translateService.onLangChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
                const locationGroups = map(this.lgData, (locationGroup) => {
                    locationGroup.label = locationGroup.value[this.currentLang];
                    return locationGroup;
                });
                this.lgData = locationGroups;
            });

        this.searchOffices$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getOffices(search);
                }),
            )
            .subscribe((data: any) => {
                this.offices = data.body;
            });

        this.searchListingAgency$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getListingAgency(search);
                }),
            )
            .subscribe((data: any) => {
                this.listingAgencies = data.body;
            });

        this.searchUserAgency$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap(() => {
                    return this._dropdownsService.getUserAgency();
                }),
            )
            .subscribe((data: any) => {
                this.userAgencies = data.body;
            });

        this.searchListingAgent$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getListingAgents(search);
                }),
            )
            .subscribe((data: any) => {
                this.listingAgent = data.body;
            });
        this.searchCountries$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: any) => {
                    return this._dropdownsService.getCountries(search);
                }),
            )
            .subscribe((data: any) => {
                const countries = _.map(data.docs, (doc) => {
                    doc.label = doc.value[this.currentLang];
                    return doc;
                });
                this.countries = countries;
            });

        this.searchUrbanisations$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getUrbanisation(search);
                }),
            )
            .subscribe((data: any) => {
                this.urbanisations = data.docs;
            });

        this.searchPropertyTypes$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getPropertyTypes(search);
                }),
            )
            .subscribe((data: any) => {
                const propertyTypes = _.map(data, (doc) => {
                    doc.label = doc.value[this.currentLang];
                    return doc;
                });
                this.propertyTypes = propertyTypes;
            });

        this.searchBuildingStyles$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getBuildingStyle(search);
                }),
            )
            .subscribe((data: any) => {
                const buildingStyles = _.map(data.body, (doc) => {
                    doc.label = doc.value[this.currentLang];
                    return doc;
                });
                this.buildingStyles = buildingStyles;
            });

        // this.searchOwners$
        //     .pipe(
        //         startWith(''),
        //         takeUntil(this._unsubscribeAll),
        //         switchMap((search: string) => {
        //             return this._dropdownsService.getOwners(search);
        //         }),
        //     )
        //     .subscribe((data: any) => {
        //         this.owners = data.body;
        //     });

        this.searchOwners$
            .pipe(
                startWith(''),
                switchMap((searchValue: string) => {
                    let search = '';
                    if (searchValue) {
                        search = searchValue;
                    }
                    return this._dropdownsService.getOwners(search);
                })
            )
            .subscribe((data: any) => {
                const owners = map(data.body, (element) => {
                    if (element.type === 'company') {
                        element.label = element.company_name;
                    } else {
                        element.label = element.full_name;
                    }
                    return element;
                });
                this.owners = owners;
            });

        this.searchCompanyContacts$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getCompanyContacts(this.form.get('listing_agency').value, search);
                }),
            )
            .subscribe((data: any) => {
                this.companyContacts = data.body;
            });

        this.searchProvinces$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: any) => {
                    return this._dropdownsService.getProvinces(search, this.form.get('address_country').value);
                }),
            )
            .subscribe((data: any) => {
                const provinces = _.map(data.docs, (doc) => {
                    doc.label = doc.value[this.currentLang];
                    return doc;
                });
                this.provinces = provinces;
            });

        this.searchCities$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getCities(search, false, false, this.form.get('address_province').value);
                }),
            )
            .subscribe((data: any) => {
                const cities = _.map(data.docs, (doc) => {
                    doc.label = doc.value[this.currentLang];
                    return doc;
                });
                if (!_.isEmpty(this.form.get('address_province').value)) {
                    this.cities = cities;
                }
            });

        this.searchLocations$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    // tslint:disable-next-line: max-line-length
                    return this._dropdownsService.getLocations(search, this.form.get('address_country').value, false, this.form.get('address_province').value, this.form.get('address_city').value);
                }),
            )
            .subscribe((data: any) => {
                const locations = _.map(data.docs, (doc) => {
                    doc.label = doc.value[this.currentLang];
                    return doc;
                });
                this.locations = locations;
            });
        this.searchLG$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap(() => {
                    return this._dropdownsService.getLocationGroupFilters();
                }),
            )
            .subscribe((data: any) => {
                const locationGroups = map(data, (locationGroup) => {
                    locationGroup.label = locationGroup.value[this.currentLang];
                    return locationGroup;
                });
                this.lgData = locationGroups;

            });
        this.searchPropertySubTypes$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: any) => {
                    return this._dropdownsService.getPropertySubTypes(this.form.get('type_one').value, search);
                }),
            )
            .subscribe((data: any) => {
                const propertyTypes = _.map(data, (doc) => {
                    doc.label = doc.value[this.currentLang];
                    return doc;
                });
                this.propertySupTypes = propertyTypes;
            });

        this.form.get('own.only_own').valueChanges
            .subscribe(() => {
                this.form.get('listing_agency').setValue(undefined);
                this.form.get('listing_agent').setValue(undefined);
                if (this.form.get('own.only_own').value) {
                    this.form.get('listing_agency').setValue(this.userAgencyData._id);
                }
            });
        this.form.get('shared').valueChanges
            .subscribe((data) => {
                if (data) {
                    this._dropdownsService.getSharingLevels()
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((sharingLevels) => {
                            this.sharingLevels = sharingLevels.body;
                        });
                }
            });

            this.searchSharedAgency$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getSharedAgency(search);
                }),
            )
            .subscribe((data: any) => {
                this.sharedAgency = data.body;
            });

        this._dropdownsService.getPropertyCategory().subscribe((data: any) => {
            this.pcategories = data.body;
        });

        this._dropdownsService.getMooringTypes(this.filter).subscribe((data: any) => {
            this.mooring = data.docs;
        });
        this.form.get('LG').valueChanges.subscribe((data) => {
            if (_.isEmpty(this.form.get('LG').value) || this.form.get('LG').value === '') {
                this.showLocation = true;
                this.showLocationGroups = false;
            }
        });

    }

    setForm(): void {
        this.form = this._formBuilder.group({
            date_from: [],
            date_until: [],
            year_built_to: [],
            license_first_occuption: [],
            built_from: [],
            built_to: [],
            usefull_area_from: [],
            usefull_area_to: [],
            max_total_floors: [],
            plot_from: [],
            plot_to: [],
            min_sleeps: [],
            feet_categories: [],
            custom_categories: [],
            feet_setting: [],
            feet_orientation: [],
            feet_views: [],
            feet_condition: [],
            feet_parking: [],
            feet_garden: [],
            feet_pool: [],
            feet_features: [],
            mooring_type: [],
            min_depth: [],
            min_width: [],
            min_length: [],
            min_height: [],
            year_built_from: [],
            updated_to: [],
            // wow: [],
            occupancy_status: [],
            listed_to: [],
            listed_from: [],
            updatedTo: [],
            updated_from: [],
            created_to: [],
            created_from: [],
            property_name: [],
            reference: [],
            search_by: this._formBuilder.array([]),
            offices: [],
            status: [],
            exclusive: [],
            own: this._formBuilder.group({
                only_own: [false],
                not_own: [false]
            }),
            shared_with_us: [],
            shared: [],
            shared_shared_with_us: [],
            sharing: [],
            featured: [],
            listing_agency: (''),
            listing_agent: [],
            agency: [],
            address_country: [],
            address_province: [],
            address_city: [],
            location: [],
            LG: [],
            urbanisation: [],
            type_one: [],
            type_two: [],
            currentprice: this._formBuilder.array([
                this._formBuilder.control(''),
                this._formBuilder.control(''),

            ]),
            ltr_price: this._formBuilder.array([
                this._formBuilder.control(''),
                this._formBuilder.control('')
            ]),
            str_price: this._formBuilder.array([
                this._formBuilder.control(''),
                this._formBuilder.control(''),
                this._formBuilder.control('')
            ]),
            bedrooms: this._formBuilder.array([
                this._formBuilder.control(''),
                this._formBuilder.control('')
            ]),
            bathrooms: this._formBuilder.array([
                this._formBuilder.control(''),
                this._formBuilder.control('')
            ]),
            p_style: [],
            new_construction: [],
            sale: [],
            lt_rental: [],
            st_rental: [],
            transfer: [],
            nude_sale: [],
            fractional_ownership: [],
            [`private_info_object.${this.currentUser.user_agency}.owner`]: [],
            show_on: [],
            interested: [],
            rejection: [],
            emailed: [],
            not_emailed: [],
            polygon: [],
            with_cadastral: [false],
            without_cadastral: [false],
        });
    }

    searchBy(event: MatCheckboxChange): void {
        const searchBy = this.form.get('search_by') as UntypedFormArray;
        const index = searchBy.value.findIndex((value: string) => value === event.source.value);
        if (event.checked && index < 0) {
            searchBy.push(this._formBuilder.control(event.source.value));
        } else if (index >= 0) {
            searchBy.removeAt(index);
        }
    }

    saveBookMark(value: any): void {
        this.bookMark = '';
        this.bsearch.name = value.title;
        this.bsearch.offices = value.offices;
        this.bsearch.menu_access = value.menu_access;
        this.bsearch.model = 'residential';
        this.bsearch.search_query = this.form.value;
        this.bsearch.user_id = this.currentUser.user_id;
        this._propertiesService.bookmarkSearch(this._globalFunction.clearObject(this.bsearch)).subscribe(() => {
            this._propertiesService.getBookmarks('residential').subscribe((data: any) => {
                this.bookMarkData = data;
                this._crmMenuService.getMenuStructure()
                    // .pipe(takeUntil(this._unsubscribeAll))
                    .then((menu: any) => {
                        this._fuseNavigationService.unregister('main');
                        this._fuseNavigationService.register('main', menu);
                        this._fuseNavigationService.setCurrentNavigation('main');
                        this._translateService.reloadLang(this._translateService.currentLang);
                    });
            });
        });
    }

    selectBookMark(searchQuery: any): void {
        this.form.reset();
        this.form.get('type_one').setValue(searchQuery.type_one);
        this.searchCountries$.next(searchQuery.address_country);
        this.searchProvinces$.next(searchQuery.address_province);
        this.searchCities$.next(searchQuery.address_city);
        this.searchLocations$.next(searchQuery.location);
        this.searchPropertySubTypes$.next(searchQuery.type_two);
        this.form.patchValue(this._globalFunction.clearObject(searchQuery));
    }

    deleteBookMark(id: string): void {
        this._propertiesService.removeBookmark(id).subscribe(() => {
            this._propertiesService.getBookmarks('residential').subscribe((data: any) => {
                this.bookMarkData = data;
                this._crmMenuService.getMenuStructure()
                    .then((menu: any) => {
                        this._fuseNavigationService.unregister('main');
                        this._fuseNavigationService.register('main', menu);
                        this._fuseNavigationService.setCurrentNavigation('main');
                        this._translateService.reloadLang(this._translateService.currentLang);
                    });
            });
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    loadLocationByGroup(e: any): void {
        this.Groups = [];
        for (const item of e) {
            for (const group of item.group) {
                this.Groups.push(group);
            }
        }
        this.locations = [];
        this.form.get('location').setValue('');

        if (this.Groups.length > 0) {
            this.showLocationGroups = true;
            this.showLocation = false;
            this._dropdownsService.getLocationsFromGroup(this.Groups)
                .subscribe((data: any) => {
                    this.locations = _.map(data.docs, (doc) => {
                        doc.label = doc.value[this.currentLang];
                        return doc;
                    });
                    const selected = this.locations.map(item => item.key);
                    this.form.get('location').patchValue(selected);
                });
        } else {
            this.showLocationGroups = false;
            this.showLocation = true;
        }
    }

    addBookmark(): void {
        this.addEditDialogRef = this._matDialog.open(SaveBookmarkComponent, {
            panelClass: 'event-form-dialog',
            // data: data ? data : {},
        });
        this.addEditDialogRef.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
                if (response) {
                    this.saveBookMark(response.value);
                }
            });
    }

    onChangeShared(event: MatSlideToggleChange): void {
        if (event.checked) {
            this.form.get('shared').setValue(true);
        } else {
            this.form.get('shared').setValue(false);
        }
        if(this.form.get('shared').value && this.form.get('shared_with_us').value){
            this.form.get('shared_shared_with_us').setValue(true);
        } else {
            this.form.get('shared_shared_with_us').setValue(false);
        }
    }

    onChangeSharedWithUs(event: MatSlideToggleChange): void {
        if (event.checked) {
            this.form.get('shared_with_us').setValue(true);
        } else {
            this.form.get('shared_with_us').setValue(false);
        }
        if(this.form.get('shared').value && this.form.get('shared_with_us').value){
            this.form.get('shared_shared_with_us').setValue(true);
        } else {
            this.form.get('shared_shared_with_us').setValue(false); 
        }
    }

}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, DropdownsService } from 'app/services';
import { CommercialPropertiesService } from 'app/services/commercial-properties.service';
import { GlobalFuntions } from 'app/_helpers';
import { forEach, isEmpty, map, orderBy, round, uniq } from 'lodash';
import { merge, Subject } from 'rxjs';
import { startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { WebsocketService } from 'app/services/websocket.service'; // for notification
@Component({
    selector: 'commercials-quick-create',
    templateUrl: './commercials-quick-create.component.html',
    styleUrls: ['./commercials-quick-create.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class CommercialsQuickCreateComponent implements OnInit {
    form: UntypedFormGroup;
    searchOwners$ = new Subject<string>();
    systemlang: any;
    searching: boolean;
    currentLang: string;
    viewRecord: boolean;
    user: any;
    filter: any = {};
    type1Data: any;
    type2Data: any;
    type1Search: UntypedFormControl = new UntypedFormControl('');
    type2Search: UntypedFormControl = new UntypedFormControl('');
    currentAgency: any;
    sale: boolean;
    transfer: boolean;
    featured: boolean;
    nudeSale: boolean;
    CountriesSearch: UntypedFormControl = new UntypedFormControl('');
    locationSearch: UntypedFormControl = new UntypedFormControl('');
    locations: any;
    citySearch: UntypedFormControl = new UntypedFormControl('');
    cities: any;
    provinceSearch: UntypedFormControl = new UntypedFormControl('');
    provinces: any;
    regionSearch: UntypedFormControl = new UntypedFormControl('');
    regions: any;
    countriesData: any;
    currentUser: any;
    currencies: any;
    nudeSales: UntypedFormArray;
    periodSeasons: UntypedFormArray;
    systemLanguages: any;
    variants: UntypedFormArray;
    currentPrice: any;
    currentBuilt: any;
    private _unsubscribeAll: Subject<any>;
    ownerData: any;
    rentalSeasons: UntypedFormArray;
    public currentOffice: any;
    defaultCurrency: any;
    public offices: any = [];
    officeGroups = [] as any;
    searchOffices$ = new Subject<string>();
    officeRegion = '' as any;
    isClick = false as boolean;
    excludedData: any;
    BuildingStyleSearch: UntypedFormControl = new UntypedFormControl('');
    BuildingStyleData: any;

    constructor(
        public dialogRef: MatDialogRef<CommercialsQuickCreateComponent>,
        private _formBuilder: UntypedFormBuilder,
        private _dropdownsService: DropdownsService,
        private _authenticationService: AuthenticationService,
        private _translateService: TranslateService,
        private _globalFuntions: GlobalFuntions,
        private _commercialsService: CommercialPropertiesService,
        private socket: WebsocketService,
        private route: ActivatedRoute,
        private _snackbar: MatSnackBar
    ) {
        this.user = this._authenticationService.currentUserValue;
        this.excludedData = this.user.agency_excluded;
        this.currentAgency = this._authenticationService.currentAgencyValue;
        this.currentOffice = this._authenticationService.currentOfficeValue;
        if (this.currentAgency.currency) {
            this.defaultCurrency = this.currentAgency.currency;
        }
        if (this.currentOffice.currency) {
            this.defaultCurrency = this.currentOffice.currency;
        }
        if (this.currentOffice.region) {
            this.officeRegion = this.currentOffice.region;
        }
        // this._translateService.onLangChange.subscribe((data: any) => {
        //   this.systemlang = data.lang;
        //   this.loadType1Data();
        //   this.loadType2Data();
        // });
        this.systemlang = this._translateService.currentLang;
        this.systemLanguages = this.route.snapshot.data.systemLanguages;
        this._unsubscribeAll = new Subject();

        let officeRecord = false;
        if (this._globalFuntions.getUserRole(this.user.user_role)) {
            officeRecord = true;
        }

        this.form = this._formBuilder.group({
            id: [{ value: '', disabled: true }],
            reference: new UntypedFormControl({ value: '', disabled: true }),
            own: [
                { value: true, disabled: this.viewRecord },
                Validators.required,
            ],
            status: [
                { value: 'Valuation', disabled: this.viewRecord },
                Validators.required,
            ],
            type_one: [{ value: '', disabled: this.viewRecord }],
            type_two: [{ value: '', disabled: this.viewRecord }],
            offices: [
                { value: [this.currentOffice._id], disabled: this.viewRecord },
            ],
            sale: [{ value: false, disabled: this.viewRecord }],
            transfer: [{ value: false, disabled: this.viewRecord }],
            rent: [{ value: false, disabled: this.viewRecord }],
            nude_sale: [{ value: '', disabled: this.viewRecord }],
            private_info_object: this._formBuilder.group({
                [this.currentAgency._id]: this._formBuilder.group({
                    address: this._formBuilder.group({
                        formatted_address: [],
                    }),
                    address_postal_code: [],
                    address_street: [],
                    address_street_number: [],
                    apartment_no: [],
                    latitude: [],
                    longitude: [],
                    address_comments: [],
                }),
            }),
            country: [
                {
                    value: this.currentOffice.country,
                    disabled: this.viewRecord,
                },
            ],
            region: [{ value: this.officeRegion, disabled: this.viewRecord }],
            province: [{ value: '', disabled: this.viewRecord }],
            city: [{ value: '', disabled: this.viewRecord }],
            location: [{ value: '', disabled: this.viewRecord }],
            bedrooms: [{ value: '', disabled: this.viewRecord }],
            bathrooms: [{ value: '', disabled: this.viewRecord }],
            p_style: [{ value: '', disabled: this.viewRecord }],
            sleeps: [{ value: '', disabled: this.viewRecord }],
            beds: [{ value: '', disabled: this.viewRecord }],
            living_rooms: [{ value: '', disabled: this.viewRecord }],
            partitioning: [{ value: '', disabled: this.viewRecord }],
            partitioning_type: [{ value: '', disabled: this.viewRecord }],
            currency: [
                { value: this.defaultCurrency, disabled: this.viewRecord },
            ],
            current_price: [{ value: '', disabled: this.viewRecord }],
            nude_sales: this._formBuilder.array([this.addNudeSales()]),
            lt_rental: [{ value: '', disabled: this.viewRecord }],
            ltr: this._formBuilder.group({
                commission: [],
                management_commission: [],
                rental_deposit: [],
                security_deposit: [],
                minimum_stay: [],
                stay_type: [],
            }),
            variants: this._formBuilder.array([this.addVariants()]),
            period_seasons_dc: this._formBuilder.group({}),
            variant: [{ value: false, disabled: this.viewRecord }],
            project: [{ value: false, disabled: this.viewRecord }],
            owner: [{ value: '', disabled: this.viewRecord }],
            other_reference: [{ value: '', disabled: this.viewRecord }],
            external_reference: [{ value: '', disabled: this.viewRecord }],
            toilets: [{ value: '', disabled: this.viewRecord }],
            rental_commission: [{ value: '', disabled: this.viewRecord }],
            management_commission: [{ value: '', disabled: this.viewRecord }],
            security_deposit: [{ value: '', disabled: this.viewRecord }],
            minimum_stay: this._formBuilder.group({
                stay: [],
                period: [],
            }),
            m_renta_commission_unit: [{ value: '', disabled: this.viewRecord }],
            m_renta_commission: [{ value: '', disabled: this.viewRecord }],
            m_renta_commission_unit_owner: [
                { value: '', disabled: this.viewRecord },
            ],
            m_renta_commission_owner: [
                { value: '', disabled: this.viewRecord },
            ],
            m_renta_commission_unit_split: [
                { value: '', disabled: this.viewRecord },
            ],
            m_renta_commission_split: [
                { value: '', disabled: this.viewRecord },
            ],
            rental_seasons: this._formBuilder.array([this.addRentalSeasons()]),
        });
    }

    ngOnInit(): void {
        this.nudeSales = this.form.get('nude_sales') as UntypedFormArray;
        if (this.nudeSales.length === 0) {
            this.nudeSales.push(this.addNudeSales());
        }
        this.variants = this.form.get('variants') as UntypedFormArray;
        this.rentalSeasons = this.form.get('rental_seasons') as UntypedFormArray;

        this._dropdownsService.getCurrencies().subscribe((data: any) => {
            this.currencies = data;
        });

        // this._dropdownsService.getCommercialTypes().subscribe((data: any) => {
        //   this.type1Data = data;
        // });

        merge(this.BuildingStyleSearch.valueChanges, this.form.get('p_style').valueChanges)
        .pipe(
          startWith(''),
          tap(() => this.searching = true),
          switchMap(() => {
            let search = this.form.get('p_style').value;
            if (this.BuildingStyleSearch.value) {
              search = this.BuildingStyleSearch.value;
            }
            if (typeof search !== 'undefined' && typeof this.excludedData.excluded_building_styles !== 'undefined') {
              if (this.excludedData.excluded_building_styles.indexOf(search) > -1) {
                this._snackbar.open(this._translateService.
                  instant('The selected building_style is excluded in agency setting so the property will not appear in listing'),
                  this._translateService.instant('Close'), {
                  duration: 5000,
                });
              }
            }
            return this._dropdownsService.getBuildingStyles();
          })
        )
        .subscribe((data: any) => {
          this.BuildingStyleData = data.body;
          this.searching = false;
        });

        merge(
            this.type1Search.valueChanges,
            this.form.get('type_one').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    let search = this.form.get('type_one').value;
                    if (this.type1Search.value) {
                        search = this.type1Search.value;
                    }
                    if (
                        typeof search !== 'undefined' &&
                        typeof this.excludedData.excluded_commercial_types !==
                            'undefined'
                    ) {
                        if (
                            this.excludedData.excluded_commercial_types.indexOf(
                                search
                            ) > -1
                        ) {
                            this._snackbar.open(
                                this._translateService.instant(
                                    'The selected type1 is excluded in agency setting so the property will not appear in listing'
                                ),
                                this._translateService.instant('Close'),
                                {
                                    duration: 5000,
                                }
                            );
                        }
                    }
                    return this._dropdownsService.getCommercialTypes(search);
                })
            )
            .subscribe((data: any) => {
                this.type1Data = data;
                this.searching = false;
            });

        merge(
            this.type2Search.valueChanges,
            this.form.get('type_one').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    let search = this.form.get('type_two').value;
                    if (this.type2Search.value) {
                        search = this.type2Search.value;
                    }
                    return this._dropdownsService.getCommercialTypesTwo(
                        this.form.get('type_one').value,
                        search
                    );
                })
            )
            .subscribe((data: any) => {
                this.type2Data = data;
                this.searching = false;
            });

        this.searchOffices$
            .pipe(
                startWith(''),
                switchMap((search: string) => {
                    return this._dropdownsService.getOffices(search);
                })
            )
            .subscribe((data: any) => {
                this.offices = data.body;
                forEach(this.offices, (value) => {
                    if (value.office_groups) {
                        if (typeof value.office_groups == 'object') {
                            value.office_groups.forEach((group) => {
                                this.officeGroups.push(group);
                            });
                        } else {
                            this.officeGroups.push(value.office_groups);
                        }
                    }
                });
                this.officeGroups = uniq(this.officeGroups);
                this.officeGroups.sort();
            });
        merge(
            this.CountriesSearch.valueChanges,
            this.form.get('country').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    let search = this.form.get('country').value;
                    if (this.CountriesSearch.value) {
                        search = this.CountriesSearch.value;
                    }
                    return this._dropdownsService.getAllCountries(search);
                })
            )
            .subscribe((data: any) => {
                this.countriesData = data.docs;
                this.searching = false;
            });

        merge(
            this.regionSearch.valueChanges,
            this.form.get('region').valueChanges,
            this.form.get('country').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    let search = this.form.get('region').value;
                    if (this.regionSearch.value) {
                        search = this.regionSearch.value;
                    }
                    return this._dropdownsService.getRegions(
                        search,
                        this.form.get('country').value
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.regions = data.docs;
            });

        merge(
            this.provinceSearch.valueChanges,
            this.form.get('province').valueChanges,
            this.form.get('region').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    let search = this.form.get('province').value;
                    if (this.provinceSearch.value) {
                        search = this.provinceSearch.value;
                    }
                    return this._dropdownsService.getProvinces(
                        search,
                        false,
                        this.form.get('region').value
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.provinces = data.docs;
            });

        merge(
            this.citySearch.valueChanges,
            this.form.get('city').valueChanges,
            this.form.get('province').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    let search = this.form.get('city').value;
                    if (this.citySearch.value) {
                        search = this.citySearch.value;
                    }
                    return this._dropdownsService.getCities(
                        search,
                        false,
                        false,
                        this.form.get('province').value
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.cities = data.docs;
            });

        merge(
            this.locationSearch.valueChanges,
            this.form.get('location').valueChanges,
            this.form.get('city').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    let search = this.form.get('location').value;
                    if (this.locationSearch.value) {
                        search = this.locationSearch.value;
                    }
                    return this._dropdownsService.getLocations(
                        search,
                        false,
                        false,
                        false,
                        this.form.get('city').value
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.locations = data.docs;
            });

        this.searchOwners$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    let ownerId = '';
                    if (!isEmpty(this.form.get('owner').value)) {
                        ownerId = this.form.get('owner').value;
                    }
                    return this._dropdownsService.getOwners(search, ownerId);
                })
            )
            .subscribe((data: any) => {
                const OwenersVal = map(data.body, (doc) => {
                    doc.label = doc.full_name;
                    return doc;
                });
                this.ownerData = OwenersVal;
            });
    }

    submitForm(): void {
        let formData = this._globalFuntions.cleanObject(this.form.value);
        // https://gitlab.optimasit.com/arsl/optima-crm-v2/-/issues/2926
        // formData.created_at = new Date();
        // formData.updated_at = new Date();
        formData.created_by = this.user.user_id;
        formData.from_crm = true; // ensure this is from CRM side handle at backend
        this._commercialsService.createProperty(formData).subscribe(
            (data: any) => {
                this.dialogRef.close(data);
                // fetch the notifications
                this.socket.emit('connectToBoardStreams', { _id: this.user._id, name: this.user?.full_name });
                this.socket.emit('unReadNotificationCount', { userId: this.user._id });
                this.socket.emit('getAllNotifications', { userId: this.user._id, remove_pervious : true });
            },
            (err: any) => {
                this.isClick = false;
            }
        );
    }

    loadType1Data(): any {
        let search = '';
        if (this.type1Search.value) {
            search = this.type1Search.value;
        }
        return this._dropdownsService
            .getPropertyType(this.systemlang)
            .subscribe((data: any) => {
                this.searching = false;
                data.body = orderBy(data.body, ['value'], ['asc']);
                this.type1Data = data.body;
            });
    }

    checkTransfer(): any {
        let status = true;
        if (
            this.form.get('type_one').value === 1 ||
            this.form.get('type_one').value === 13 ||
            this.form.get('type_one').value === 40 ||
            this.form.get('type_one').value === 42 ||
            this.form.get('type_one').value === 45 ||
            this.form.get('type_one').value === 49 ||
            this.form.get('type_one').value === 53 ||
            this.form.get('type_one').value === 67 ||
            this.form.get('type_one').value === 74 ||
            this.form.get('type_one').value === 82 ||
            this.form.get('type_one').value === 88 ||
            this.form.get('type_one').value === 89 ||
            this.form.get('type_one').value === 104
        ) {
            status = false;
        }
        return status;
    }
    checkNudes(): any {
        let show = true;
        if (
            this.form.get('type_one').value === 9 ||
            this.form.get('type_one').value === 12 ||
            this.form.get('type_one').value === 16 ||
            this.form.get('type_one').value === 64 ||
            this.form.get('type_one').value === 67 ||
            this.form.get('type_one').value === 89 ||
            this.form.get('type_one').value === 91 ||
            this.form.get('type_one').value === 104
        ) {
            show = false;
        }
        return show;
    }

    addNudeSales(): UntypedFormGroup {
        return this._formBuilder.group({
            initial_amount: { value: '', disabled: false },
            lft_monthly_rent: { value: '', disabled: false },
            temporary_monthly_rent: { value: '', disabled: false },
            comentarios: { value: '', disabled: false },
        });
    }

    calculate(index: any): void {
        const currentprice = this.currentPrice;
        const built = this.currentBuilt;
        const pricePerBuilt = (currentprice * 1) / (built * 1);
        this.form
            .get('variants.' + index + '.price_per_built')
            .setValue(round(pricePerBuilt, 2));
    }

    addVariants(): UntypedFormGroup {
        return this._formBuilder.group({
            order: { value: '', disabled: true },
            name: { value: '', disabled: false },
            price: { value: '', disabled: false },
            built: { value: '', disabled: false },
            price_per_built: { value: '', disabled: true },
            usefull_area: { value: '', disabled: false },
        });
    }

    setPrice(price: any, index: any): void {
        this.currentPrice = price.srcElement.value;
        this.calculate(index);
    }

    setBuilt(built: any, index: any): void {
        console.log(index);
        this.currentBuilt = built.srcElement.value;
        this.calculate(index);
    }

    onChange(event): void {
        if (event === 'sale') {
            if (this.sale === true) {
                this.sale = false;
            } else {
                this.sale = true;
            }
            this.transfer = false;
            this.form.get('transfer').setValue(false);
        } else {
            if (event === 'transfer') {
                if (this.transfer === true) {
                    this.transfer = false;
                } else {
                    this.transfer = true;
                }
                this.sale = false;
                this.form.get('sale').setValue(false);
            }
        }
    }

    calculateCommissions(type: any): void {
        if (type === 'm_renta_commission_unit') {
            let price = 0;
            let commissionUnit = 0;
            let commission = 0;
            let commissionUnitOwner = 0;
            let commissionOwner = 0;
            let commissionUnitSplit = 0;
            let commissionSplit = 0;
            if (this.form.get('rental_commission').value) {
                price = this.form.get('rental_commission').value;
            }
            if (this.form.get('m_renta_commission_unit').value) {
                commissionUnit = this.form.get('m_renta_commission_unit').value;
            } else if (this.form.get('m_renta_commission_unit_owner').value) {
                commissionUnitOwner = this.form.get(
                    'm_renta_commission_unit_owner'
                ).value;
            } else if (this.form.get('m_renta_commission_unit_split').value) {
                commissionUnitSplit = this.form.get(
                    'cm_renta_ommission_unit_split'
                ).value;
            }
            commissionUnitOwner = commissionUnit / 2;
            commissionUnitSplit = commissionUnit / 2;
            const commissionUnitPercentage = commissionUnit / 100;
            commission = price * commissionUnitPercentage;
            this.form.get('m_renta_commission').setValue(commission);
            const commissionOwnerPercentage = commissionUnitOwner / 100;
            commissionOwner = price * commissionOwnerPercentage;
            this.form
                .get('m_renta_commission_unit_owner')
                .setValue(commissionUnitOwner);
            this.form.get('m_renta_commission_owner').setValue(commissionOwner);
            const commissionUnitSplitPercentage = commissionUnitSplit / 100;
            commissionSplit = price * commissionUnitSplitPercentage;
            this.form
                .get('m_renta_commission_unit_split')
                .setValue(commissionUnitSplit);
            this.form.get('m_renta_commission_split').setValue(commissionSplit);
        }
    }

    addRentalSeasons(): UntypedFormGroup {
        return this._formBuilder.group({
            seasons: [],
            period_from: [],
            period_to: [],
            new_price: [0],
            old_price: [0],
            discount: this._formBuilder.array([this.addDiscounts()]),
            quantity: [0],
            commission_included: [],
            gross_price: [0],
            gross_day_price: [0],
            price_per_day: [0],
            period: [0],
        });
    }
    addDiscounts(): UntypedFormGroup {
        return new UntypedFormGroup({
            number_days: new UntypedFormControl(),
            discount_percent: new UntypedFormControl(),
            discount_price: new UntypedFormControl(),
        });
    }
}
